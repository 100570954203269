import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import LongText from '../long-text';
import CarrierName from '../carrier-name';

import WithPermission, { editDrivers } from '../../modules/with-permission';

import WithUserInfo from '../../hocs/with-user-info';
import LinkButton, { editIconStyle } from '../link-button';

const DriverTableRow = ({
  t,
  id,
  name,
  phone,
  carrier,
  user,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle"><LongText value={name} /></td>
      <td className="align-middle"><div className="mobileTextLimit"><LongText value={phone} /></div></td>
      {!user.isDispatcher && <td className="hideMobile"><CarrierName {...carrier} /></td> }
      <WithPermission permission={editDrivers}>
        <td className="align-middle">
          <LinkButton
            to={`/drivers/${id}/edit`}
            iconStyle={editIconStyle}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
            title={t('edit')}
          />
        </td>
      </WithPermission>
    </tr>
  );
};

DriverTableRow.propTypes = {
  carrier: PropTypes.object,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

DriverTableRow.defaultProps = {
  carrier: {},
};

export default compose(
  withTranslation(),
  WithUserInfo,
)(DriverTableRow);
