import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


export const viewIconStyle = {
  backgroundImage: "url('/assets/eye-icon.png')",
  backgroundSize: '18px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center',
};


export const editIconStyle = {
  backgroundImage: "url('/assets/edit-icon.png')",
  backgroundSize: '16px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center',
};


const LinkButtonWithIcon = ({
  iconStyle,
  title,
  to,
  ...rest
}) => (
  <Link
    to={to}
    className="btn table-button"
    style={iconStyle}
    {...rest}
  >
    {title}
  </Link>
);

LinkButtonWithIcon.propTypes = {
  iconStyle: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};
export default LinkButtonWithIcon;
