import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Form,
  FormGroup,
  Table,
} from 'reactstrap';

import ButtonWithIcon from '../button-with-icon';
import KpiTableRowForm from '../kpi-table-row-form';

const KpiForm = ({
  t,
  kpis,
  onSubmit,
  isConfirmButtonLoading,
}) => {
  const [kpiList, updateKpiList] = React.useState(kpis);
  const handleSetValue = (kpi) => {
    updateKpiList(_.map(kpiList, (kpiObject) => (kpiObject.id === kpi.id ? kpi : kpiObject)));
  };

  const values = {
    kpiList,
  };
  const isSubmitButtonValid = _.every(kpiList,
    (kpi) => !_.isEmpty(_.toString(kpi.value)) && !_.isEmpty(_.toString(kpi.min_value)));
  return (
    <>
      <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
        <FormGroup>
          <Table hover responsive="sm" size="sm" className="KPITable">
            <thead>
              <tr>
                <th className="align-middle" colSpan="2">{ t('kpi.name')}</th>
                <th className="align-middle">{ t('kpi.value')}</th>
                <th className="align-middle">{ t('kpi.minValue')}</th>
              </tr>
            </thead>
            <tbody>
              { _.map(kpiList, (kpi) => (
                <KpiTableRowForm
                  key={kpi.id}
                  kpi={kpi}
                  onUpdate={handleSetValue}
                />
              )) }
            </tbody>
          </Table>
        </FormGroup>
        <FormGroup>
          <ButtonWithIcon
            disabled={!isSubmitButtonValid || isConfirmButtonLoading}
            title={t('save')}
            height="40px"
            width="200px"
            icon="/assets/save-icon.png"
            className="btn-save enabled"
          />
        </FormGroup>
      </Form>
    </>
  );
};


KpiForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  kpis: PropTypes.array,
  isConfirmButtonLoading: PropTypes.bool,
};

KpiForm.defaultProps = {
  kpis: [],
  isConfirmButtonLoading: false,
};


export default KpiForm;
