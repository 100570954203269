import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const UserRole = ({
  roles,
  t,
}) => {
  const isMissingRole = _.isEmpty(roles);
  if (isMissingRole) {
    return <span className="text-danger">{t('role.missing')}</span>;
  }
  return _.first(roles).label;
};

UserRole.propTypes = {
  roles: PropTypes.array,
  t: PropTypes.func.isRequired,
};

UserRole.defaultProps = {
  roles: [],
};

export default compose(
  withTranslation(),
)(UserRole);
