import React from 'react';
import PropTypes from 'prop-types';

const KpiHistoryRow = ({ kpi, ...rest }) => (
  <tr {...rest}>
    <td>{kpi.carrier_name}</td>
    <td className="hideField">{kpi.KPI1}</td>
    <td className="hideField">{kpi.KPI2}</td>
    <td className="hideField">{kpi.KPI3}</td>
    <td className="hideField">{kpi.KPI4}</td>
    <td className="hideField">{kpi.KPI5}</td>
    <td className="hideField">{kpi.KPI6}</td>
    <td>{kpi.KPI}</td>
    <td>{kpi.market_share}</td>
  </tr>
);


KpiHistoryRow.propTypes = {
  kpi: PropTypes.object.isRequired,
  rest: PropTypes.object,
};

KpiHistoryRow.defaultProps = {
  rest: {},
};
export default KpiHistoryRow;
