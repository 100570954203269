import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import HttpError from '../../components/http-error';
import ActionCard from '../../components/action-card';
import SuspendedCarrierTableRow from '../../components/suspended-carriers-row';
import Spinner from '../../components/spinner';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';

import WithPermission, { editCarriers } from '../with-permission';
import Table from '../../components/table';
import withAPI from '../../hocs/with-api';
import FlashMessage from '../../components/flash-message';
import SuccessAlert from '../../components/success-alert';

class SuspendedCarriers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carriers: [],
      currentPage: getCurrentPageNumber(props),
      totalPages: 1,
      totalRecords: 1,
      perPage: 1,
      from: 1,
      to: 1,
      successRestore: false,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('carriers.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadCarriers();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/suspended-carriers/${currentPage}`);
      this.loadCarriers();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadCarriers);
  }

  loadCarriers = async () => {
    const { httpRequest } = this.props;
    const { currentPage } = this.state;
    const {
      data: {
        data: carriers,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url: `/carriers/trashed?page=${currentPage}`,
    });
    this.setState({
      carriers,
      totalPages: last_page,
      perPage: per_page,
      totalRecords: total,
      from,
      to,
    });
  }

  toggleCarriersActivation = async (carrierId) => {
    const { httpRequest } = this.props;
    const restored = await httpRequest({
      method: 'post',
      url: `carriers/restore/${carrierId}`,
    });
    this.setState({
      successRestore: restored,
    }, this.loadCarriers);
  }

  onAlertClose = () => {
    this.setState({
      successRestore: false,
    });
  }

  onActivateClick = (id) => {
    this.toggleCarriersActivation(id);
  }

  render() {
    const {
      t,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
      httpPostLoading,
    } = this.props;
    const {
      carriers,
      from,
      currentPage,
      totalPages,
      to,
      perPage,
      totalRecords,
      successRestore,
    } = this.state;
    return (
      <ActionCard
        title={t('carriers.suspendedCarriers')}
        withActionButton={false}
      >
        { httpGetLoading && <Spinner marginTop="555px" /> }
        {
          <FlashMessage />
        }
        {
            successRestore && <SuccessAlert onClose={this.onAlertClose} message={t('carriers.restored')} />
        }
        {
          httpGetFailed && (
          <HttpError
            withTryAgainButton
            onTryAgain={this.loadCarriers}
          />
          )
        }
        {
          httpGetDone && !httpGetFailed && !httpGetLoading && (
            <>
              <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(carriers)} emptyMessage={t('messages.noData')}>
                <thead>
                  <tr>
                    <th className="align-middle">{ t('carriers.name') }</th>
                    <th className="align-middle hideMobile">{ t('carriers.description') }</th>
                    <WithPermission permission={editCarriers}>
                      <th className="align-middle actions">{t('actions')}</th>
                    </WithPermission>
                  </tr>
                </thead>
                <tbody>
                  { _.map(carriers, (carrier) => (
                    <SuspendedCarrierTableRow
                      {...carrier}
                      key={`carrier-${carrier.id}`}
                      onActivateClick={this.onActivateClick}
                      actionButtonDisabled={httpPostLoading}
                    />
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={this.handlePageChange}
                totalRecords={totalRecords}
                perPage={perPage}
                from={from}
                to={to}
              />
            </>
          )
        }
      </ActionCard>
    );
  }
}

SuspendedCarriers.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
  httpPostLoading: PropTypes.bool,
};

SuspendedCarriers.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
  httpPostLoading: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(SuspendedCarriers);
