import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import { UncontrolledTooltip } from 'reactstrap';

class APIInfo extends PureComponent {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      hasResponded: false,
      isFailed: false,
      failures: [],
    };
  }

  async componentDidMount() {
    const response = await axios.get(`${process.env.REACT_APP_HEALTH_CHECK_URL}`).catch(() => {
      this.setState({
        isLoading: false,
        hasResponded: false,
      });
    });
    if (response) {
      const {
        data: {
          status = '', items = [], version = 'unknown', release_date,
        },
      } = response;
      const isFailed = status !== 'OK';
      const failures = _.filter(_.keys(items), (item) => items[item] !== 'OK');
      this.setState({
        isFailed,
        failures,
        hasResponded: true,
        isLoading: false,
        version,
        releaseDate: release_date,
      });
    }
  }

  render() {
    const {
      hasResponded, isLoading, failures, isFailed, version, releaseDate,
    } = this.state;
    return (
      <>
        <span>API Health: </span>
        {
          isLoading && <span>?</span>
        }
        {
          !isLoading && !hasResponded && (
            <>
              <span className="badge badge-danger" id="UncontrolledTooltipExample">FAIL</span>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                  Health check did not respond properly!
              </UncontrolledTooltip>
            </>
          )
        }
        {
          !isLoading && hasResponded && isFailed && (
            <>
              <span id="UncontrolledTooltipExample">FAIL</span>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                  Failed services:
                {' '}
                { _.join(failures, ',') }
              </UncontrolledTooltip>
            </>
          )
        }
        {
          !isLoading && hasResponded && !isFailed && (
            <>
              <span id="UncontrolledTooltipExample">OK</span>
              <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                Version
                {' '}
                {version}
                {' '}
                |
                {' '}
                { moment.unix(releaseDate).tz('Europe/Chisinau').format('lll') }
              </UncontrolledTooltip>
            </>
          )
        }
      </>
    );
  }
}

export default compose()(APIInfo);
