import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';
import { roles as userRoles } from '../../hocs/with-user-info';

import Input from '../input';
import ButtonWithConfirmation from '../button-with-confirmation';
import ButtonWithIcon from '../button-with-icon';
import DropdownInput from '../dropdown-input';

const UserForm = (props) => {
  const {
    user,
    inEditMode,
    onSubmit,
    onDelete,
    roles,
    carriers,
    t,
    isMyUser,
    userCreatedOk,
  } = props;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState(_.get(user, 'roles[0]'));
  const [carrier, setCarrier] = useState(_.get(user, 'carriers[0]'));

  const handleSetEmail = (e) => setEmail(_.get(e, 'target.value', ''));
  const handleSetName = (e) => setName(_.get(e, 'target.value', ''));
  const handleSetPassword = (e) => setPassword(_.get(e, 'target.value', ''));
  const handleSetCarrier = (carrierObj) => setCarrier(carrierObj);
  const handleSetRole = (roleObj) => setRole(roleObj);

  const isButtonValid = () => {
    const hasName = !_.isEmpty(name);
    const hasEmail = !_.isEmpty(email);
    const hasRole = !_.isEmpty(_.toString(role));
    const hasCarrier = !_.isEmpty(_.toString(carrier));
    const isCarrierRequired = hasRole && role.id === userRoles.dispatcher;
    const basicFieldsOK = hasName && hasEmail && hasRole;
    return isCarrierRequired ? (basicFieldsOK && hasCarrier) : basicFieldsOK;
  };

  useEffect(() => {
    setName(inEditMode ? user.name : '');
    setEmail(inEditMode ? user.email : '');
    setPassword(inEditMode ? user.password || '' : '');
  }, [userCreatedOk, user, inEditMode]);

  const values = {
    user,
    email,
    name,
    password,
    role,
    carrier,
  };

  const roleOptions = _.map(roles, (record) => ({
    id: record.id,
    label: record.label,
    value: record,
  }));

  const carrierOptions = _.map(carriers, (record) => ({
    id: record.id,
    label: record.name,
    value: record,
  }));

  const defaultSelectedRole = (role ? {
    label: role.label,
    value: role,
    id: role.id,
  } : undefined);

  const defaultSelectedCarrier = (carrier ? {
    label: carrier.name,
    value: carrier,
    id: carrier.id,
  } : undefined);

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="email">{ t('email') }</Label>
          <Input
            value={email}
            type="email"
            name="email"
            id="email"
            className="input-clean"
            placeholder={t('email')}
            onChange={handleSetEmail}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="name">{ t('fullname') }</Label>
          <Input
            value={name}
            name="name"
            id="name"
            className="input-clean"
            placeholder={t('fullname')}
            onChange={handleSetName}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="password">{ t('password') }</Label>
          <Input
            autoComplete="off"
            value={password}
            type="password"
            name="password"
            id="password"
            className="input-clean"
            placeholder={t('password')}
            onChange={handleSetPassword}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <DropdownInput
            options={roleOptions}
            label={t('role')}
            placeholder={t('role.select')}
            onSelected={handleSetRole}
            defaultSelected={defaultSelectedRole}
          />
        </FormGroup>
      </Col>
      {
       role && _.toNumber(role.id) === userRoles.dispatcher
        && (
          <Col md="12">
            <FormGroup>
              <DropdownInput
                required
                options={carrierOptions}
                label={t('carriers.one')}
                placeholder={t('carriers.one')}
                onSelected={handleSetCarrier}
                defaultSelected={defaultSelectedCarrier}
              />
            </FormGroup>
          </Col>
        )
      }
      <Col md="12" className="btn-form-group">
        <ButtonWithIcon
          title={inEditMode ? t('save') : t('add')}
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isButtonValid()}
        />
        {
          inEditMode && !isMyUser && (
          <ButtonWithConfirmation
            buttonLabel={t('users.delete')}
            headerText={t('users.delete')}
            confirmText={t('users.confirmDelete')}
            onConfirm={() => onDelete(user.id)}
            alignment="left-aligned"
            icon="/assets/images/delete-icon@2x.png"
          />
          )
          }
      </Col>
    </Form>
  );
};

UserForm.propTypes = {
  userCreatedOk: PropTypes.bool,
  inEditMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  user: PropTypes.object,
  roles: PropTypes.array,
  carriers: PropTypes.array,
  isMyUser: PropTypes.bool,
};

UserForm.defaultProps = {
  userCreatedOk: false,
  inEditMode: false,
  user: {},
  roles: [],
  carriers: [],
  isMyUser: false,
  onDelete: () => {},
};


export default compose(
  withTranslation(),
)(UserForm);
