import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Row, Col,
  Alert,
  Button,
} from 'reactstrap';

import LongText from '../long-text';

const ErrorList = ({ list }) => (
  <ul>
    { _.map(_.flatten(_.values(list)), (error) => <li key={error}>{error}</li>) }
  </ul>
);

const HttpErrorHandler = ({ message, errors, onClose }) => (
  <Alert key={message} className="alert alert-danger error-alert" variant="danger" style={{ marginTop: 20 }}>
    <Row>
      <Col className="alert-message">
        <LongText value={message} maxLength={150} />
        { !_.isEmpty(errors) && <ErrorList list={errors} /> }
      </Col>
      { onClose && (
      <Col lg="20" className="error-close-btn-container">
        <Button
          className="float-right error-close-btn"
          onClick={onClose}
        />
      </Col>
      )}
    </Row>
  </Alert>
);

ErrorList.propTypes = {
  list: PropTypes.object.isRequired,

};

HttpErrorHandler.propTypes = {
  message: PropTypes.string,
  errors: PropTypes.object,
  onClose: PropTypes.func,
};

HttpErrorHandler.defaultProps = {
  onClose: undefined,
};


HttpErrorHandler.defaultProps = {
  message: 'S-a produs o eroare internă, vă rugăm să încercați mai târziu',
  errors: {},
};


export default HttpErrorHandler;
