import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../date-picker.css';

import {
  Form,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';

import withAPI from '../../hocs/with-api';
import Input from '../../components/input';
import ButtonWithIcon from '../../components/button-with-icon';
import SuccessAlert from '../../components/success-alert';

const weekdays = Array.from({ length: 7 }).map((v, i) => {
  const val = moment(i, 'e').startOf('week').isoWeekday(i + 1).format('dddd');
  return _.upperFirst(val);
});

class CreateEditWorkProgram extends PureComponent {
  constructor() {
    super();
    this.state = {
      workProgramList: Array.from({ length: 7 })
        .map((i, index) => ({ day: weekdays[index], from: '', to: '' })),
      programId: '',
      workProgramSubmitted: false,
      selectedDates: [],
      currentSelectedDate: '',
      isInvalidFormValidation: false,
      isFormSubmitting: false,
    };
  }

  async componentDidMount() {
    await this.loadWorkProgram();
  }

  handleChange = (val, index, field) => {
    const { workProgramList } = this.state;
    const workProgramListCopy = _.cloneDeep(workProgramList);
    workProgramListCopy[index][field] = val;
    this.setState({ workProgramList: workProgramListCopy }, this.validateForm);
  }

  validateForm() {
    const { workProgramList } = this.state;
    const { t } = this.props;

    const hasOneNotSelected = (workProgramList
      .findIndex(({ from, to }) => (from && !to) || (to && !from)));

    const toLowerThanFrom = (workProgramList
      .findIndex(({ from, to }) => moment(from, 'h:mm A').isAfter(moment(to, 'h:mm A'))));

    const copiedProgramList = _.cloneDeep(workProgramList);

    const hasError = (hasOneNotSelected !== -1) || (toLowerThanFrom !== -1);

    if (toLowerThanFrom !== -1) {
      copiedProgramList[toLowerThanFrom].error = t('work_program.to_lower_than_from');
    }

    this.setState({
      isInvalidFormValidation: hasError,
      workProgramList: hasError ? copiedProgramList : workProgramList.map((val) => ({ ...val, error: '' })),
    });
  }

  async loadWorkProgram() {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: 'get-work-program',
    });

    const data = _.get(response, 'data.data', '');
    const programId = _.get(response, 'data.id', '');
    const freeDays = _.get(response, 'data.free_days', '');
    if (data) {
      this.setState(
        {
          workProgramList: JSON.parse(data), programId, selectedDates: JSON.parse(freeDays),
        }, this.validateForm,
      );
    }
  }

  componentWillUnmount() {
    if (this.hideAlertIntervalId) clearInterval(this.hideAlertIntervalId);
  }

  async onSubmitForm() {
    const { httpRequest } = this.props;
    const { programId, workProgramList, selectedDates } = this.state;
    const data = {
      period: moment().format('YYYY-MM'),
      data: JSON.stringify(workProgramList.map(({ error, ...rest }) => ({ ...rest }))),
      free_days: JSON.stringify(selectedDates),
    };
    this.setState({ isFormSubmitting: true });
    const submitted = await httpRequest({
      method: programId ? 'put' : 'post',
      url: `work-program/${programId ?? ''}`,
      data,
    }).finally(() => this.setState({ isFormSubmitting: false }));

    if (submitted) {
      window.scrollTo(0, 0);
      const { id } = submitted?.data;
      this.setState({ workProgramSubmitted: true, programId: id });
      this.hideAlertInterval();
    }
  }

  hideAlertInterval() {
    if (this.hideAlertIntervalId) clearInterval(this.hideAlertIntervalId);
    this.hideAlertIntervalId = setTimeout(() => {
      this.setState({ workProgramSubmitted: false });
    }, 3000);
  }

  render() {
    const { t } = this.props;
    const {
      workProgramList, workProgramSubmitted, programId, selectedDates, currentSelectedDate,
      isInvalidFormValidation, isFormSubmitting,
    } = this.state;
    const formattedDate = moment().format('MMMM YYYY');

    return (
      <>
        <Col md="12" className="mt-3">
          {
            workProgramSubmitted && <SuccessAlert message={t('work_program.updated')} />
          }
        </Col>
        <Col md="12">
          <p className="lf-main-text">{`${t('work_program.title')} - ${formattedDate}`}</p>
        </Col>
        <Form className="w-100 mt-4" onSubmit={(e) => { e.preventDefault(); this.onSubmitForm(); }}>
          {workProgramList.map((val, index) => {
            const isFromSelected = !!workProgramList[index].from;
            const isToSelected = !!workProgramList[index].to;
            const validationError = val?.error ?? '';
            return (
              <Row key={val?.day}>
                <Col md="4">
                  <FormGroup>
                    <Label className="label-clean">{t('work_program.day')}</Label>
                    <Input
                      value={val?.day}
                      type="text"
                      className="input-clean mw-100 w-100"
                      placeholder={t('work_program.day')}
                      onChange={() => {}}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="position-ref">
                    <Label className="label-clean">{t('work_program.from')}</Label>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      className={`form-control input-clean mw-100 w-100 
                        ${(isToSelected && !isFromSelected) || validationError ? 'border border-danger' : ''}`}
                      dateFormat="h:mm A"
                      value={val?.from ?? ''}
                      onChange={(v) => this.handleChange(moment(v).format('h:mm A'), index, 'from')}
                    />
                    <button
                      className="clear-button-datepicker btn btn-danger"
                      type="button"
                      onClick={() => this.handleChange('', index, 'from')}
                    >
                      x
                    </button>
                  </FormGroup>
                  <p className="text-danger">{validationError}</p>
                </Col>
                <Col md="4">
                  <FormGroup className="position-ref">
                    <Label className="label-clean">{t('work_program.to')}</Label>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      className={`form-control input-clean mw-100 w-100 
                        ${isFromSelected && !isToSelected ? 'border border-danger' : ''}`}
                      dateFormat="h:mm A"
                      value={val?.to ?? ''}
                      onChange={(v) => this.handleChange(moment(v).format('h:mm A'), index, 'to')}
                    />
                    <button
                      className="clear-button-datepicker btn btn-danger"
                      type="button"
                      onClick={() => this.handleChange('', index, 'to')}
                    >
                      x
                    </button>
                  </FormGroup>
                </Col>
              </Row>
            );
          })}
          <Row className="mt-3">
            <Col md="4">
              <FormGroup>
                <Label className="label-clean">{t('work_program.day_off_select')}</Label>
                <DatePicker
                  className="form-control input-clean mw-100 w-100"
                  dateFormat="DD-MM-YYYY"
                  popperPlacement="top"
                  value={currentSelectedDate}
                  onChange={(val) => this.setState({ currentSelectedDate: moment(val).format('DD-MM-YYYY') })}
                  filterDate={(val) => {
                    const isUsedDate = selectedDates.includes(moment(val).format('DD-MM-YYYY'));
                    const isCurrentMonthAndYear = moment().format('MM-YYYY') === moment(val).format('MM-YYYY');
                    return !isUsedDate && isCurrentMonthAndYear;
                  }}
                />
              </FormGroup>
              <button
                className="btn btn-success"
                type="button"
                disabled={!currentSelectedDate}
                onClick={() => {
                  const dates = selectedDates.concat(currentSelectedDate);
                  const sortedDates = dates.sort((a, b) => moment(a, 'DD-MM-YYYY') - moment(b, 'DD-MM-YYYY'));
                  this.setState({ selectedDates: sortedDates, currentSelectedDate: '' });
                }}
              >
                {t('add')}
              </button>
            </Col>
            <Col md="4">
              {!!selectedDates.length && (
              <Label className="label-clean">{t('work_program.day_off_list')}</Label>
              )}
              <ul>
                {selectedDates.map((day) => (
                  <li key={day} className="flex gap-10">
                    {day}
                    <button
                      className="btn btn-danger ml-3"
                      type="button"
                      onClick={() => this.setState({ selectedDates: _.filter(selectedDates, (val) => val !== day) })}
                    >
                      {t('delete')}
                    </button>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Col md="12" className="btn-form-group">
            <ButtonWithIcon
              title={programId ? t('save') : t('save')}
              icon="/assets/images/save-icon@2x.png"
              alignment="left-aligned"
              disabled={isInvalidFormValidation || isFormSubmitting}
            />
          </Col>
        </Form>
      </>
    );
  }
}

CreateEditWorkProgram.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
};

CreateEditWorkProgram.defaultProps = {

};
export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(CreateEditWorkProgram);
