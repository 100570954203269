import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Spinner from '../../components/spinner';
import WarehouseForm from '../../components/warehouse-form';

import withAPI from '../../hocs/with-api';
import HttpErrorHandler from '../../components/http-error-handler';
import ButtonWithIcon from '../../components/button-with-icon';

class EditWarehouse extends PureComponent {
  constructor() {
    super();
    this.state = { warehouse: null };
    this.formRef = React.createRef();
  }

   componentDidMount = async () => {
     const {
       t, httpRequest, history, match: { params: { id: warehouseId } },
     } = this.props;
     document.title = `${t('vehicles.edit')} - ${t('app.name')}`;
     const response = await httpRequest({
       method: 'get',
       url: `/warehouses/${warehouseId}`,
     }).catch(() => {
       history.push('/dashboard');
     });
     const warehouse = _.get(response, 'data');
     if (warehouse) {
       this.setState({
         warehouse,
       });
     }
   }

   updateTransport = async ({
     name,
     address,
     ivmsLocationId,
   }) => {
     const {
       t, httpRequest, match: { params: { id } }, history,
     } = this.props;

     const patched = await httpRequest({
       method: 'patch',
       url: `warehouses/${id}`,
       data: {
         name, address, ivms_location_id: ivmsLocationId,
       },
     });
     if (patched) {
       history.push({
         pathname: _.get(this.props, 'location.state.prevPath', '/warehouses'),
         flash: {
           type: 'success',
           message: t('warehouses.edited'),
         },
       });
     }
   }

   onDelete = async (warehouseId) => {
     const { httpRequest, history, t } = this.props;
     const deleted = await httpRequest({
       method: 'delete',
       url: `warehouses/${warehouseId}`,
     });
     if (deleted) {
       history.push({
         pathname: '/warehouses',
         flash: {
           type: 'success',
           message: t('warehouses.deleted'),
         },
       });
     }
   }

   onBackButtonClick = () => {
     const { history } = this.props;
     history.goBack();
   }

   render() {
     const { warehouse } = this.state;
     const {
       t,
       httpGetLoading,
       httpGetDone,
       httpDeleteLoading,
       httpPatchLoading,
       httpPatchFailed,
       httpPatchFailReason,
       httpDeleteFailed,
       httpDeleteFailReason,
     } = this.props;

     return (
       <>
         <Col md="12">
           <Row className="with-back-button">
             <p className="lf-main-text form-name-text">{ t('warehouses.edit') }</p>
             <ButtonWithIcon
               height="40px"
               icon="/assets/back-icon.png"
               width="50px"
               className="float-right back-button"
               onClick={this.onBackButtonClick}
             />
           </Row>
         </Col>
         {
            httpGetLoading && <Spinner />
          }
         {
          !httpGetLoading && httpGetDone && warehouse
          && (
            <Col md="12">
              {
                httpPatchFailed && httpPatchFailReason
                && <HttpErrorHandler {...httpPatchFailReason} />
              }
              {
                httpDeleteFailed && httpDeleteFailReason
                && <HttpErrorHandler {...httpDeleteFailReason} />
              }
              <WarehouseForm
                inEditMode
                t={t}
                warehouse={warehouse}
                onSubmit={this.updateTransport}
                onDelete={this.onDelete}
                isConfirmButtonLoading={httpPatchLoading}
                isDeleteButtonLoading={httpDeleteLoading}
              />
            </Col>
          )
          }
       </>
     );
   }
}

EditWarehouse.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpDeleteLoading: PropTypes.bool,
  httpPatchLoading: PropTypes.bool,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
};

EditWarehouse.defaultProps = {
  httpGetLoading: true,
  httpGetDone: false,
  httpDeleteLoading: false,
  httpPatchLoading: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpDeleteFailed: false,
  httpDeleteFailReason: undefined,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(EditWarehouse);
