import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({
  marginTop,
}) => (
  <div
    className="text-center"
    style={
    { display: 'block', marginTop, marginBottom: marginTop }
  }
  >
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

Spinner.propTypes = {
  marginTop: PropTypes.string,
};

Spinner.defaultProps = {
  marginTop: '240px',
};

export default Spinner;
