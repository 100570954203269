import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import LongText from '../long-text';

import WithPermission, { editCarriers } from '../../modules/with-permission';
import { editIconStyle } from '../link-button';
import ButtonWithIcon from '../button-with-icon';

const SuspendedCarrierTableRow = ({
  id,
  name,
  description,
  onActivateClick,
  t,
  actionButtonDisabled,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const onActivatePress = (carrierId) => {
    setButtonLoading(true);
    onActivateClick(carrierId);
  };
  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle"><LongText value={name} maxLength={40} /></td>
      <td className="align-middle hideMobile"><LongText value={description} maxLength={40} /></td>
      <WithPermission permission={editCarriers}>
        <td className="align-middle">
          <ButtonWithIcon
            disabled={actionButtonDisabled}
            onClick={() => onActivatePress(id)}
            title={t('carriers.activate')}
            width="100px"
            height="135%"
            styleClass={editIconStyle}
            loading={buttonLoading}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
          />
        </td>
      </WithPermission>
    </tr>
  );
};

SuspendedCarrierTableRow.defaultProps = {
  description: '-',
  onActivateClick: () => {},
  actionButtonDisabled: false,
};

SuspendedCarrierTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  onActivateClick: PropTypes.func,
  actionButtonDisabled: PropTypes.bool,
};

export default compose(
  withTranslation(),
)(SuspendedCarrierTableRow);
