import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  Container,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import LongText from '../long-text';

import withUserInfo from '../../hocs/with-user-info';
import styles from './styles.module.css';

const infoBlockStyle = {
  padding: '5px 20px 5px 20px',
  fontSize: 13,
};

const NavigationBar = ({
  t,
  isAuthenticated,
  user,
}) => (
  <div>
    <Navbar expand="md" className="navbar-top">
      <Container>
        <Collapse isOpen={false} navbar>
          <Nav className="mr-auto" navbar />
          <Nav className={styles.userProfile}>
            {
                isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className={`${styles.userProfile} top-nav-item`} nav caret>
                    <LongText value={user.name} />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <div style={infoBlockStyle}>
                      <b>{ user.roles[0].label }</b>
                      <br />
                      {user.isDispatcher && (
                        <>
                          <LongText value={user.carriers[0].name} />
                          <br />
                        </>
                      )}
                      { user.email }
                    </div>
                    <DropdownItem divider />
                    <DropdownItem style={infoBlockStyle} tag={Link} to="/password/change">
                      { t('password.change') }
                    </DropdownItem>
                    <DropdownItem style={infoBlockStyle} tag={Link} to="/logout">
                      { t('logout') }
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                )
              }
            {
                !isAuthenticated && (
                  <Link to="/login">
                    { t('login') }
                  </Link>
                )
              }
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  </div>
);
NavigationBar.propTypes = {
  t: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withUserInfo,
  React.memo,
)(NavigationBar);
