import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Alert, Col, Row,
} from 'reactstrap';

import withUser from '../../hocs/with-user';
import withUserInfo from '../../hocs/with-user-info';

import LoginForm from '../../components/login-form';
import FlashMessage from '../../components/flash-message';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      wrongCredentials: false,
    };
  }

  onLogin = async (credentials) => {
    const {
      login,
      storeTokens,
      storeProfile,
      setIsAuthenticated,
      getProfile,
      history,
    } = this.props;
    history.push({
      state: {
        flash: null,
      },
    });
    this.setState({
      isLoggingIn: true,
      wrongCredentials: false,
    }, async () => {
      const tokens = await login(credentials).catch(() => {
        this.setState({
          isLoggingIn: false,
          wrongCredentials: true,
        });
      });
      if (tokens) {
        storeTokens(tokens);
        const profile = await getProfile();
        storeProfile(profile);
        setIsAuthenticated(true);
        history.push('/dashboard');
      }
    });
  }

  render() {
    const { t, isAuthenticated } = this.props;
    const { wrongCredentials, isLoggingIn } = this.state;
    if (isAuthenticated) return <Redirect to="/dashboard" />;
    return (
      <div className="full-height page-container authPage">
        <div className="login-page-form">
          <Row>
            <Col md="7">
              <div className="vinci-logo">
                <img src="/assets/images/logo-by-lafarge@2x.jpg" alt="Logo" />
                <div className="clearfix" />
                <img src="/assets/images/truck@2x.jpg" className="truckLogo" alt="Logo truck" />
                <div className="clearfix" />
              </div>
            </Col>
            <Col md="5">
              <div className="authFormWrapper">
                <Row>
                  <Col md="12">
                    <p className="lf-main-text">{ t('login') }</p>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: '15px' }}>
                  <FlashMessage />
                </Row>
                {
                  wrongCredentials
                  && (
                    <Row>
                      <Col md="12">
                        <Alert color="danger">
                          { t('credentials.wrong') }
                        </Alert>
                      </Col>
                    </Row>
                  )
                }
                <LoginForm
                  isLoggingIn={isLoggingIn}
                  onLogin={this.onLogin}
                />
                <Row>
                  <Col md="12">
                    <p className="copyright-text">
                      Copyright ©
                      {(new Date().getFullYear())}
                      , Lafarge
                    </p>
                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  storeTokens: PropTypes.func.isRequired,
  storeProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default compose(
  withTranslation(),
  withUser,
  withUserInfo,
  withRouter,
)(Login);
