import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';

const ButtonWithIcon = ({
  title,
  icon,
  width,
  height,
  styleClass,
  alignment,
  loading,
  restButtonStyles,
  ...rest
}) => {
  const iconStyle = {
    backgroundImage: `url(${icon})`,
    backgroundSize: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15px center',
  };
  return (
    <Button
      style={{
        width,
        height,
        ...(!loading ? iconStyle : {}),
        ...restButtonStyles,
      }}
      className={(styleClass) || `${styles.iconBtn} ${alignment}`}
      {... rest}
    >
      { !loading && <span>{ title }</span>}
      { loading && <FontAwesomeIcon icon={faSpinner} spin /> }
    </Button>
  );
};


ButtonWithIcon.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  styleClass: PropTypes.string,
  alignment: PropTypes.string,
  loading: PropTypes.bool,
  restButtonStyles: PropTypes.object,
};

ButtonWithIcon.defaultProps = {
  icon: '',
  width: '200px',
  height: '40px',
  styleClass: '',
  title: '',
  alignment: 'right-aligned',
  loading: false,
  restButtonStyles: {},
};

export default ButtonWithIcon;
