import React from 'react';
import PropTypes from 'prop-types';
import { Button as OriginalButton } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const buttonStyle = {

};

const Button = ({
  children,
  loading,
  minWidth,
  ...rest
}) => (
  <OriginalButton {...rest} style={{ minWidth, ...buttonStyle }}>
    { !loading && children }
    { loading && <FontAwesomeIcon icon={faSpinner} spin /> }
  </OriginalButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  minWidth: PropTypes.number,
};

Button.defaultProps = {
  loading: false,
  minWidth: 180,
};

export default Button;
