import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const Product = ({
  t,
  number,
  product,
  quantity,
  uom,
}) => (
  <tr>
    <td>{number}</td>
    <td><span className="prodName">{product}</span></td>
    <td>{quantity}</td>
    <td>{ _.isUndefined(uom) ? '-' : t(_.toLower(`orders.uom.${uom}`))}</td>
  </tr>
);

Product.propTypes = {
  t: PropTypes.func.isRequired,
  number: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
};

export default compose(
  withTranslation(),
)(Product);
