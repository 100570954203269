import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner';
import DriverForm from '../../components/driver-form';

import withUserInfo from '../../hocs/with-user-info';
import withAPI from '../../hocs/with-api';
import HttpErrorHandler from '../../components/http-error-handler';
import ButtonWithIcon from '../../components/button-with-icon';

class EditDriver extends PureComponent {
  constructor() {
    super();
    this.state = { driver: null, carriers: [] };
  }

  componentDidMount = async () => {
    const {
      httpRequest,
      history,
      match: {
        params: { id: driverId },
      },
      user,
    } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: `/drivers/${driverId}`,
    }).catch(() => {
      history.push('/dashboard');
    });
    const driver = _.get(response, 'data');
    if (!driver) { return; }
    if (driver) {
      this.setState({
        driver,
      });
    }
    if (user.isDispatcher) return;
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });
    this.setState({
      carriers,
    });
  };

  updateDriver = async ({ name, phone, carrier }) => {
    const {
      t,
      httpRequest,
      match: {
        params: { id },
      },
      history,
    } = this.props;
    const patched = await httpRequest({
      method: 'patch',
      url: `drivers/${id}`,
      data: {
        name,
        phone,
        carrier_id: carrier.id,
      },
    });
    if (patched) {
      history.push({
        pathname: '/drivers',
        flash: {
          type: 'success',
          message: t('drivers.edited'),
        },
      });
    }
  };

  onDelete = async (driverId) => {
    const { httpRequest, history, t } = this.props;
    const deleted = await httpRequest({
      method: 'delete',
      url: `drivers/${driverId}`,
    });
    if (deleted) {
      history.push({
        pathname: '/drivers',
        flash: {
          type: 'success',
          message: t('drivers.deleted'),
        },
      });
    }
  };

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }


  render() {
    const { driver, carriers } = this.state;
    const {
      httpGetLoading,
      httpGetDone,
      httpGetFailed,
      httpGetFailReason,
      t,
      httpPatchFailed,
      httpPatchFailReason,
      httpDeleteFailed,
      httpDeleteFailReason,
      httpPatchLoading,
      httpDeleteLoading,
    } = this.props;
    return (
      <>
        <Col md="12">
          {httpGetFailed && httpGetFailReason && (
          <HttpErrorHandler {...httpGetFailReason} />
          )}
          {httpPatchFailed && httpPatchFailReason && (
          <HttpErrorHandler {...httpPatchFailReason} />
          )}
          {httpDeleteFailed && httpDeleteFailReason && (
          <HttpErrorHandler {...httpDeleteFailReason} />
          )}
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('drivers.edit') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {httpGetLoading && <Spinner />}
          {!httpGetLoading && httpGetDone && driver && (
          <DriverForm
            inEditMode
            carriers={carriers}
            driver={driver}
            onSubmit={this.updateDriver}
            onDelete={this.onDelete}
            isConfirmButtonLoading={httpPatchLoading}
            isDeleteButtonLoading={httpDeleteLoading}
          />
          )}
        </Col>
      </>
    );
  }
}

EditDriver.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
  httpDeleteLoading: PropTypes.bool,
  httpDeleteDone: PropTypes.bool,
  httpPatchLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
  httpGetFailReason: PropTypes.object,
};

EditDriver.defaultProps = {
  httpGetLoading: true,
  httpGetDone: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpDeleteFailed: false,
  httpDeleteFailReason: undefined,
  httpDeleteLoading: false,
  httpDeleteDone: true,
  httpPatchLoading: false,
  httpGetFailed: false,
  httpGetFailReason: undefined,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
  withUserInfo,
)(EditDriver);
