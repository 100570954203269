import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import LongText from '../long-text';
import UserRole from '../user-role';

import WithPermission, { editUsers } from '../../modules/with-permission';
import LinkButton, { editIconStyle } from '../link-button';

const UserTableRow = ({
  id,
  name,
  email,
  roles,
  last_activity_at,
  t,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);

  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle"><LongText value={name} /></td>
      <td className="align-middle hideField"><LongText value={email} maxLength={40} /></td>
      <td className="align-middle"><UserRole roles={roles} /></td>
      <td className="align-middle hideField">
        { moment(last_activity_at).isValid() ? moment(last_activity_at).format('LLLL') : t('never') }
      </td>
      <WithPermission permission={editUsers}>
        <td className="align-middle">
          <LinkButton
            to={`/users/${id}/edit`}
            iconStyle={editIconStyle}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
            title={t('edit')}
          />
        </td>
      </WithPermission>
    </tr>
  );
};

UserTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  roles: PropTypes.array.isRequired,
  last_activity_at: PropTypes.string,
  t: PropTypes.func.isRequired,
};

UserTableRow.defaultProps = {
  last_activity_at: '-',
};

export default compose(
  withTranslation(),
  React.memo,
)(UserTableRow);
