import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import WithPermission, { viewOrders, editOrders, editOrderDetails } from '../../modules/with-permission';

import LinkButton, { viewIconStyle, editIconStyle } from '../link-button';
import { loadingTimeStatuses } from '../../modules/orders/utils';

const alreadyPickedStyle = {
  opacity: 0.4,
};

const OrderTableRow = ({
  t,
  id,
  number,
  trip,
  status,
  uom,
  hasViewLink,
  hasEditLink,
  justPicked,
  justExpired,
  kpi_proposes,
  viewLinkRouterState,
  hasEditDetailsLink,
  loading_time_status,
  displayStatus,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  const clientName = _.get(trip, 'client_address.client.name');

  const shouldHighlightType = !_.isEmpty(uom);
  const getHighlightStyle = () => {
    const isBulkType = uom === 'tons';
    return isBulkType ? 'highlight-light-yellow' : 'highlight-light-blue';
  };

  const styleClassName = `${rowHoverDisabled
    ? 'hover-disabled'
    : 'hover-enabled'} ${shouldHighlightType ? getHighlightStyle() : ''}`;

  const currentTimeStatusClass = loadingTimeStatuses[loading_time_status] ?? loadingTimeStatuses.default;

  return (
    <tr
      style={justPicked || justExpired ? alreadyPickedStyle : null}
      className={styleClassName}
    >
      <td className="align-middle">
        {number}
        {displayStatus && (
          <div
            className={`loading-time-status-icon ${currentTimeStatusClass}`}
          />
        )}
      </td>
      {clientName && <td className="align-middle hideMobile">{ clientName }</td>}
      <td className="align-middle hideMobile">{ _.get(status, 'name', t('unknown')) }</td>
      {
      !_.isEmpty(kpi_proposes) && (
      <td className="align-middle">
        { moment(_.head(kpi_proposes).expires_at).format('LLLL') }
      </td>
      )
    }
      <td className="align-middle contentRight">
        {
          hasViewLink && !justPicked && !justExpired && (
            <WithPermission permission={viewOrders}>
              <LinkButton
                to={{
                  pathname: `/orders/${id}/view`,
                  state: { viewLinkRouterState },
                }}
                iconStyle={viewIconStyle}
                onMouseEnter={() => setRowHoverDisabled(true)}
                onMouseLeave={() => setRowHoverDisabled(false)}
                title={t('details')}
              />
            </WithPermission>
          )
        }

        {
        hasEditLink && (
          <WithPermission permission={editOrders}>
            <LinkButton
              to={{
                pathname: `/orders/${id}/edit`,
                state: { prevPath: window.location.pathname },
              }}
              iconStyle={editIconStyle}
              onMouseEnter={() => setRowHoverDisabled(true)}
              onMouseLeave={() => setRowHoverDisabled(false)}
              title={t('edit')}
            />
          </WithPermission>
        )
        }
        {
        hasEditDetailsLink && (
          <WithPermission permission={editOrderDetails}>
            <LinkButton
              to={{
                pathname: `/orders/${id}/details/edit`,
                state: { prevPath: window.location.pathname },
              }}
              iconStyle={editIconStyle}
              onMouseEnter={() => setRowHoverDisabled(true)}
              onMouseLeave={() => setRowHoverDisabled(false)}
              title={t('edit')}
            />
          </WithPermission>
        )
      }
        {
        justPicked && <span>{ t('orders.alreadyPicked') }</span>
      }
        {
        justExpired && <span>{ t('orders.alreadyExpired') }</span>
      }
      </td>
    </tr>
  );
};

OrderTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  number: PropTypes.string.isRequired,
  trip: PropTypes.object,
  status: PropTypes.object,
  hasViewLink: PropTypes.bool,
  hasEditLink: PropTypes.bool,
  hasEditDetailsLink: PropTypes.bool,
  justPicked: PropTypes.bool,
  justExpired: PropTypes.bool,
  kpi_proposes: PropTypes.array,
  uom: PropTypes.string,
  viewLinkRouterState: PropTypes.object,
  loading_time_status: PropTypes.string,
  displayStatus: PropTypes.bool,
};

OrderTableRow.defaultProps = {
  status: {},
  hasViewLink: false,
  hasEditLink: true,
  justPicked: false,
  justExpired: false,
  kpi_proposes: undefined,
  viewLinkRouterState: undefined,
  hasEditDetailsLink: false,
  trip: undefined,
  uom: '',
  loading_time_status: '',
  displayStatus: false,
};

export default compose(
  withTranslation(),
  React.memo,
)(OrderTableRow);
