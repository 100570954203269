import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner';
import ActionCard from '../../components/action-card';


import withAPI from '../../hocs/with-api';
import HttpError from '../../components/http-error';
import KpiForm from '../../components/kpi-form';


class EditKpi extends PureComponent {
  constructor() {
    super();
    this.state = { kpiList: [] };
  }

   componentDidMount = async () => {
     const { httpRequest } = this.props;
     const response = await httpRequest({
       method: 'get',
       url: 'kpi',
     });

     if (response) {
       const {
         data: kpiList,
       } = response;
       this.setState({
         kpiList,
       });
     }
   }

   updateKpi = async ({
     kpiList,
   }) => {
     const { t, httpRequest, history } = this.props;
     const data = {
       kpi: kpiList,
     };

     const patched = await httpRequest({
       method: 'patch',
       url: 'kpi',
       data,
     });
     if (patched) {
       history.push({
         pathname: '/kpi',
         flash: {
           type: 'success',
           message: t('kpi.edited'),
         },
       });
     }
   }

   render() {
     const { kpiList } = this.state;
     const {
       httpGetLoading,
       httpGetDone,
       httpGetFailed,
       t,
       history,
     } = this.props;

     return (
       <ActionCard
         title={t('kpi.edit')}
         actionButtonWidth={50}
         actionButtonStyleClass="back-button"
         onActionButtonClick={() => history.goBack()}
         actionButtonIcon="/assets/back-button-icon.png"
       >
         {
             httpGetLoading && <Spinner marginTop="555px" />
           }
         {
             httpGetFailed && (
             <HttpError
               withTryAgainButton
               onTryAgain={this.loadKPI}
             />
             )
           }
         { !httpGetLoading && !httpGetFailed && httpGetDone && !_.isEmpty(kpiList) && (
         <KpiForm
           t={t}
           kpis={kpiList}
           onSubmit={this.updateKpi}
           isConfirmButtonLoading={httpGetLoading}
         />
         )}
       </ActionCard>
     );
   }
}

EditKpi.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

EditKpi.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
  httpGetDone: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(EditKpi);
