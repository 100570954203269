import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styles from './styles.module.css';

import Spinner from '../../components/spinner';

import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import Table from '../../components/table';
import VehicleStatusTableRow from '../../components/vehicle-status-table-row';

class VehicleStatuses extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      vehicles: null,
      days: null,
      selectedDate: moment().toDate(),
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('vehicles.statuses')} - ${t('app.name')}`;
    this.loadVechicles();
  }

  loadVechicles = async () => {
    const { httpRequest } = this.props;
    const { selectedDate } = this.state;

    const date = moment(selectedDate).format('YYYY-MM-DD');

    const url = `vehicles/statuses?date=${date}`;
    const response = await httpRequest({
      method: 'get',
      url,
    });
    if (response) {
      const {
        data: vehicles,
      } = response;

      const { days } = vehicles.length > 0 ? vehicles[0] : [];

      this.setState({
        vehicles,
        days,
      });
    }
  };

  handleSetDateFrom = (date) => {
    this.setState({
      selectedDate: date,
    }, () => this.loadVechicles());
  };

  render() {
    const colorByDayStatus = (status) => {
      switch (status) {
        case 'STATUS_DAY_FINISHED':
          return styles.finished;
        case 'STATUS_DAY_IN_PROGRESS':
          return styles.inProgress;
        default:
          return '';
      }
    };

    const {
      t,
      httpGetLoading,
      httpGetFailed,
      httpGetDone,
    } = this.props;

    const statusDayName = {
      STATUS_DAY_EMPTY: t('vehicle.status.empty'),
      STATUS_DAY_FINISHED: t('vehicle.status.finished'),
      STATUS_DAY_IN_PROGRESS: t('vehicle.status.busy'),
    };

    const {
      vehicles,
      days,
      selectedDate,
    } = this.state;

    return (
      <>

        <Col md="6">
          <p className={`lf-main-text inline-block ${styles.head}`}>{ t('vehicles.statuses') }</p>
          <p className="inline-block">
            {
              _.map(statusDayName, (dayStatus, key) => (
                <span key={key} className={styles.legendItem}>
                  <span className={`${styles.statusDay} ${colorByDayStatus(key)}`} />
                  {dayStatus}
                </span>
              ))
            }
          </p>
        </Col>
        <Col md="6" className="text-right">
          <div className="inline-block">
            <DatePicker
              onChange={this.handleSetDateFrom}
              highlightDates={Array.from(Array(7).keys()).map((i) => moment(selectedDate).subtract(i, 'days').toDate())}
              selected={selectedDate}
              className="input-clean center-text kpi-date-picker"
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
              maxDate={moment().toDate()}
            />
          </div>
        </Col>
        <Col md="12">
          {httpGetLoading && <Spinner />}
          {!httpGetLoading
          && (
            <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(vehicles)} emptyMessage={t('messages.noData')}>
              <thead>
                <tr>
                  <th className="align-middle">{t('carriers.one')}</th>
                  <th className="align-middle">{t('nr.reg')}</th>
                  <th className="align-middle">{t('kpi.status')}</th>
                  {
                    _.map(days, (day, key) => (
                      <th className="align-middle" key={key}>{key}</th>
                    ))
                  }
                </tr>
              </thead>
              {!httpGetFailed && httpGetDone && vehicles && (
              <tbody>
                {
                  _.map(vehicles, (vehicle, key) => (
                    <VehicleStatusTableRow key={key} {...vehicle} />
                  ))
                }
              </tbody>
              )}
            </Table>
          )}
        </Col>
      </>
    );
  }
}

VehicleStatuses.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetFailed: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withRouter,
  withUserInfo,
  withAPI,
)(VehicleStatuses);
