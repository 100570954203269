import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
  Alert,
  Button,
} from 'reactstrap';

const InfoAlert = ({ message, onClose, ...rest }) => (
  <Alert key={message} className="alert alert-info info-alert-bg" {...rest}>
    <Row>
      <Col className="alert-message">{message}</Col>
      { onClose && (
      <Col lg="20" className="close-btn-container">
        <Button
          icon="/assets/success-close-icon.png"
          className="float-right close-btn"
          onClick={onClose}
        />
      </Col>
      )}
    </Row>
  </Alert>
);

InfoAlert.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

InfoAlert.defaultProps = {
  message: '',
  onClose: undefined,
};

export default InfoAlert;
