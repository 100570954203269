import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles.module.css';

const ButtonWithConfirmation = ({
  t,
  buttonLabel,
  headerText,
  confirmText,
  onConfirm,
  onCancel,
  buttonProps,
  confirmationModalError,
  icon,
  width,
  height,
  alignment,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = onCancel || (() => setModal(!modal));
  const iconStyle = {
    backgroundImage: `url(${icon})`,
    backgroundSize: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '15px center',
  };
  const confirmAndDismiss = () => {
    onConfirm();
    toggle();
  };

  return (
    <>
      <Button
        style={{ width, height, ...iconStyle }}
        className={`${styles.iconBtn} ${alignment} btn-delete`}
        onClick={toggle}
        {...buttonProps}
      >
        <span>
          {' '}
          {buttonLabel }
        </span>
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody>
          { confirmationModalError && <Alert color="danger">{confirmationModalError}</Alert>}
          {confirmText}
        </ModalBody>
        <ModalFooter>
          <Button
            className={`${styles.iconBtn} right-aligned`}
            onClick={confirmAndDismiss}
          >
            {t('yes')}
          </Button>
          <Button
            className={`${styles.cancel} right-aligned btn-cancel`}
            onClick={toggle}
          >
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ButtonWithConfirmation.propTypes = {
  t: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmationModalError: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
  onCancel: PropTypes.func,
  icon: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alignment: PropTypes.string,
};

ButtonWithConfirmation.defaultProps = {
  onCancel: null,
  buttonProps: {},
  confirmationModalError: null,
  icon: '',
  width: '200px',
  height: '40px',
  alignment: 'right-aligned',
};

export default compose(
  withTranslation(),
)(ButtonWithConfirmation);
