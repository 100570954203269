import React from 'react';
import moment from 'moment-timezone';

import { Row, Col } from 'reactstrap';
import APIInfo from '../../modules/api-info';
import styles from './styles.module.css';

const Footer = () => (
  <footer className={`${styles.footer} footerText`}>
    <Row className={styles.container}>
      <Col lg="2" className={`${styles.apiHealth} footerColInfo`}>
        <APIInfo />
      </Col>
      <Col className={`${styles.appVersion} footerColInfo`}>
        Copyright © 2020, Lafarge,
        <span className="breakBlock" />
        Client version:
        { process.env.REACT_APP_GIT_SHA }
        {' '}
          |
        {' '}
        { moment.unix(process.env.REACT_APP_BUILD_DATE).tz('Europe/Chisinau').format('DD MMM YYYY HH:mm') }
      </Col>
    </Row>
  </footer>
);

export default Footer;
