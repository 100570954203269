import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LinkButton, { editIconStyle } from '../link-button';

const statusIcon = {
  danger: '/assets/error-icon.png',
  warning: '/assets/warning-icon.png',
};

const statusIconStyle = (status) => {
  const icon = _.get(statusIcon, _.lowerCase(status), '/assets/info-icon.png');
  return {
    backgroundImage: `url('${icon}')`,
    backgroundSize: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    height: '20px',
    width: '40px',
  };
};

const IssueTableRow = ({ issue, t }) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle">
        <div style={statusIconStyle(issue.status)} />
      </td>
      <td className="align-middle">
        {issue.message}
      </td>
      <td className="align-middle">

        {issue.objectId && (
        <LinkButton
          to={{
            pathname: `/${issue.path}/${issue.objectId}/edit`,
            state: { prevPath: window.location.pathname },
          }}
          iconStyle={editIconStyle}
          onMouseEnter={() => setRowHoverDisabled(true)}
          onMouseLeave={() => setRowHoverDisabled(false)}
          title={t('edit')}
        />
        )}
      </td>
    </tr>
  );
};


IssueTableRow.propTypes = {
  issue: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default IssueTableRow;
