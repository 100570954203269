import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  withGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import ActionCard from '../../components/action-card';
import OrderDetails from '../../components/order-details';
import Spinner from '../../components/spinner';

import withUser from '../../hocs/with-user';
import withAPI from '../../hocs/with-api';
import withEcho from '../../hocs/with-echo';
import withUserInfo from '../../hocs/with-user-info';
import SuccessAlert from '../../components/success-alert';
import HttpErrorHandler from '../../components/http-error-handler';
import Map from '../../components/map';
import InfoAlert from '../../components/info-alert';
import { getContextCarrier } from '../carrier-selector';
import { orderStatuses } from './constants';


class ViewOrder extends Component {
  constructor() {
    super();
    this.state = {
      order: null,
      canPickOrder: false,
      pickOrderMessages: [],
      orderPickedOk: false,
      orderAlreadyPicked: false,
      initialPosition: undefined,
      recaptchaToken: null,
    };
  }

  async componentDidMount() {
    const {
      match: { params: { id } },
      location,
      httpRequest,
      user,
      history,
      echoClient,
    } = this.props;
    const contextCarrierId = _.get(location, 'state.viewLinkRouterState.contextCarrierId');

    const response = await httpRequest({
      method: 'get',
      url: `orders/${id}`,
    }).catch(() => {
      history.goBack();
    });

    const order = _.get(response, 'data');

    if (!order) {
      return;
    }

    this.setState({
      order,
    });

    if (user.isDispatcher || contextCarrierId) {
      const { data: { can_pick: canPick, messages: pickMessage } } = await httpRequest({
        method: 'get',
        url: `orders/can-pick/${id}${contextCarrierId ? `?carrier_id=${contextCarrierId}` : ''}`,
        tag: 'canPick',
      });
      this.setState({
        order,
        canPickOrder: canPick,
        pickOrderMessages: pickMessage,
      });
    }

    if (order && _.get(order, 'vehicle.geo.started', false)) {
      const { geo } = _.get(order, 'vehicle');
      this.setState({
        initialPosition: [geo.lat, geo.long],
        channel: `order.${order.id}.vehicle.geo.changed`,
      });
    }

    window.onpopstate = this.onBackButtonClick;
    echoClient
      .channel('order.picked')
      .listen('OrderPicked', this.onOrderPicked);

    const { googleReCaptchaProps } = this.props;
    const token = await googleReCaptchaProps.executeRecaptcha('orderDetails');
    this.setState({
      recaptchaToken: token,
    });
  }

  componentWillUnmount() {
    window.onpopstate = null;
    const { echoClient } = this.props;
    echoClient.leave();
  }

  onBackButtonClick = () => {
    this.goBack();
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }


  onOrderPicked = (event) => {
    const { order } = this.state;
    const { user } = this.props;
    const { data: { picked_by: pickedBy, order: { id } } } = event;

    if (order.id === id && user.id !== pickedBy) {
      this.setState({
        orderAlreadyPicked: true,
      });
    }
  }

  onPickOrderButtonClick = async (id) => {
    const { httpRequest, location } = this.props;
    const contextCarrierId = _.get(location, 'state.viewLinkRouterState.contextCarrierId');
    this.setState({ orderPickedOk: false });
    const { recaptchaToken: token } = this.state;
    const pickResponse = await httpRequest({
      method: 'get',
      url: `orders/pick/${id}?recaptcha_token=${token}${contextCarrierId ? `&carrier_id=${contextCarrierId}` : ''}`,
      tag: 'pick',
    });

    if (pickResponse) {
      this.setState({ orderPickedOk: true });
    } else {
      this.setState({ orderPickedOk: false });
    }
  }

  onRefuseOrderButtonClick = async (id) => {
    const { httpRequest, location } = this.props;
    const contextCarrierId = _.get(location, 'state.viewLinkRouterState.contextCarrierId');
    this.setState({ orderPickedOk: false });
    const refuseResponse = await httpRequest({
      method: 'get',
      url: `orders/refuse/${id}${contextCarrierId ? `?carrier_id=${contextCarrierId}` : ''}`,
      tag: 'refuse',
    });
    if (refuseResponse) {
      this.goBack();
    }
  }

  render() {
    const {
      httpGetLoading,
      httpGetLoadingRefuse,
      httpGetLoadingPick,
      httpGetDone,
      httpGetFailed,
      httpGetFailReason,
      t,
      httpGetFailedPick,
      httpGetFailReasonPick,
      httpGetFailedRefuse,
      httpGetFailReasonRefuse,
      location,
      user,
    } = this.props;

    const {
      order,
      canPickOrder,
      pickOrderMessages,
      orderPickedOk,
      orderAlreadyPicked,
      channel,
      initialPosition,
      recaptchaToken,
    } = this.state;
    const contextCarrierId = _.get(location, 'state.viewLinkRouterState.contextCarrierId');
    const contextCarrier = getContextCarrier();
    const shouldDisablePickButton = httpGetLoadingPick
          || !canPickOrder
          || orderPickedOk
          || orderAlreadyPicked
          || _.isNil(recaptchaToken);
    const proposes = _.get(order, 'kpi_proposes', []);
    const sortedProposes = _.sortBy(proposes, (kpiPropose) => new Date(_.get(kpiPropose, 'created_at', '')));
    const latestKpiPropose = _.last(sortedProposes);

    const proposeCarrierId = _.get(latestKpiPropose, 'carrier_id', '');
    const orderCarrierId = _.get(user, 'carriers[0].id', contextCarrierId);
    const withRefuseButton = _.get(latestKpiPropose, 'status', '') === 'PENDING'
          && (user.isDispatcher || contextCarrierId)
          && (proposeCarrierId === orderCarrierId);

    const isRefuseOrderButtonDisabled = httpGetLoadingRefuse
    || httpGetLoadingPick
    || orderPickedOk
    || orderAlreadyPicked;

    return (
      <Col className="no-gutters">
        {
            httpGetLoading && <Spinner />
          }
        {
            orderPickedOk && <SuccessAlert message={t('orders.picked')} />
          }
        {
            orderAlreadyPicked && (
            <InfoAlert
              message={t('orders.pickedBySomeoneElse')}
            />
            )
          }
        {
          !canPickOrder && order?.status_id === orderStatuses.transmittedToPool
          && order?.force_sent_to_shared === true
          && pickOrderMessages?.length > 0 && (
            <>
              <div className="font-weight-bold">Nu puteți prelua comanda din următorul motiv:</div>
              <ul>
                {pickOrderMessages?.map((pickOrderMessage) => (
                  <li className="text-danger" key={pickOrderMessage}>
                    {pickOrderMessage}
                  </li>
                ))}
              </ul>
            </>
          )
        }
        {
            httpGetFailed && httpGetFailReason && <HttpErrorHandler {...httpGetFailReason} />
          }
        {
            httpGetFailedPick && httpGetFailReasonPick && <HttpErrorHandler {...httpGetFailReasonPick} />
          }
        {
            httpGetFailedRefuse && httpGetFailReasonRefuse && <HttpErrorHandler {...httpGetFailReasonRefuse} />
          }
        {
            httpGetDone && order && (
              <>
                  {
                    contextCarrierId && contextCarrier
                    && (
                    <InfoAlert
                      message={t('context.warning', { name: contextCarrier.name })}
                    />
                    )
                  }

                <OrderDetails
                  withBackButton
                  withPickOrderButton={canPickOrder}
                  isPickOrderButtonDisabled={shouldDisablePickButton}
                  onBackButtonClick={this.onBackButtonClick}
                  onPickOrderButtonClick={this.onPickOrderButtonClick}
                  withRefuseOrderButton={withRefuseButton}
                  onRefuseOrderButtonClick={this.onRefuseOrderButtonClick}
                  isRefuseOrderButtonDisabled={isRefuseOrderButtonDisabled}
                  isAdmin={user.isAdmin}
                  isPlanner={user.isPlanner}
                  {...order}
                />
              </>
            )
          }
        {
            channel && initialPosition && (

            <ActionCard
              title={t('map.view')}
              withActionButton={false}
            >
              <Map channel={channel} initialPosition={initialPosition} />
            </ActionCard>
            )
          }
      </Col>
    );
  }
}

ViewOrder.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpGetFailedPick: PropTypes.bool,
  httpGetLoadingPick: PropTypes.bool,
  httpGetFailReasonPick: PropTypes.object,
  httpGetFailed: PropTypes.bool,
  withRefuseButton: PropTypes.bool,
  httpGetLoadingRefuse: PropTypes.bool,
  httpGetFailReason: PropTypes.any,
  httpGetFailedRefuse: PropTypes.bool,
  httpGetFailReasonRefuse: PropTypes.any,
  echoClient: PropTypes.object.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired,
};

ViewOrder.defaultProps = {
  httpGetFailedPick: false,
  httpGetLoadingPick: false,
  httpGetLoadingRefuse: false,
  httpGetFailReasonPick: undefined,
  httpGetFailed: false,
  httpGetFailReason: undefined,
  withRefuseButton: false,
  httpGetFailedRefuse: false,
  httpGetFailReasonRefuse: undefined,
};
export default compose(
  withTranslation(),
  withRouter,
  withUserInfo,
  withAPI,
  withUser,
  withEcho,
  withGoogleReCaptcha,
)(ViewOrder);
