import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import LongText from '../long-text';

import WithPermission, { editCarriers } from '../../modules/with-permission';
import LinkButton, { editIconStyle } from '../link-button';

const CarrierTableRow = ({
  id,
  name,
  description,
  market_share: marketShare,
  t,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);

  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle"><LongText value={name} maxLength={40} /></td>
      <td className="align-middle hideMobile"><LongText value={description} maxLength={40} /></td>
      <td className="align-middle"><LongText value={marketShare} /></td>

      <WithPermission permission={editCarriers}>
        <td className="align-middle">
          <LinkButton
            to={{
              pathname: `/carriers/${id}/edit`,
              state: { prevPath: window.location.pathname },

            }}
            iconStyle={editIconStyle}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
            title={t('edit')}
          />
        </td>
      </WithPermission>
    </tr>
  );
};

CarrierTableRow.defaultProps = {
  description: '-',
  market_share: '-',
};

CarrierTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  market_share: PropTypes.string,
};

export default compose(
  withTranslation(),
)(CarrierTableRow);
