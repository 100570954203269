export const buildNotification = ({
  onClickCallback,
  title,
  body,
}) => {
  const notification = new Notification(title, { body });
  notification.onclick = onClickCallback;
  return notification;
};

export const askForNotificationPermission = () => {
  if (typeof (Notification) !== 'undefined') {
    if (Notification.permission !== 'denied' && Notification.permission !== 'granted') {
      try {
        Notification.requestPermission().then(() => {
        });
      } catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission(() => {
          });
        }
      }
    }
  }
};

export const showBrowserNotification = (args) => {
  buildNotification(args);
};
