import axios from 'axios';

const getAPIClient = (token) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  } else {
    console.warn('Building an API client without an API Token');
  }
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000,
    headers,
  });
};

export default getAPIClient;
