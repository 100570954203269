import React from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  RadarChart as OriginalRadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend,
} from 'recharts';
import styles from './styles.module.css';

const COLOURS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RadarChart = (props) => {
  const {
    t, dataLoaded, data, labels,
  } = props;

  if (!dataLoaded) {
    return <div className={styles.noData}><FontAwesomeIcon icon={faSpinner} spin /></div>;
  }

  return data.length === 0 ? (
    <div className={styles.noData}>{t('homepage.charts.noData')}</div>
  ) : (
    <OriginalRadarChart
      width={400}
      height={300}
      className={styles.radar}
      data={data}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={18} domain={[0, 100]} />

      {
        _.map(labels, (entry, index) => (
          <Radar
            key={index}
            name={entry.label}
            dataKey={entry.key}
            stroke={COLOURS[index % COLOURS.length]}
            fill={COLOURS[index % COLOURS.length]}
            fillOpacity={0.6}
          />
        ))
      }

      <Legend layout="vertical" verticalAlign="middle" align="right" />
    </OriginalRadarChart>
  );
};

RadarChart.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
  labels: PropTypes.array,
  dataLoaded: PropTypes.bool,
};

RadarChart.defaultProps = {
  data: [],
  labels: [],
  dataLoaded: true,
};

export default compose(
  withTranslation(),
)(RadarChart);
