import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';

import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';
import UserTableRow from '../../components/user-table-row';
import WithPermission, { addUsers, editUsers } from '../with-permission';

import withAPI from '../../hocs/with-api';
import FlashMessage from '../../components/flash-message';

class Users extends PureComponent {
  constructor(props) {
    super(props);
    const currentPage = getCurrentPageNumber(props);
    this.state = {
      users: [],
      currentPage,
      totalPages: 1,
      totalRecords: 1,
      perPage: 1,
      from: 1,
      to: 1,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('users.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadUsers();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadUsers);
  }

  loadUsers = async () => {
    const { httpRequest } = this.props;
    const { currentPage } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `users?page=${currentPage}`,
    });
    if (response) {
      const {
        data: {
          data: users,
          total,
          per_page,
          last_page,
          from,
          to,
        },
      } = response;
      this.setState({
        users,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/users/${currentPage}`);
      this.loadUsers();
    });
  }

  render() {
    const {
      t,
      history,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
    } = this.props;

    const {
      users, currentPage, totalPages, perPage, totalRecords, from, to,
    } = this.state;

    return (
      <>
        <ActionCard
          title={t('users.all')}
          actionButtonLabel={t('users.createNew')}
          onActionButtonClick={() => history.push('/users/new')}
          actionButtonRequiredPermission={addUsers}
          actionButtonIcon="/assets/add-icon.png"
        >
          {
            httpGetLoading && <Spinner marginTop="555px" />
          }
          {
            <FlashMessage />
          }
          {
            httpGetFailed && (
            <HttpError
              withTryAgainButton
              onTryAgain={this.loadUsers}
            />
            )
          }
          { !httpGetLoading && !httpGetFailed && httpGetDone && (
            <>
              <Table hover responsive="sm" size="sm">
                <thead>
                  <tr>
                    <th className="align-middle">{ t('fullname') }</th>
                    <th className="align-middle hideField">{ t('email') }</th>
                    <th className="align-middle">{ t('role') }</th>
                    <th className="align-middle hideField">{ t('activity.last') }</th>
                    <WithPermission permission={editUsers}>
                      <th className="lfg-actions align-middle actions">{ t('actions') }</th>
                    </WithPermission>
                  </tr>
                </thead>
                <tbody>
                  { _.map(users, (user) => <UserTableRow key={user.id} {...user} />) }
                </tbody>
              </Table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={this.handlePageChange}
                totalRecords={totalRecords}
                perPage={perPage}
                from={from}
                to={to}
              />
            </>
          )}
        </ActionCard>
      </>
    );
  }
}

Users.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Users.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(Users);
