import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
  Alert,
  Button,
} from 'reactstrap';

const SuccessAlert = ({ message, onClose, ...rest }) => (
  <Alert key={message} className="alert alert-success success-alert-bg" {...rest} variant="danger">
    <Row>
      <Col className="alert-message">{message}</Col>
      { onClose && (
      <Col lg="20" className="close-btn-container">
        <Button
          icon="/assets/success-close-icon.png"
          className="float-right close-btn"
          onClick={onClose}
        />
      </Col>
      )}
    </Row>
  </Alert>
);

SuccessAlert.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

SuccessAlert.defaultProps = {
  message: '',
  onClose: undefined,
};

export default SuccessAlert;
