import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';

export const roles = {
  administrator: 1,
  planner: 2,
  callCenter: 3,
  dispatcher: 4,
};
function withUserInfo(WrappedComponent) {
  return class extends React.Component {
    constructor() {
      super();
      this.state = {
        isTokenExpired: false,
      };
    }

    componentDidMount = () => {
      const json = localStorage.getItem('lfg-tokens');
      const tokens = json ? JSON.parse(json) : null;
      if (!tokens) return;
      const expiryDate = moment(tokens.created_at).add(_.toNumber(tokens.expires_in), 'seconds');
      if (expiryDate.isBefore()) {
        this.setState({
          isTokenExpired: true,
        });
      }
    }

    isAuthenticated = () => Boolean(localStorage.getItem('lfg-is-authenticated'));

    user = () => {
      const json = localStorage.getItem('lfg-profile');
      return json ? JSON.parse(json) : {};
    };

    getRole = () => {
      const user = this.user();
      if (_.isEmpty(user)) return null;
      return _.get(user, 'roles[0].identifier', null);
    }

    render() {
      const role = this.getRole();
      const user = this.user();
      const rolesDef = {
        isAdmin: role === 'administrator',
        isPlanner: role === 'planner',
        isCallCenter: role === 'call_center',
        isDispatcher: role === 'dispatcher',
      };
      const merged = { ...user, ...rolesDef };
      const { isTokenExpired } = this.state;
      if (isTokenExpired) {
        return <Redirect to="/logout" />;
      }
      return (
        <WrappedComponent
          {...this.props}
          {...rolesDef}
          user={merged}

          isAuthenticated={this.isAuthenticated()}
        />
      );
    }
  };
}

export default withUserInfo;
