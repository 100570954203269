import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import _ from 'lodash';

import withAPI from '../../hocs/with-api';
import LongText from '../../components/long-text';

const style = {
  margin: 0,
};

export const setContextCarrier = (carrier) => {
  localStorage.setItem('lfg-context-carrier', JSON.stringify(carrier));
};
export const getContextCarrier = () => {
  const o = localStorage.getItem('lfg-context-carrier');
  return o ? JSON.parse(o) : null;
};

class CarrierSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoading: true,
      carriers: [],
    };
  }

  async componentDidMount() {
    const { httpRequest } = this.props;
    const response = await httpRequest({
      method: 'get',
      url: 'carriers',
    });
    if (response) {
      const { data: carriers } = response;
      this.setState({
        carriers,
        isLoading: false,
      });
    }
  }

  onCarrierChanged = (carrier) => {
    const { onCarrierChanged } = this.props;
    onCarrierChanged(carrier);
  }

  toggleDropdownState = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { isOpen, carriers, isLoading } = this.state;
    const { t } = this.props;
    const selected = getContextCarrier();
    return (
      <Dropdown
        style={style}
        isOpen={isOpen}
        toggle={this.toggleDropdownState}
        className="float-right clean-dropdown"
      >
        <DropdownToggle caret>
          {
            isLoading && t('loading')
          }
          {
            !isLoading && !selected && t('select.carrier')
          }
          {
            !isLoading && carriers.length > 0 && selected && <LongText value={selected.name} withTooltip={false} />
          }
        </DropdownToggle>
        <DropdownMenu>
          {
              _.map(carriers, (carrier) => (
                <DropdownItem
                  key={carrier.id}
                  onClick={() => this.onCarrierChanged(carrier)}
                >
                  {carrier.name}
                </DropdownItem>
              ))
            }
        </DropdownMenu>
      </Dropdown>
    );
  }
}

CarrierSelector.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  onCarrierChanged: PropTypes.func.isRequired,
};


export default compose(
  withAPI,
  withTranslation(),
)(CarrierSelector);
