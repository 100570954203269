import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import Address from '../address';
import LinkButton, { editIconStyle } from '../link-button';


const centerAlign = { verticalAlign: 'middle' };
const ClientTableRow = ({
  client,
  t,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);

  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle" style={centerAlign}>
        {client.name}
      </td>
      <td className="align-middle hideMobile">
        <Address {...client} />
      </td>
      <td className="align-middle" style={centerAlign}>
        <LinkButton
          to={`/clients/${client.id}/edit`}
          iconStyle={editIconStyle}
          onMouseEnter={() => setRowHoverDisabled(true)}
          onMouseLeave={() => setRowHoverDisabled(false)}
          title={t('edit')}
        />
      </td>
    </tr>
  );
};


ClientTableRow.propTypes = {
  client: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
)(ClientTableRow);
