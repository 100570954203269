import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const LongText = ({
  value,
  maxLength,
  omission,
  withTooltip,
}) => {
  const shouldTruncate = _.size(value) > maxLength;
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleHover = (hoverShown) => {
    setShowTooltip(hoverShown);
  };

  return shouldTruncate ? (
    <>
      {
        withTooltip && showTooltip && (
        <div className="tooltip">
          { value }
        </div>
        )
      }
      <div
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        { _.truncate(value, { length: maxLength, omission })}
      </div>
    </>
  )
    : <>{value}</>;
};

LongText.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  omission: PropTypes.string,
  withTooltip: PropTypes.bool,
};

LongText.defaultProps = {
  maxLength: 30,
  omission: '...',
  withTooltip: true,
};

export default LongText;
