import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner';

import withAPI from '../../hocs/with-api';
import HttpErrorHandler from '../../components/http-error-handler';
import OrderForm from '../../components/order-form';
import ButtonWithIcon from '../../components/button-with-icon';

class EditOrder extends PureComponent {
  constructor() {
    super();
    this.state = { order: null, statuses: [] };
  }

   componentDidMount = async () => {
     const { httpRequest, history, match: { params: { id: orderId } } } = this.props;
     const response = await httpRequest({
       method: 'get',
       url: `/orders/${orderId}`,
     }).catch(() => {
       history.push('/dashboard');
     });
     const order = _.get(response, 'data');
     if (!order) { return; }
     if (order) {
       this.setState({
         order,
       });
     }
     this.loadStatues();
   }

   loadStatues = async () => {
     const { httpRequest } = this.props;
     const { data: statuses } = await httpRequest({
       method: 'get',
       url: 'statuses',
     });
     this.setState({ statuses });
   }

   updateOrder = async ({
     deliveryDateFrom,
     deliveryDateTo,
     claimActive,
     claimText,
     status,
     realLoadingTime,
     realDeliveryTime,
   }) => {
     const {
       t, httpRequest, match: { params: { id } }, history,
     } = this.props;

     const data = {
       delivery_from: deliveryDateFrom,
       delivery_to: deliveryDateTo,
       claimed: claimActive,
       claim_details: claimText,
       real_loading_time: realLoadingTime,
       real_delivery_time: realDeliveryTime,
       status_id: status.id,
     };

     const patched = await httpRequest({
       method: 'patch',
       url: `orders/${id}`,
       data,
     });
     if (patched) {
       history.push({
         pathname: _.get(this.props, 'location.state.prevPath', '/orders'),
         flash: {
           type: 'success',
           message: t('orders.edited'),
         },
       });
     }
   }

   deleteOrder = async () => {
     const {
       t, httpRequest, match: { params: { id } }, history,
     } = this.props;
     const patched = await httpRequest({
       method: 'delete',
       url: `orders/${id}`,
     });
     if (patched) {
       history.push({
         pathname: _.get(this.props, 'location.state.prevPath', '/orders'),
         flash: {
           type: 'success',
           message: t('orders.deleted'),
         },
       });
     }
   }

   onBackButtonClick = () => {
     const { history } = this.props;
     history.goBack();
   }

   render() {
     const { order, statuses } = this.state;
     const {
       httpGetLoading,
       httpGetDone,
       t,
       httpPatchFailed,
       httpPatchFailReason,
       httpPatchLoading,
       httpDeleteLoading,
       httpDeleteFailed,
       httpDeleteFailReason,
     } = this.props;

     return (
       <>
         <Col md="12">
           <Row className="with-back-button">
             <p className="lf-main-text form-name-text">{ t('orders.edit') }</p>
             <ButtonWithIcon
               height="40px"
               icon="/assets/back-icon.png"
               width="50px"
               className="float-right back-button"
               onClick={this.onBackButtonClick}
             />
           </Row>
         </Col>
         {
            httpGetLoading && <Spinner />
          }
         {
            !httpGetLoading && httpGetDone && order
            && (
            <Col md="12">
              {
                httpPatchFailed && httpPatchFailReason
                && <HttpErrorHandler {...httpPatchFailReason} />
              }
              {
                httpDeleteFailed && httpDeleteFailReason
                && <HttpErrorHandler {...httpDeleteFailReason} />
              }
              <OrderForm
                t={t}
                order={order}
                onSubmit={this.updateOrder}
                onDelete={this.deleteOrder}
                isConfirmButtonLoading={httpPatchLoading}
                isDeleteButtonLoading={httpDeleteLoading}
                statuses={statuses}
              />
            </Col>
            )
          }
       </>
     );
   }
}

EditOrder.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpPatchLoading: PropTypes.bool,
  httpDeleteLoading: PropTypes.bool,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
};

EditOrder.defaultProps = {
  httpGetLoading: true,
  httpGetDone: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpPatchLoading: false,
  httpDeleteLoading: false,
  httpDeleteFailReason: undefined,
  httpDeleteFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(EditOrder);
