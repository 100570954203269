import React, { useState } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Form,
  FormGroup,
  Col,
} from 'reactstrap';
import DropdownInput from '../dropdown-input';
import ButtonWithIcon from '../button-with-icon';
import withEcho from '../../hocs/with-echo';

const OrderDetailsForm = ({
  order,
  onSubmit,
  drivers,
  vehicles,
  t,
  isConfirmButtonLoading,
  echoClient,
}) => {
  const [driver, setDriver] = useState(_.get(order, 'driver_id', ''));
  const [vehicle, setVehicle] = useState(_.get(order, 'vehicle_id', ''));
  const handleSetDriver = (driverId) => setDriver(driverId);
  const handleSetVehicle = (vehicleId) => setVehicle(vehicleId);

  const driverObj = _.find(drivers, ['id', _.toNumber(driver)]);
  const vehicleObj = _.find(vehicles, ['id', _.toNumber(vehicle)]);
  const isSubmitButtonValid = driver && vehicle;

  const [isNotifySubmitButtonValid, setNotifySubmitButtonValid] = useState(isSubmitButtonValid);

  const values = {
    driver,
    vehicle,
  };

  const driversOptions = _.map(drivers, (record) => ({
    id: record.id,
    label: record.name,
    value: record.id,
  }));
  const vehiclesOptions = _.map(vehicles, (record) => ({
    id: record.id,
    label: `${record.number_plate} / ${record.number_plate_trailer}`,
    value: record.id,
  }));

  const defaultSelectedDriver = (driverObj ? {
    label: driverObj.name,
    value: driverObj,
    id: driverObj.id,
  } : undefined);

  const defaultSelectedVehicle = (vehicleObj ? {
    label: `${vehicleObj.number_plate} / ${vehicleObj.number_plate_trailer}`,
    value: vehicleObj,
    id: vehicleObj.id,
  } : undefined);

  echoClient
    .channel('order.changed')
    .listen('OrderDetailsChanged', (event) => {
      const { data: { order_id, vehicle_id, driver_id } } = event;
      if (order_id === order.id && vehicle_id !== null && driver_id !== null) {
        setNotifySubmitButtonValid(false);
      }
    });

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <DropdownInput
            required
            options={driversOptions}
            label={t('drivers.name')}
            placeholder={t('drivers.name')}
            onSelected={handleSetDriver}
            defaultSelected={defaultSelectedDriver}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <DropdownInput
            required
            options={vehiclesOptions}
            label={t('vehicle')}
            placeholder={t('vehicle')}
            onSelected={handleSetVehicle}
            defaultSelected={defaultSelectedVehicle}
          />
        </FormGroup>
      </Col>
      <Col md="12" className="btn-form-group">
        <ButtonWithIcon
          title={t('save')}
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isSubmitButtonValid || isConfirmButtonLoading}
          loading={isConfirmButtonLoading}
        />
        <ButtonWithIcon
          title={t('orders.saveAndNotify')}
          width="240px"
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isSubmitButtonValid || isConfirmButtonLoading
            || !isNotifySubmitButtonValid || order.logon_notification_sent
            || order.source === 'FILE'}
          loading={isConfirmButtonLoading}
          type="button"
          onClick={(e) => { e.preventDefault(); onSubmit(values, '/notify'); }}
        />
      </Col>
    </Form>
  );
};

OrderDetailsForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  order: PropTypes.object,
  isConfirmButtonLoading: PropTypes.bool,
  drivers: PropTypes.array,
  vehicles: PropTypes.array,
  echoClient: PropTypes.object.isRequired,
};

OrderDetailsForm.defaultProps = {
  order: {},
  isConfirmButtonLoading: false,
  drivers: [],
  vehicles: [],
};


export default compose(
  withEcho,
)(OrderDetailsForm);
