import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import Spinner from '../../components/spinner';
import VehicleForm from '../../components/vehicle-form';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import ButtonWithIcon from '../../components/button-with-icon';

class EditVehicle extends PureComponent {
  constructor() {
    super();
    this.state = { transport: null, carriers: [] };
  }

   componentDidMount = async () => {
     const {
       t, httpRequest, history, match: { params: { id: transportId } }, user,
     } = this.props;
     document.title = `${t('vehicles.edit')} - ${t('app.name')}`;

     const response = await httpRequest({
       method: 'get',
       url: `/vehicles/${transportId}`,
     }).catch(() => {
       history.push('/dashboard');
     });
     const transport = _.get(response, 'data');
     if (!transport) { return; }
     if (transport) {
       this.setState({
         transport,
       });
     }

     const { data: drivers } = await httpRequest({
       method: 'get',
       url: 'drivers',
       tag: 'drivers',
     });

     this.setState({
       drivers,
     });

     if (user.isDispatcher) return;

     const { data: carriers } = await httpRequest({
       method: 'get',
       url: 'carriers',
       tag: 'carriers',
     });

     this.setState({
       carriers,
     });
   }

   updateTransport = async ({
     tractorNr,
     trailerNr,
     truckSapId,
     truckExternalId,
     carrier,
     transportType,
     driver,
   }) => {
     const {
       t, httpRequest, match: { params: { id } }, history,
     } = this.props;
     const patched = await httpRequest({
       method: 'patch',
       url: `vehicles/${id}`,
       data: {
         number_plate: tractorNr,
         number_plate_trailer: trailerNr,
         truck_sap_id: truckSapId,
         external_id: truckExternalId,
         carrier_id: carrier,
         type: transportType,
         driver_id: driver === '' ? undefined : driver,
       },
     });
     if (patched) {
       history.push({
         pathname: '/vehicles',
         flash: {
           type: 'success',
           message: t('vehicles.edited'),
         },
       });
     }
   }

   onDelete = async (transportId) => {
     const { httpRequest, history, t } = this.props;
     const deleted = await httpRequest({
       method: 'delete',
       url: `vehicles/${transportId}`,
     });
     if (deleted) {
       history.push({
         pathname: '/vehicles',
         flash: {
           type: 'success',
           message: t('vehicles.deleted'),
         },
       });
     }
   }

   onBackButtonClick = () => {
     const { history } = this.props;
     history.goBack();
   }

   render() {
     const { transport, carriers, drivers } = this.state;
     const {
       t,
       httpPatchFailed,
       httpPatchFailReason,
       httpPatchLoading,
       httpDeleteLoading,
       httpDeleteFailed,
       httpDeleteFailReason,
       httpGetFailed,
       httpGetFailReason,
       httpGetLoading,
       httpGetDone,
     } = this.props;
     return (
       <>
         <Col md="12">
           <Row className="with-back-button">
             <p className="lf-main-text form-name-text">{ t('vehicles.edit') }</p>
             <ButtonWithIcon
               height="40px"
               icon="/assets/back-icon.png"
               width="50px"
               className="float-right back-button"
               onClick={this.onBackButtonClick}
             />
           </Row>
         </Col>

         <Col md="12">
           { httpGetLoading && <Spinner /> }
           { httpGetFailed && httpGetFailReason && <HttpErrorHandler {...httpGetFailReason} /> }
           { httpPatchFailed && httpPatchFailReason && <HttpErrorHandler {...httpPatchFailReason} /> }
           { httpDeleteFailed && httpDeleteFailReason && <HttpErrorHandler {...httpDeleteFailReason} /> }
           { httpGetDone && transport && (
           <VehicleForm
             inEditMode
             drivers={drivers}
             carriers={carriers}
             transport={transport}
             onSubmit={this.updateTransport}
             onDelete={this.onDelete}
             isConfirmButtonLoading={httpPatchLoading}
             isDeleteButtonLoading={httpDeleteLoading}
           />
           )}
         </Col>
       </>
     );
   }
}

EditVehicle.propTypes = {
  user: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoadingDrivers: PropTypes.bool,
  httpGetDoneDrivers: PropTypes.bool,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpPatchLoading: PropTypes.bool,
  httpDeleteLoading: PropTypes.bool,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
  httpGetFailed: PropTypes.bool,
  httpGetFailReason: PropTypes.bool,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
};

EditVehicle.defaultProps = {
  httpGetLoadingDrivers: true,
  httpGetDoneDrivers: false,
  httpPatchLoading: false,
  httpDeleteLoading: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpDeleteFailed: false,
  httpDeleteFailReason: undefined,
  httpGetFailed: false,
  httpGetFailReason: false,
  httpGetLoading: false,
  httpGetDone: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
  withUserInfo,
)(EditVehicle);
