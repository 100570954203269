import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import withAPI from '../../hocs/with-api';
import '../../date-picker.css';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../../components/table';
import { everything } from '../with-permission';
import KpiHistoryRow from '../../components/kpi-history-row';

const firstDate = moment().subtract(1, 'months').toDate();
class KpiHistory extends PureComponent {
  constructor() {
    super();
    this.state = {
      kpiDetails: {},
      selectedDate: firstDate,
      kpiDate: {
        month: moment(firstDate).month() + 1,
        year: moment(firstDate).year(),
      },
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('kpi.all')} - ${t('app.name')}`;
    this.loadKPI();
  }

  loadKPI = async () => {
    const { httpRequest } = this.props;
    const { kpiDate } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `kpi/export/preview?year=${kpiDate.year}&month=${kpiDate.month}`,
    });

    if (response) {
      const {
        data: kpiDetails,
      } = response;
      this.setState({
        kpiDetails,
      });
    }
  }

  handleSetDateFrom = (date) => {
    this.setState({
      selectedDate: date,
      kpiDate: {
        month: moment(date).month() + 1,
        year: moment(date).year(),
      },
    }, () => this.loadKPI());
  }

  onExportClick = async () => {
    const { httpRequest } = this.props;
    const { kpiDate } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `kpi/export?year=${kpiDate.year}&month=${kpiDate.month}`,
    });

    const data = new Blob([response.data], { contentType: 'text/csv' });
    const fileName = `Export-${kpiDate.year}-${kpiDate.month}.csv`;
    if (window.navigator.msSaveBlob) { // IE
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  onExportAllocatedClick = async () => {
    const { httpRequest } = this.props;
    const { kpiDate } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `orders/export/allocated?year=${kpiDate.year}&month=${kpiDate.month}`,
    });

    const data = new Blob([response.data], { contentType: 'text/csv' });
    const fileName = `ExportAllocatedOrders-${kpiDate.year}-${kpiDate.month}.csv`;
    if (window.navigator.msSaveBlob) { // IE
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  onExportSharedClick = async () => {
    const { httpRequest } = this.props;
    const { kpiDate } = this.state;
    const response = await httpRequest({
      method: 'get',
      url: `orders/export/shared?year=${kpiDate.year}&month=${kpiDate.month}`,
    });

    const data = new Blob([response.data], { contentType: 'text/csv' });
    const fileName = `ExportSharedOrders-${kpiDate.year}-${kpiDate.month}.csv`;
    if (window.navigator.msSaveBlob) { // IE
      window.navigator.msSaveOrOpenBlob(data, fileName);
    } else {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.setAttribute('download', fileName);
      link.setAttribute('href', url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    const {
      t,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
    } = this.props;
    const {
      kpiDetails,
      selectedDate,
    } = this.state;

    return (
      <>
        <ActionCard
          title={t('kpi.history')}

          withActionButton
          actionButtonLabel={t('kpi.export')}
          actionButtonRequiredPermission={everything}
          actionButtonIcon="/assets/save-icon.png"
          onActionButtonClick={this.onExportClick}

          withSecondaryActionButton
          secondaryActionButtonLabel={t('orders.allocated.export')}
          secondaryActionButtonIcon="/assets/save-icon.png"
          onSecondaryActionButtonClick={this.onExportAllocatedClick}

          withThirdActionButton
          thirdActionButtonLabel={t('orders.shared.export')}
          thirdActionButtonIcon="/assets/save-icon.png"
          onThirdActionButtonClick={this.onExportSharedClick}

          actionButtonDisabled={_.isEmpty(kpiDetails)}
          headerChildren={[
            <DatePicker
              onChange={this.handleSetDateFrom}
              selected={selectedDate}
              className="input-clean center-text kpi-date-picker"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              maxDate={moment().toDate()}
            />,
          ]}
        >
          {
            httpGetLoading && <Spinner marginTop="555px" />
          }
          {
            httpGetFailed && (
            <HttpError
              withTryAgainButton
              onTryAgain={this.loadKPI}
            />
            )
          }
          { !httpGetLoading && !httpGetFailed && httpGetDone && (
            <>
              <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(_.get(kpiDetails, 'rows.data', []))}>
                <thead>
                  <tr>
                    <th>{t('kpi.carrier')}</th>
                    <th className="hideField">
KPI
                      <sub>1</sub>
                    </th>
                    <th className="hideField">
KPI
                      <sub>2</sub>
                    </th>
                    <th className="hideField">
KPI
                      <sub>3</sub>
                    </th>
                    <th className="hideField">
KPI
                      <sub>4</sub>
                    </th>
                    <th className="hideField">
KPI
                      <sub>5</sub>
                    </th>
                    <th className="hideField">
KPI
                      <sub>6</sub>
                    </th>
                    <th>{t('kpi.average')}</th>
                    <th>{t('kpi.marketShare')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.get(kpiDetails, 'avg_row', undefined)
                    && (<KpiHistoryRow kpi={_.get(kpiDetails, 'avg_row')} style={{ fontWeight: 'bold' }} />)
                  }
                  { _.map(_.get(kpiDetails, 'rows.data'),
                    (kpi, index) => (<KpiHistoryRow key={`kpiRow-${index}`} kpi={kpi} />))}
                </tbody>
              </Table>
            </>
          )}
        </ActionCard>
      </>
    );
  }
}

KpiHistory.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

KpiHistory.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};


export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(KpiHistory);
