import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import LongText from '../long-text';

const CarrierName = ({
  name,
  inGroupByMode,
  t,
}) => {
  if (inGroupByMode) return null;
  if (name && name !== 'undefined') {
    return <LongText value={name} />;
  }
  return <span className="text-danger">{t('carriers.not.assigned')}</span>;
};

CarrierName.propTypes = {
  name: PropTypes.string,
  inGroupByMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CarrierName.defaultProps = {
  inGroupByMode: false,
  name: null,
};

export default compose(
  withTranslation(),
)(CarrierName);
