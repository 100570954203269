import React from 'react';
import PropTypes from 'prop-types';
import {
  Map as LeafletMap, TileLayer, Marker,
} from 'react-leaflet';
import L from 'leaflet';
import { compose } from 'recompose';
import _ from 'lodash';
import withEcho from '../../hocs/with-echo';

L.Icon.Default.imagePath = 'cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/';

class Map extends React.Component {
  constructor(props) {
    super(props);
    const { channel, echoClient, initialPosition } = this.props;
    this.state = {
      position: initialPosition,
    };
    if (channel) {
      echoClient.channel(channel)
        .listen('VehicleChangedPosition', this.onPositionChanged);
    }
  }

  componentWillUnmount() {
    const { echoClient } = this.props;
    echoClient.leave('VehicleChangedPosition');
  }

onPositionChanged = (event) => {
  const { data: { vehicle } } = event;
  const geo = _.get(vehicle, 'geo');
  if (geo) {
    this.setState({
      position: [geo.lat, geo.long],
    });
  }
}

render() {
  const { initialPosition } = this.props;
  const { position } = this.state;
  const markerIcon = L.icon({
    iconUrl: '/assets/marker.png',
    iconSize: [30, 45],
    iconAnchor: [15, 45],
  });

  return (
    <LeafletMap
      style={{ flex: 1, height: '50vh' }}
      center={initialPosition}
      zoom={14}
      maxZoom={19}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={markerIcon} />
    </LeafletMap>
  );
}
}

Map.propTypes = {
  echoClient: PropTypes.object.isRequired,
  initialPosition: PropTypes.array.isRequired,
  channel: PropTypes.string,
};

Map.defaultProps = {
  channel: undefined,
};

export default compose(
  withEcho,
)(Map);
