import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import { everything } from '../with-permission';

import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import KpiTableRow from '../../components/kpi-table-row';
import KPIInfopanel from '../../components/kpi-infopanel';
import FlashMessage from '../../components/flash-message';
import withAPI from '../../hocs/with-api';

class KPI extends PureComponent {
  constructor() {
    super();
    this.state = {
      kpiList: [],
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('kpi.all')} - ${t('app.name')}`;
    this.loadKPI();
  }

  loadKPI = async () => {
    const { httpRequest } = this.props;

    const response = await httpRequest({
      method: 'get',
      url: 'kpi',
    });
    if (response) {
      const {
        data: kpiList,
      } = response;
      this.setState({
        kpiList,
      });
    }
  }

  render() {
    const {
      t,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
      history,
    } = this.props;
    const {
      kpiList,
    } = this.state;


    return (
      <>
        <ActionCard
          title={t('kpi.all')}
          actionButtonLabel={t('edit')}
          onActionButtonClick={() => history.push('/kpi/edit')}
          actionButtonRequiredPermission={everything}
          actionButtonIcon="/assets/edit-icon-white.png"
        >
          {
            httpGetLoading && <Spinner marginTop="555px" />
          }
          {
            <FlashMessage />
          }
          {
            httpGetFailed && (
            <HttpError
              withTryAgainButton
              onTryAgain={this.loadKPI}
            />
            )
          }
          { !httpGetLoading && !httpGetFailed && httpGetDone && (
            <>
              <Table hover responsive="sm" size="sm" className="KPITable">
                <thead>
                  <tr>
                    <th colSpan="2" className="align-middle">{ t('kpi.name')}</th>
                    <th className="align-middle">{ t('kpi.value')}</th>
                    <th className="align-middle">{ t('kpi.minValue')}</th>
                  </tr>
                </thead>
                <tbody>
                  { _.map(kpiList, (kpi) => (
                    <KpiTableRow key={kpi.id} kpi={kpi} />
                  )) }
                </tbody>
              </Table>
            </>
          )}
        </ActionCard>
        <div className="col-md-12">
          <KPIInfopanel />
        </div>
      </>
    );
  }
}

KPI.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
  history: PropTypes.object.isRequired,

};

KPI.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(KPI);
