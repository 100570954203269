import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import DriverTableRow from '../../components/driver-table-row';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';

import WithPermission, { addDrivers, editDrivers } from '../with-permission';
import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import DropdownFilter from '../../components/dropdown-filter';
import Table from '../../components/table';
import FlashMessage from '../../components/flash-message';

class Drivers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drivers: null,
      currentPage: getCurrentPageNumber(props),
      carriers: [],
      filterCarrierId: null,
    };
  }

  async componentDidMount() {
    const { t, user } = this.props;
    document.title = `${t('drivers.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadDrivers();

    if (!user.isDispatcher) {
      this.loadCarriers();
    }
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  loadDrivers = async () => {
    const { httpRequest } = this.props;
    const { currentPage, shouldFilterByCarrier: withFilter, filterCarrierId: cid } = this.state;
    const url = !withFilter ? `drivers?page=${currentPage}` : `drivers?page=${currentPage}&carrier_id=${cid}`;

    const {
      data: {
        data: drivers,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url,
    });
    if (drivers) {
      this.setState({
        drivers,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/drivers/${currentPage}`);
      this.loadDrivers();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadDrivers);
  }

  onCarrierSelected = (carrierId) => {
    this.setState({
      shouldFilterByCarrier: carrierId !== null,
      filterCarrierId: carrierId,
      currentPage: 1,
    }, this.loadDrivers);
  }

  async loadCarriers() {
    const { httpRequest } = this.props;
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });
    if (carriers) {
      this.setState({
        carriers,
      });
    }
  }

  render() {
    const {
      t,
      history,
      httpGetLoading,
      httpGetDone,
      httpGetFailed,
      user,
    } = this.props;
    const {
      drivers,
      from,
      currentPage,
      totalPages,
      to,
      perPage,
      totalRecords,
      carriers,
    } = this.state;

    const filterOptions = _.map(carriers, (carrier) => ({
      id: carrier.id,
      label: carrier.name,
      value: carrier.id,
    }));

    const carrierFilter = (
      <DropdownFilter
        options={filterOptions}
        label={t('carriers.one')}
        onSelected={this.onCarrierSelected}
      />
    );

    return (
      <ActionCard
        title={t('drivers.all')}
        actionButtonLabel={t('drivers.createNew')}
        onActionButtonClick={() => history.push('/drivers/new')}
        actionButtonRequiredPermission={addDrivers}
        inFailedState={httpGetFailed}
        actionButtonIcon="/assets/add-icon.png"
        headerChildren={!user.isDispatcher ? [carrierFilter] : []}

      >
        { httpGetLoading && <Spinner marginTop="555px" /> }
        {
          <FlashMessage />
        }
        <>
          <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(drivers)} emptyMessage={t('messages.noData')}>
            <thead>
              <tr>
                <th className="align-middle">{ t('drivers.name') }</th>
                <th className="align-middle">{ t('drivers.phoneNr') }</th>
                {
                 !user.isDispatcher && (
                 <th className="align-middle hideMobile">
                   {t('carriers.one')}
                 </th>
                 )
                 }
                <WithPermission permission={editDrivers}>
                  <th className="align-middle actions">{t('actions')}</th>
                </WithPermission>
              </tr>
            </thead>
            {
                  httpGetDone && !httpGetFailed && drivers && (
                    <tbody>
                      { _.map(drivers, (driver) => (
                        <DriverTableRow {...driver} key={`driver-${driver.id}`} />
                      ))}
                    </tbody>
                  )
              }
          </Table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onChange={this.handlePageChange}
            totalRecords={totalRecords}
            perPage={perPage}
            from={from}
            to={to}
          />
        </>

      </ActionCard>
    );
  }
}

Drivers.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  httpGetDone: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Drivers.defaultProps = {
  httpGetDone: false,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
  withUserInfo,
)(Drivers);
