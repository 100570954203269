import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import HttpError from '../../components/http-error';
import withAPI from '../../hocs/with-api';
import '../../date-picker.css';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '../../components/table';
import IssueTableRow from '../../components/issue-table-row';
import FlashMessage from '../../components/flash-message';

class Issues extends PureComponent {
  constructor() {
    super();
    this.state = {
      issueList: [],
    };
  }

  async componentDidMount() {
    this.loadIssues();
  }

  loadIssues = async () => {
    const { httpRequest } = this.props;

    const response = await httpRequest({
      method: 'get',
      url: '/system/integrity/check',
    });

    if (response) {
      const {
        data: issues,
      } = response;
      this.parseIssues(issues);
    }
  }

  parseIssues = (response) => {
    const subjects = _.get(response, 'subjects', {});
    const issueList = _.reduce(subjects, (result, value, key) => {
      _.map(value.messages, (msg) => {
        if (value.status !== 'OK') {
          result.push({
            status: value.status,
            path: key,
            message: _.get(msg, 'message', msg),
            objectId: _.get(msg, 'object_id', undefined),
            label: value.label,
          });
        }
      });
      return result;
    }, []);

    this.setState({
      issueList,
    });
  }

  render() {
    const {
      t,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
    } = this.props;
    const {
      issueList,
    } = this.state;

    return (
      <>
        <ActionCard
          title={t('issues.title')}
          withActionButton={false}
        >
          {
            httpGetLoading && <Spinner marginTop="555px" />
          }
          {
            <FlashMessage />
          }
          {
            httpGetFailed && (
            <HttpError
              withTryAgainButton
              onTryAgain={this.loadKPI}
            />
            )
          }
          { !httpGetLoading && !httpGetFailed && httpGetDone && (
            <>
              <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(issueList)} emptyMessage={t('messages.noData')}>
                <thead>
                  <tr>
                    <th>{t('status')}</th>
                    <th>{t('messages')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    _.map(issueList, (issue, index) => <IssueTableRow key={index} t={t} issue={issue} />)
                 }
                </tbody>
              </Table>
            </>
          )}
        </ActionCard>
      </>
    );
  }
}

Issues.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Issues.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};


export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(Issues);
