import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import queryString from 'query-string';
import {
  Row,
  Col,
  Form,
} from 'reactstrap';
import styles from './styles.module.css';


import Button from '../../components/button';
import Input from '../../components/input';
import HttpErrorHandler from '../../components/http-error-handler';

import buildAPIClient from '../../services/api';

class ResetPasswordForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      error: null,
      isLoading: false,
    };
  }

  onInputChanged = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    const apiClient = buildAPIClient();
    const { email, password, passwordConfirmation } = this.state;
    const parsedQS = queryString.parse(window.location.search);
    this.setState({
      isLoading: true,
    });
    const response = await apiClient.post('reset/password', {
      token: parsedQS.token || '',
      email,
      password,
      password_confirmation: passwordConfirmation,
    }).catch((error) => {
      this.setState({
        error: error.response.data,
        isLoading: false,
      });
    });

    if (response) {
      const { data: { message, status: isOK } } = response;
      if (!isOK) {
        this.setState({
          error: { message },
          isLoading: false,
        });
      } else {
        history.push({
          pathname: '/login',
          flash: {
            type: 'success',
            message,
          },
        });
      }
    }
  }

  render() {
    const {
      email, password, passwordConfirmation, error, isLoading,
    } = this.state;
    const { t } = this.props;
    const shouldDisableButton = _.isEmpty(email) || _.isEmpty(password) || _.isEmpty(passwordConfirmation);
    return (
      <div className="flex-start position-ref full-height page-container">
        <div className="content-sm">
          <Row className="lf-logo-container">
            <Col md="12">
              <div className="lf-logo">
                <img src="/assets/lfg-logo.png" alt="Logo" />
              </div>
              <div className="lf-logo-text">
                { t('lf.logoBlock.name') }
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p className="lf-main-text">{t('password.modify')}</p>
            </Col>
          </Row>
          {
            error && (
              <Row>
                <Col md="12">
                  <HttpErrorHandler
                    {...error}
                  />
                </Col>
              </Row>
            )
          }
          <Form onSubmit={this.onSubmit}>
            <Row className={styles.resetRow}>
              <Col md="12">
                <Input
                  required
                  name="email"
                  id="email"
                  value={email}
                  placeholder={t('email')}
                  onChange={this.onInputChanged}
                  type="email"
                />
              </Col>
            </Row>
            <Row className={styles.resetRow}>
              <Col md="12">
                <Input
                  required
                  name="password"
                  id="password"
                  value={password}
                  placeholder={t('password.new')}
                  onChange={this.onInputChanged}
                  type="password"
                />
              </Col>
            </Row>
            <Row className={styles.resetRow}>
              <Col md="12">
                <Input
                  required
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  value={passwordConfirmation}
                  placeholder={t('password.confirm')}
                  onChange={this.onInputChanged}
                  type="password"
                />
              </Col>
            </Row>
            <Row className={`text-left ${styles.resetRowBtn}`}>
              <Col md="12">
                <Button
                  className="btn-main"
                  loading={isLoading}
                  disabled={shouldDisableButton}
                >
                  { t('reset') }
                </Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="12">
              <p className="copyright-text">
              Copyright ©
                {(new Date().getFullYear())}
            , Lafarge
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
)(ResetPasswordForm);
