import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import WithPermission, { editWarehouses } from '../../modules/with-permission';

import LongText from '../long-text';
import LinkButton, { editIconStyle } from '../link-button';

const warningIconStyle = {
  backgroundImage: "url('/assets/warning-icon.png')",
  backgroundSize: '20px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center',
  paddingLeft: '35px',
  margin: '6px',
};
const WarehouseTableRow = ({
  id,
  name,
  address,
  ivms_location_id: locationId,
  t,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>
      <td className="align-middle"><LongText value={name} /></td>
      <td className="align-middle hideMobile">
        <div style={locationId ? null : warningIconStyle}>
          <LongText value={address} />
        </div>
      </td>
      <WithPermission permission={editWarehouses}>
        <td className="align-middle">
          <LinkButton
            to={`/warehouses/${id}/edit`}
            iconStyle={editIconStyle}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
            title={t('edit')}
          />
        </td>
      </WithPermission>
    </tr>
  );
};

WarehouseTableRow.propTypes = {
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  ivms_location_id: PropTypes.string,
};

WarehouseTableRow.defaultProps = {
  ivms_location_id: null,
};

export default compose(
  withTranslation(),
)(WarehouseTableRow);
