import React from 'react';
import moment from 'moment';
import getAPIClient from '../../services/api';

function withUser(WrappedComponent) {
  return class extends React.Component {
    login = async ({
      email,
      password,
    }) => {
      const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET;
      const clientId = process.env.REACT_APP_PASSPORT_CLIENT_ID;
      const apiClient = getAPIClient();
      return apiClient.post('oauth/token', {
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
        username: email,
        password,
      }).then((response) => {
        const { data } = response;
        return data;
      });
    }

    getProfile = async () => {
      const tokens = JSON.parse(localStorage.getItem('lfg-tokens'));
      const apiClient = getAPIClient(tokens.access_token);
      return apiClient.get('users/my').then((response) => {
        const { data } = response;
        return data;
      });
    }

    logout = () => {
      localStorage.removeItem('lfg-profile');
      localStorage.removeItem('lfg-tokens');
      localStorage.removeItem('lfg-is-authenticated');
      localStorage.removeItem('lfg-context-carrier');
    }

    storeProfile = (profile) => localStorage.setItem('lfg-profile', JSON.stringify(profile));

    storeTokens = (tokens) => localStorage.setItem('lfg-tokens', JSON.stringify({
      created_at: moment.now(),
      ...tokens,
    }));

    setIsAuthenticated = (isAuthenticated) => localStorage.setItem('lfg-is-authenticated', isAuthenticated);

    render() {
      const rest = {
        logout: this.logout,
        login: this.login,
        storeTokens: this.storeTokens,
        getProfile: this.getProfile,
        storeProfile: this.storeProfile,
        setIsAuthenticated: this.setIsAuthenticated,
      };
      return <WrappedComponent {...this.props} {...rest} />;
    }
  };
}

export default withUser;
