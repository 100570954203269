import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withUserInfo from '../../hocs/with-user-info';
import withAPI from '../../hocs/with-api';

import VehicleForm from '../../components/vehicle-form';
import HttpErrorHandler from '../../components/http-error-handler';
import SuccessAlert from '../../components/success-alert';
import ButtonWithIcon from '../../components/button-with-icon';

class CreateVehicle extends PureComponent {
  constructor() {
    super();
    this.state = {
      carriers: [],
      vehicleCreated: false,
    };
  }

  async componentDidMount() {
    const { httpRequest, t, user } = this.props;
    document.title = `${t('vehicles.createNew')} - ${t('app.name')}`;

    const { data: drivers } = await httpRequest({
      method: 'get',
      url: 'drivers',
      tag: 'drivers',
    });

    if (drivers) {
      this.setState({
        drivers,
      });
    }

    if (user.isDispatcher) return;

    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });

    this.setState({
      carriers,
    });
  }

  onSubmit = async (values) => {
    const {
      tractorNr,
      trailerNr,
      truckSapId,
      truckExternalId,
      carrier,
      transportType,
      driver,
    } = values;
    const { httpRequest } = this.props;
    const data = {
      number_plate: tractorNr,
      number_plate_trailer: trailerNr,
      truck_sap_id: truckSapId,
      external_id: truckExternalId,
      carrier_id: carrier,
      type: transportType,
      driver_id: driver === '' ? undefined : driver,
    };

    this.setState({
      vehicleCreated: false,
    });
    const posted = await httpRequest({
      method: 'post',
      url: 'vehicles',
      data,
    });
    if (posted) {
      this.setState({
        vehicleCreated: true,
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t, httpPostFailed, httpPostFailReason, httpPostLoading,
    } = this.props;
    const { carriers, drivers, vehicleCreated } = this.state;
    return (
      <>
        <Col md="12">
          {
             vehicleCreated && <SuccessAlert message={t('vehicles.created')} />
          }
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('vehicles.createNew') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {
            httpPostFailed && httpPostFailReason && <HttpErrorHandler {...httpPostFailReason} />
            }
          <VehicleForm
            vehicleCreated={vehicleCreated}
            drivers={drivers}
            carriers={carriers}
            onSubmit={this.onSubmit}
            isConfirmButtonLoading={httpPostLoading}
          />
        </Col>
      </>
    );
  }
}

CreateVehicle.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostLoading: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
};

CreateVehicle.defaultProps = {
  httpPostFailed: false,
  httpPostFailReason: undefined,
  httpPostLoading: false,
};
export default compose(
  withTranslation(),
  withAPI,
  withRouter,
  withUserInfo,
)(CreateVehicle);
