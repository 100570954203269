import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import Dropzone from 'react-dropzone';

import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import withEcho from '../../hocs/with-echo';
import ButtonWithIcon from '../../components/button-with-icon';

const MAX_FILE_SIZE_IN_BYTES = 4000000;

const ACCEPTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

class OrderImporter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImporting: false,
    };
    props.echoClient
      .channel(`orders.index.${props.user.id}`)
      .listen('ImportFinished', this.onImportFinished);
  }

  componentWillUnmount() {
    const { echoClient } = this.props;
    echoClient.leave('ImportFinished');
  }

  onImportFinished = (event) => {
    const {
      t,
      onImport,
    } = this.props;
    const {
      data: {
        success: importedOk,
        message,
        ...rest
      },
    } = event;
    const importFeedback = importedOk ? t('import.good', rest) : message;

    onImport(importedOk, importFeedback);
    this.setState({
      isImporting: false,
      isUploading: false,
    });
  }

  onDrop = async (files) => {
    if (_.isEmpty(files)) return;
    const formData = new FormData();
    formData.append('file', files.pop());
    const { httpRequest, onImport } = this.props;
    this.setState({
      isUploading: true,
    });
    const uploaded = await httpRequest({
      method: 'post',
      url: 'orders/import',
      data: formData,
    }).catch(() => {
      this.setState({
        isUploading: false,
      });
      const {
        httpPostFailReason,
      } = this.props;
      onImport(false, httpPostFailReason);
    });
    if (uploaded) {
      this.setState({
        isImporting: true,
      });
    }
  }

  render() {
    const {
      isImporting, isUploading,
    } = this.state;
    const {
      t, buttonImportStyles,
    } = this.props;

    const importDisabled = isImporting || isUploading;
    return (
      <Dropzone
        disabled={importDisabled}
        multiple={false}
        accept={ACCEPTED_FILE_TYPES}
        maxSize={MAX_FILE_SIZE_IN_BYTES}
        onDropRejected={this.onDropFailed}
        onDrop={this.onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <ButtonWithIcon
              disabled={importDisabled}
              loading={importDisabled}
              title={t('orders.import')}
              width="200px"
              height="40px"
              icon="/assets/import-icon.png"
              restButtonStyles={buttonImportStyles}
            />
          </div>
        )}
      </Dropzone>
    );
  }
}

OrderImporter.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  echoClient: PropTypes.object.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
  user: PropTypes.object.isRequired,
  httpPostLoading: PropTypes.bool,
  onImport: PropTypes.func,
  buttonImportStyles: PropTypes.object,
};

OrderImporter.defaultProps = {
  httpPostFailed: false,
  httpPostLoading: false,
  onImport: () => {},
  httpPostFailReason: {},
  buttonImportStyles: {},
};

export default compose(
  withTranslation(),
  withAPI,
  withUserInfo,
  withEcho,
)(OrderImporter);
