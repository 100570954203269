import React from 'react';

import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'recompose';
import _ from 'lodash';
import {
  PieChart as OriginalPieChart, Pie, Cell, Tooltip, Legend,
} from 'recharts';
import styles from './styles.module.css';

const COLOURS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const PieChart = (props) => {
  const { t, dataLoaded, data } = props;

  if (!dataLoaded) {
    return <div className={styles.noData}><FontAwesomeIcon icon={faSpinner} spin /></div>;
  }

  return data.length === 0 ? (
    <div className={styles.noData}>{t('homepage.charts.noData')}</div>
  ) : (
    <OriginalPieChart width={450} height={300} className={styles.piechart}>
      <Pie
        data={data}
        innerRadius={70}
        outerRadius={120}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        {
          _.map(data, (entry, index) => <Cell key={index} fill={COLOURS[index % COLOURS.length]} />)
        }
      </Pie>
      <Tooltip />
      <Legend layout="vertical" verticalAlign="middle" align="right" />
    </OriginalPieChart>
  );
};

PieChart.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataLoaded: PropTypes.bool,
};

PieChart.defaultProps = {
  data: [],
  dataLoaded: true,
};

export default compose(
  withTranslation(),
)(PieChart);
