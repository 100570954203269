import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import moment from 'moment';

import {
  Card,
  CardHeader,
  CardBody,
  Table,
} from 'reactstrap';


const STATUS_FAIL = 3;

const ImportStatusTable = ({
  t,
  id,
  status,
  status_label,
  created_at,
  updated_at,
  data,
  count,
}) => (
  <Card>
    <CardHeader>{ t('import.status') }</CardHeader>
    <CardBody>
      <Table>
        <tbody>
          <tr>
            <td>
              { t('id') }
            </td>
            <td>
              { id }
            </td>
          </tr>
          <tr>
            <td>
              { t('status')}
            </td>
            <td>
              { status_label }
            </td>
          </tr>
          {
            status === STATUS_FAIL && (
              <tr>
                <td>{ t('import.failCause') }</td>
                <td>
                  <code style={{ maxWidth: 800, display: 'block' }}>
                    { data }
                  </code>
                </td>
              </tr>
            )
          }
          <tr>
            <td>
              { t('import.createdAt')}
            </td>
            <td>
              { moment(created_at).format('DD-MM-YYYY H:m:ss') }
            </td>
          </tr>
          <tr>
            <td>
              { t('import.updatedAt')}
            </td>
            <td>
              { moment(updated_at).format('DD-MM-YYYY H:m:ss') }
            </td>
          </tr>

          <tr>
            <td>
              { t('import.in')}
            </td>
            <td>
              { count.tried }
            </td>
          </tr>

          <tr>
            <td>
              { t('import.imported')}
            </td>
            <td>
              { count.imported }
            </td>
          </tr>

          <tr>
            <td>
              { t('import.skipped')}
            </td>
            <td>
              { count.skipped }
            </td>
          </tr>

          <tr>
            <td colSpan={2}>
              <small>
                { t('import.duplicates.are.skipped') }
              </small>
            </td>
          </tr>
        </tbody>
      </Table>
    </CardBody>
  </Card>
);

ImportStatusTable.propTypes = {
  status: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  status_label: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  data: PropTypes.string,
  count: PropTypes.object,
};

ImportStatusTable.defaultProps = {
  data: '',
  count: {
    tried: '-',
    imported: '-',
    skipped: '-',
  },
};

export default compose(
  withTranslation(),
)(ImportStatusTable);
