import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import {
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';

import DatePicker from 'react-datepicker';
import ButtonWithIcon from '../button-with-icon';
import ButtonWithConfirmation from '../button-with-confirmation';
import Checkbox from '../checkbox';
import TextArea from '../textarea';
import 'react-datepicker/dist/react-datepicker.css';
import '../../date-picker.css';
import DropdownInput from '../dropdown-input';

const OrderForm = ({
  order,
  statuses,
  onSubmit,
  onDelete,
  t,
  isConfirmButtonLoading,
  isDeleteButtonLoading,
}) => {
  const [deliveryDateFrom, setDateFrom] = useState(_.get(order, 'delivery_from', ''));
  const [deliveryDateTo, setDateTo] = useState(_.get(order, 'delivery_to', ''));
  const [claimActive, setClaimActive] = useState(_.get(order, 'claimed', false));
  const [claimText, setClaimText] = useState(_.get(order, 'claim_details', '') || '');
  const [status, setStatus] = useState(_.get(order, 'status', undefined));
  const [realLoadingTime, setRealLoadingTime] = useState(_.get(order, 'real_loading_time', null));
  const [realDeliveryTime, setRealDeliveryTime] = useState(_.get(order, 'real_delivery_time', null));

  const handleSetDateFrom = (date) => setDateFrom(date);
  const handleSetDateTo = (date) => setDateTo(date);
  const handleSetLoadingTime = (date) => setRealLoadingTime(date);
  const handleSetDeliveryTime = (date) => setRealDeliveryTime(date);
  const handleClaimCheckbox = (event) => setClaimActive(_.get(event, 'target.checked', false));
  const handleSetClaimText = (event) => setClaimText(_.get(event, 'target.value', ''));
  const handleSetStatus = (selectedStatus) => {
    setStatus(selectedStatus);
  };

  const toDate = (date) => (moment(date).isValid() ? moment(date).toDate() : null);

  const mapStatuses = (statusList) => _.map(statusList, (statusObject) => ({
    label: statusObject.name,
    value: statusObject,
    id: statusObject.id,
  }));

  const defaultSelected = (status ? {
    label: status.name,
    value: status,
    id: status.id,
  } : undefined);

  const deleteButtonProps = {
    disabled: isConfirmButtonLoading || isDeleteButtonLoading,
  };

  const isSubmitButtonValid = toDate(deliveryDateFrom) && toDate(deliveryDateTo);

  const values = {
    deliveryDateFrom: moment(deliveryDateFrom).format('YYYY-MM-DD HH:mm:ss'),
    deliveryDateTo: moment(deliveryDateTo).format('YYYY-MM-DD HH:mm:ss'),
    realLoadingTime: realLoadingTime ? moment(realLoadingTime).format('YYYY-MM-DD HH:mm:ss') : null,
    realDeliveryTime: realDeliveryTime ? moment(realDeliveryTime).format('YYYY-MM-DD HH:mm:ss') : null,
    claimActive,
    claimText,
    status,
  };

  const canBeDeleted = () => order.status.name !== 'Deleted';
  const isDeleted = order.status.name === 'Deleted';

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <DropdownInput
            label={t('orders.status')}
            placeholder={t('orders.selectStatus')}
            onSelected={handleSetStatus}
            options={mapStatuses(statuses)}
            defaultSelected={defaultSelected}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="dateFrom">{ t('orders.deliveryDateFrom') }</Label>
          <FormGroup>
            <DatePicker
              disabled={isDeleted}
              required
              showTimeSelect
              timeIntervals={1}
              onChange={handleSetDateFrom}
              selected={toDate(deliveryDateFrom)}
              className="form-control input-clean"
              dateFormat="yyyy-MM-dd h:mm:ss"
            />
          </FormGroup>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="dateFrom">{ t('orders.deliveryDateTo') }</Label>
          <FormGroup>
            <DatePicker
              disabled={isDeleted}
              required
              showTimeSelect
              timeIntervals={1}
              onChange={handleSetDateTo}
              selected={toDate(deliveryDateTo)}
              className="form-control input-clean"
              dateFormat="yyyy-MM-dd h:mm:ss"
            />
          </FormGroup>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="realLoadingTime">{ t('delivery.realLoadingTime') }</Label>
          <FormGroup>
            <DatePicker
              disabled={isDeleted}
              showTimeSelect
              timeIntervals={1}
              onChange={handleSetLoadingTime}
              selected={toDate(realLoadingTime)}
              className="form-control input-clean"
              dateFormat="yyyy-MM-dd h:mm:ss"
              name="realLoadingTime"
            />
          </FormGroup>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="realDeliveryTime">{ t('delivery.realDeliveryTime') }</Label>
          <FormGroup>
            <DatePicker
              disabled={isDeleted}
              showTimeSelect
              timeIntervals={1}
              onChange={handleSetDeliveryTime}
              selected={toDate(realDeliveryTime)}
              className="form-control input-clean"
              dateFormat="yyyy-MM-dd h:mm:ss"
              name="realDeliveryTime"
            />
          </FormGroup>
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Checkbox
            checkboxLabel={t('orders.claim')}
            checked={claimActive}
            onChange={handleClaimCheckbox}
          />
          {
            claimActive ? (
              <TextArea
                className="form-control input-clean"
                id="noter-text-area"
                name="textArea"
                rows="3"
                maxLength={10000}
                value={claimText}
                onChange={handleSetClaimText}
              />
            ) : undefined
        }
        </FormGroup>
      </Col>
      <Col md="12" className="btn-form-group">
        <FormGroup>
          <ButtonWithIcon
            title={t('save')}
            icon="/assets/images/save-icon@2x.png"
            alignment="left-aligned"
            disabled={!isSubmitButtonValid || isDeleteButtonLoading || isConfirmButtonLoading || isDeleted}
            loading={isConfirmButtonLoading}
          />
          {
            canBeDeleted() && (
              <ButtonWithConfirmation
                buttonProps={deleteButtonProps}
                buttonLabel={t('orders.delete')}
                headerText={t('orders.delete')}
                confirmText={t('orders.confirmDelete')}
                onConfirm={() => onDelete(order.id)}
                alignment="left-aligned"
                icon="/assets/images/delete-icon@2x.png"
              />
            )
        }
        </FormGroup>
      </Col>
    </Form>
  );
};

OrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  order: PropTypes.object,
  isDeleteButtonLoading: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
  statuses: PropTypes.array,
};

OrderForm.defaultProps = {
  onDelete: () => {},
  order: {},
  isDeleteButtonLoading: false,
  isConfirmButtonLoading: false,
  statuses: [],
};


export default OrderForm;
