import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import {
  BarChart as OriginalBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';

const BarChart = ({
  data, dataLoaded, isDispatcher, t,
}) => (!dataLoaded ? (
  <ResponsiveContainer
    height={400}
    className={styles.barchart}
  >
    <div className={styles.noData}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  </ResponsiveContainer>
) : (
  <ResponsiveContainer
    height={400}
    className={styles.barchart}
  >
    { dataLoaded && data.length === 0
      ? <div className={styles.noData}>{t('homepage.charts.noData')}</div>
      : (
        <OriginalBarChart
          data={data}
          margin={{
            top: 20, right: 30, left: 30, bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />

          {isDispatcher
            && (
            <Bar
              name={t('homepage.barchart.carrier.kpi')}
              dataKey="kpi"
              stackId="kpiStatistics"
              fill="#54A8E2"
            />
            )}
          {isDispatcher
            && (
            <Bar
              name={t('homepage.barchart.carrier.extra')}
              dataKey="extra"
              stackId="kpiStatistics"
              fill="#65D9FB"
            />
            )}
          {!isDispatcher
            && (
            <Bar
              name={t('homepage.barchart.manager.orders')}
              dataKey="value"
              stackId="kpiStatistics"
              fill="#54A8E2"
            />
            )}

        </OriginalBarChart>
      )}
  </ResponsiveContainer>
));

BarChart.propTypes = {
  data: PropTypes.array,
  isDispatcher: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

BarChart.defaultProps = {
  data: [],
  dataLoaded: true,
};
export default compose(withTranslation())(BarChart);
