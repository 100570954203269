import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import {
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';

import Input from '../input';
import ButtonWithConfirmation from '../button-with-confirmation';
import WithPermission, { deleteDrivers } from '../../modules/with-permission';
import ButtonWithIcon from '../button-with-icon';
import DropdownInput from '../dropdown-input';

import withUserInfo from '../../hocs/with-user-info';

const DriverForm = (props) => {
  const {
    t,
    carriers,
    driver,
    inEditMode,
    onSubmit,
    onDelete,
    isConfirmButtonLoading,
    isDeleteButtonLoading,
    driverCreated,
    user,
  } = props;

  const { isDispatcher } = user;
  const getCarrier = () => {
    if (isDispatcher) {
      const carrier = _.get(user, 'carriers[0]');
      return carrier;
    }
    return _.get(driver, 'carrier', '');
  };
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [carrier, setCarrier] = useState(getCarrier());

  const handleSetName = (event) => setName(_.get(event, 'target.value', ''));
  const handleSetPhone = (event) => setPhone(_.get(event, 'target.value', ''));
  const handleSetCarrier = (carrierObj) => setCarrier(carrierObj);

  useEffect(() => {
    setName(inEditMode ? driver.name : '');
    setPhone(inEditMode ? driver.phone : '');
  }, [driverCreated, inEditMode, driver]);

  const values = {
    name,
    phone,
    carrier,
  };

  const deleteButtonProps = {
    disabled: isConfirmButtonLoading || isDeleteButtonLoading,
    loading: isDeleteButtonLoading,
  };

  const carrierOptions = _.map(carriers, (record) => ({
    id: record.id,
    label: record.name,
    value: record,
  }));

  const defaultSelectedCarrier = (carrier ? {
    label: carrier.name,
    value: carrier,
    id: carrier.id,
  } : undefined);

  const isSubmitButtonValid = !_.isEmpty(name)
    && !_.isEmpty(phone)
    && !_.isEmpty(_.toString(carrier));

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="name">{ t('drivers.name') }</Label>
          <Input
            value={name}
            type="text"
            name="name"
            id="name"
            className="input-clean"
            placeholder={t('drivers.name')}
            onChange={handleSetName}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="phone">{ t('drivers.phoneNr') }</Label>
          <Input
            value={phone}
            type="text"
            name="phone"
            id="phone"
            className="input-clean"
            placeholder={t('drivers.phoneNr')}
            onChange={handleSetPhone}
          />
        </FormGroup>
      </Col>
      {
        !isDispatcher && (
          <Col md="12">
            <FormGroup>
              <DropdownInput
                required
                options={carrierOptions}
                label={t('carriers.one')}
                placeholder={t('carriers.one')}
                onSelected={handleSetCarrier}
                defaultSelected={defaultSelectedCarrier}
              />
            </FormGroup>
          </Col>
        )
      }
      <Col md="12" className="btn-form-group">
        <ButtonWithIcon
          title={inEditMode ? t('save') : t('add')}
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isSubmitButtonValid || isDeleteButtonLoading || isConfirmButtonLoading}
          loading={isConfirmButtonLoading}
        />
        {
          inEditMode && (
            <WithPermission permission={deleteDrivers}>
              <ButtonWithConfirmation
                buttonProps={deleteButtonProps}
                buttonLabel={t('drivers.delete')}
                headerText={t('drivers.delete')}
                confirmText={t('drivers.confirmDelete')}
                onConfirm={() => onDelete(driver.id)}
                alignment="left-aligned"
                icon="/assets/images/delete-icon@2x.png"
              />
            </WithPermission>
          )
          }
      </Col>
    </Form>
  );
};

DriverForm.propTypes = {
  inEditMode: PropTypes.bool,
  driverCreated: PropTypes.bool,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  driver: PropTypes.object,
  carriers: PropTypes.array.isRequired,
  isDeleteButtonLoading: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
};

DriverForm.defaultProps = {
  inEditMode: false,
  onDelete: () => {},
  driver: {},
  isDeleteButtonLoading: false,
  isConfirmButtonLoading: false,
  driverCreated: false,
};


export default compose(
  withTranslation(),
  withUserInfo,
)(DriverForm);
