import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Col,
  Row,
} from 'reactstrap';
import moment from 'moment';
import _ from 'lodash';
import BarChart from '../bar-chart';
import withAPI from '../../hocs/with-api';
import PieChart from '../pie-chart';
import withUserInfo from '../../hocs/with-user-info';
import styles from './styles.module.css';
import RadarChart from '../radar-chart';
import FlashMessage from '../flash-message';

const carrierStatisticsUrl = 'statistics/carrier/orders/';
const managerStatisticsUrl = 'statistics/total/';

class HomepageCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierKpiPickedOrders: undefined,
      carrierDeliveredOrders: undefined,
      carrierKpiAllocatedOrders: undefined,
      managerTotalOrders: undefined,
      managerOrdersByType: undefined,
      managerAvgKpi: undefined,
      topChartDataLoaded: false,
      leftChartDataLoaded: false,
      rightChartDataLoaded: false,
    };
  }

  async componentDidMount() {
    const { user } = this.props;

    if (user.isDispatcher) {
      this.loadKpiPickedOrders();
      this.loadDeliveredOrders();
      this.loadAllocatedOrders();
    } else {
      this.loadTotalOrders();
      this.loadOrdersByType();
      this.loadAvgKpi();
    }
  }

  loadKpiPickedOrders = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByMonths(12);
    const response = await httpRequest({
      method: 'get',
      url: `${carrierStatisticsUrl}kpi/extra/monthly?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: carrierKpiPickedOrders } = response;
      this.setState({
        carrierKpiPickedOrders,
        topChartDataLoaded: true,
      });
    }
  }

  loadDeliveredOrders = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByMonths(1);
    const response = await httpRequest({
      method: 'get',
      url: `${carrierStatisticsUrl}on-time?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: carrierDeliveredOrders } = response;
      this.setState({
        carrierDeliveredOrders,
        leftChartDataLoaded: true,
      });
    }
  }

  loadAllocatedOrders = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByMonths(1);
    const response = await httpRequest({
      method: 'get',
      url: `${carrierStatisticsUrl}kpi/rapport?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: carrierKpiAllocatedOrders } = response;
      this.setState({
        carrierKpiAllocatedOrders,
        rightChartDataLoaded: true,
      });
    }
  }

  loadTotalOrders = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByMonths(12);
    const response = await httpRequest({
      method: 'get',
      url: `${managerStatisticsUrl}orders/monthly?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: managerTotalOrders } = response;
      this.setState({
        managerTotalOrders,
        topChartDataLoaded: true,
      });
    }
  }

  loadOrdersByType = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByMonths(2);
    const response = await httpRequest({
      method: 'get',
      url: `${managerStatisticsUrl}orders/uom/type?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: managerOrdersByType } = response;
      this.setState({
        managerOrdersByType,
        leftChartDataLoaded: true,
      });
    }
  }

  loadAvgKpi = async () => {
    const {
      httpRequest,
    } = this.props;

    const datePeriod = this.getStatisticPeriodByPreviousMonths(2);
    const response = await httpRequest({
      method: 'get',
      url: `${managerStatisticsUrl}avg/kpi?start=${datePeriod.start}&end=${datePeriod.end}`,
    });
    if (response) {
      const { data: managerAvgKpi } = response;
      this.setState({
        managerAvgKpi,
        rightChartDataLoaded: true,
      });
    }
  }


  getStatisticPeriodByMonths = (monthsPeriod) => ({
    start: moment()
      .startOf('month')
      .subtract(monthsPeriod, 'months')
      .format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  })

  getStatisticPeriodByPreviousMonths = (monthsPeriod) => ({
    start: moment()
      .startOf('month')
      .subtract(monthsPeriod, 'months')
      .format('YYYY-MM-DD'),
    end: moment()
      .endOf('month')
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
  })

  parseKpiPickedOrders = (data) => {
    const items = _.map(_.get(data, 'statistic', {}), (obj, key) => ({
      name: moment(key, 'YYYY-MM').format('MMM \'YY'),
      kpi: _.get(obj, 'kpi', 0),
      extra: _.get(obj, 'extra', 0),
    }));

    const emptyElements = _.map(items, (item) => (
      item.kpi === 0 && item.extra === 0))
      .filter((item) => (!item)).length === 0;

    return emptyElements ? [] : items;
  }

  parseDeliveredOrders = (data) => {
    const statistic = _.get(data, 'statistic', {});
    const { t } = this.props;
    const deliveredOnTime = _.get(statistic, 'delivered_on_time', 0);
    const notDeliveredOnTime = _.get(statistic, 'not_delivered_on_time', 0);

    if (deliveredOnTime === 0 && notDeliveredOnTime === 0) {
      return [];
    }

    return [
      {
        name: t('homepage.piechart.carrier.deliveredOnTime'),
        value: deliveredOnTime,
        color: '#0088FE',
      },
      {
        name: t('homepage.piechart.carrier.deliveredNotOnTime'),
        value: notDeliveredOnTime,
        color: '#FFBB28',
      },
    ];
  }

  parseKpiAllocatedOrders = (data) => {
    const statistic = _.get(data, 'statistic', {});
    const { t } = this.props;
    const accepted = _.get(statistic, 'accepted', 0);
    const refused = _.get(statistic, 'refused', 0);

    if (accepted === 0 && refused === 0) {
      return [];
    }

    return [
      {
        name: t('homepage.piechart.carrier.kpi.accepted'),
        value: accepted,
        color: '#0088FE',
      },
      {
        name: t('homepage.piechart.carrier.kpi.refused'),
        value: refused,
        color: '#FFBB28',
      },
    ];
  }

  parseTotalOrders = (data) => {
    const items = _.map(_.get(data, 'statistic', {}), (obj, key) => ({
      name: moment(key, 'YYYY-MM').format('MMM \'YY'),
      value: obj,
    }));

    const emptyElements = _.map(items, (item) => (
      item.value === 0))
      .filter((item) => (!item)).length === 0;

    return emptyElements ? [] : items;
  }

  parseOrdersByType = (data) => {
    const statistic = _.get(data, 'statistic', {});
    const { t } = this.props;
    const freight = _.get(statistic, 'FREIGHT', 0);
    const bulk = _.get(statistic, 'BULK', 0);

    if (freight === 0 && bulk === 0) {
      return [];
    }

    return [
      {
        name: t('homepage.piechart.manager.orders.type.freight'),
        value: freight,
        color: '#0088FE',
      },
      {
        name: t('homepage.piechart.manager.orders.type.bulk'),
        value: bulk,
        color: '#FFBB28',
      },
    ];
  }

  parseAvgKpi = (arg) => {
    const statistic = _.get(arg, 'statistic', {});

    const emptyElements = _.map(statistic, (item) => (
      item.KPI1 === 0 && item.KPI2 === 0 && item.KPI3 === 0 && item.KPI4 === 0 && item.KPI5 === 0))
      .filter((item) => (!item)).length === 0;

    if (emptyElements) {
      return [];
    }

    const keys = ['KPI1', 'KPI2', 'KPI3', 'KPI4', 'KPI5', 'KPI6'];
    return _.map(keys, (key) => {
      const data = _.reduce(statistic, (result, value, k) => {
        // eslint-disable-next-line no-param-reassign
        result[k] = value[key];
        return result;
      }, {});
      return {
        subject: key,
        ...data,
      };
    });
  }

  parseAvgKpiLabels = (arg) => _.map(_.get(arg, 'statistic', {}), (row, date) => ({
    key: date,
    label: moment(date, 'YYYY-MM').format('MMMM YYYY'),
  }))


  render() {
    const { t, user } = this.props;
    const {
      carrierKpiPickedOrders,
      carrierDeliveredOrders,
      carrierKpiAllocatedOrders,
      managerTotalOrders,
      managerOrdersByType,
      managerAvgKpi,
      topChartDataLoaded,
      leftChartDataLoaded,
      rightChartDataLoaded,
    } = this.state;

    return (
      <Col md="12">
        {
          <FlashMessage />
        }
        {
        user.isDispatcher ? (
          <div className="graphWrapper">
            <Row>
              <Col md="12">
                <h3 className={styles.heading}>Dashboard</h3>
                <p className={styles.title}>{t('homepage.barchart.carrier.title')}</p>
                <BarChart
                  data={this.parseKpiPickedOrders(carrierKpiPickedOrders)}
                  dataLoaded={topChartDataLoaded}
                  isDispatcher={user.isDispatcher}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <p className={`${styles.title} ${styles.title_margin_top}`}>
                  {t('homepage.piechart.carrier.onTime.title')}
                </p>
                <PieChart data={this.parseDeliveredOrders(carrierDeliveredOrders)} dataLoaded={leftChartDataLoaded} />
              </Col>
              <Col md="6">
                <p className={`${styles.title} ${styles.title_margin_top}`}>
                  {t('homepage.piechart.carrier.kpi.title')}
                </p>
                <PieChart
                  data={this.parseKpiAllocatedOrders(carrierKpiAllocatedOrders)}
                  dataLoaded={rightChartDataLoaded}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div className="graphWrapper">
            <Row>
              <Col md="12">
                <h3 className={styles.heading}>Dashboard</h3>
                <p className={styles.title}>{t('homepage.barchart.manager.title')}</p>
                <BarChart
                  data={this.parseTotalOrders(managerTotalOrders)}
                  dataLoaded={topChartDataLoaded}
                  isDispatcher={user.isDispatcher}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <p className={`${styles.title} ${styles.title_margin_top}`}>
                  {t('homepage.piechart.manager.orders.type.title')}
                </p>
                <PieChart data={this.parseOrdersByType(managerOrdersByType)} dataLoaded={leftChartDataLoaded} />
              </Col>
              <Col md="6">
                <p className={`${styles.title} ${styles.title_margin_top}`}>
                  {t('homepage.piechart.manager.avgKpi.title')}
                </p>
                <RadarChart
                  data={this.parseAvgKpi(managerAvgKpi)}
                  labels={this.parseAvgKpiLabels(managerAvgKpi)}
                  dataLoaded={rightChartDataLoaded}
                />
              </Col>
            </Row>
          </div>
        )
        }
      </Col>
    );
  }
}

HomepageCard.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withAPI,
  withUserInfo,
)(HomepageCard);
