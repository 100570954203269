import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
} from 'reactstrap';


const TextArea = ({ textAreaLabel, ...rest }) => (
  <div>
    {
      textAreaLabel && <Label for="textArea">{ textAreaLabel }</Label>
    }
    <textarea
      {...rest}
    />
  </div>
);

TextArea.propTypes = {
  textAreaLabel: PropTypes.string,
};
TextArea.defaultProps = {
  textAreaLabel: undefined,
};
export default TextArea;
