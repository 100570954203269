import React from 'react';
import {
  Jumbotron,
} from 'reactstrap';
import styles from './styles.module.css';

const KPIInfopanel = () => (
  <Jumbotron className={styles.jumbotron} fluid>
    <div className={styles.infoPanelContainer}>
      <div className={styles.infoIconContainer}>
        <img src="/assets/info-icon.png" alt="" />
      </div>
      <div className={styles.mainKPIFormula}>
                KPI
        <sub>transportator</sub>
        {' '}
= (KPI
        <sub>1</sub>
        {' '}
× R
        <sub>o</sub>
) + (KPI
        <sub>2</sub>
        {' '}
× S
        <sub>î</sub>
) + (KPI
        <sub>3</sub>
        {' '}
× S
        <sub>d</sub>
) + (KPI
        <sub>4</sub>
        {' '}
× R
        <sub>c</sub>
) + (KPI
        <sub>5</sub>
        {' '}
× R
        <sub>r</sub>
) + (KPI
        <sub>6</sub>
        {' '}
× P
        <sub>i</sub>
)
      </div>
      <div className="hideMobile">
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>1</sub>
            {' '}
            =
          </div>
          <div className={styles.formulaFraction}>
            <span>Numărul de comenzi recomandate preluate</span>
            <span className={styles.symbol}>/</span>
            <span className={styles.bottom}>Total comenzi recomandate</span>
          </div>
        </div>
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>2</sub>
            {' '}
            =
          </div>
          <div className={styles.formulaFraction}>
            <span>Numărul de comenzi încărcate la timp ±30 minute</span>
            <span className={styles.symbol}>/</span>
            <span className={styles.bottom}>Total comenzi încărcate</span>
          </div>
        </div>
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>3</sub>
            {' '}
            =
          </div>
          <div className={styles.formulaFraction}>
            <span>Numărul de comenzi livrate la timp ±30 minute</span>
            <span className={styles.symbol}>/</span>
            <span className={styles.bottom}>Total comenzi livrate</span>
          </div>
        </div>
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>4</sub>
            {' '}
            =
          </div>
          <div className={styles.formulaFraction}>
            <span>Numărul de comenzi care au reclamații</span>
            <span className={styles.symbol}>/</span>
            <span className={styles.bottom}>Total comenzi livrate</span>
          </div>
        </div>
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>5</sub>
            {' '}
            —
            {' '}
            <span>Respectarea regulilor de conducere în siguranță</span>
          </div>
        </div>
        <div className={styles.KPIFormulaContainer}>
          <div className={styles.secondaryKPIFormula}>
            KPI
            <sub>6</sub>
            {' '}
            —
            {' '}
            <span>Cost transportare</span>
          </div>
        </div>
      </div>
    </div>
  </Jumbotron>
);

export default KPIInfopanel;
