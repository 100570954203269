import React from 'react';
import PropTypes from 'prop-types';
import BSPagination from 'react-ultimate-pagination-bootstrap-4';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const API_PER_PAGE = 20;

export function getCurrentPageNumber({
  match,
}) {
  const { params: { page } } = match;
  return !page ? 1 : _.toNumber(page);
}

const Pagination = ({
  totalPages,
  totalRecords,
  perPage,
  t,
  from,
  to,
  currentPage,
  ...rest
}) => {
  const shouldRender = totalRecords > API_PER_PAGE;
  const canRender = (totalPages >= currentPage);
  if (!shouldRender || !canRender) return null;
  return (
    <>
      <BSPagination
        {...rest}
        currentPage={currentPage}
        totalPages={totalPages}
        hidePreviousAndNextPageLinks
        hideFirstAndLastPageLinks
      />
      <hr />
      <span>{`${from} - ${to} ${t('from')} ${totalRecords}`}</span>
    </>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
  from: PropTypes.number,
  to: PropTypes.number,
  perPage: PropTypes.number,
  currentPage: PropTypes.number,
  t: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  totalPages: 1,
  totalRecords: 1,
  from: 1,
  to: 1,
  perPage: 1,
  currentPage: 1,
};

export default compose(
  withTranslation(),
)(Pagination);
