/* eslint-disable react/jsx-filename-extension */
import React, {
  Suspense,
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './i18n';

import Login from './modules/login';
import Logout from './modules/logout';
import Dashboard from './modules/dashboard';
import SearchOrders from './modules/search-orders';
import ScrollToTop from './modules/scroll-to-top';
import ResetPassword from './modules/reset-password';
import ResetPasswordForm from './modules/reset-password-form';
import WebViewOrderSearch from './modules/webview-search-order';

const App = () => (
  <Suspense fallback={null}>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            component={SearchOrders}
            path="/search/order"
          />
          <Route
            component={WebViewOrderSearch}
            path="/webview/search/order"
          />
          <Route
            path="/password/reset/change"
            component={ResetPasswordForm}
          />
          <Route
            path="/password/reset"
            component={ResetPassword}
          />
          <Route
            component={Login}
            path="/login"
          />
          <Route
            component={Logout}
            path="/logout"
          />
          <Route
            component={Dashboard}
            path=""
          />
          <Route
            path="/"
            component={Dashboard}
            exact
          />
        </Switch>
      </ScrollToTop>
    </Router>
  </Suspense>
);


export default App;
