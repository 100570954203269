import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import SuccessAlert from '../success-alert';

class FlashMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { flashMessage: undefined };
  }

  componentDidMount() {
    const { history } = this.props;
    const flashMessage = _.get(history, 'location.flash');
    this.setState({ flashMessage }, () => {
      _.unset(history, 'location.flash');
    });
  }

  resetMessageCallback = () => {
    this.setState({ flashMessage: undefined });
  }

  render() {
    const { flashMessage } = this.state;
    return (
      <>
        {
          flashMessage && (
          <SuccessAlert onClose={this.resetMessageCallback} message={flashMessage.message} />
          )
        }
      </>
    );
  }
}

FlashMessage.propTypes = {
  history: PropTypes.object.isRequired,
};


export default compose(withRouter)(FlashMessage);
