import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  CardBody,
  Row,
} from 'reactstrap';
import _ from 'lodash';
import HttpError from '../http-error';
import WithPermission, { everything } from '../../modules/with-permission';
import ButtonWithIcon from '../button-with-icon';
import styles from './styles.module.css';

const headerChildStyle = {
  paddingRight: '0px',
};

const ActionCard = ({
  title,
  actionButtonLabel,
  secondaryActionButtonLabel,
  thirdActionButtonLabel,
  onActionButtonClick,
  onSecondaryActionButtonClick,
  onThirdActionButtonClick,
  children,
  withActionButton,
  withSecondaryActionButton,
  withThirdActionButton,
  actionButtonRequiredPermission,
  actionButtonIcon,
  secondaryActionButtonIcon,
  thirdActionButtonIcon,
  actionButtonWidth,
  actionButtonStyleClass,
  inFailedState,
  headerChildren,
  actionButtonDisabled,
}) => (
  <Col md="12">
    <div className={styles.header}>
      <Row style={{ width: '100%' }}>
        <Col className={`${styles.title} titleBlock`}>
          { title }
        </Col>
        {
          _.map(headerChildren, (child, index) => (
            <Col className={styles.headerChild} lg="20" key={index} style={!withActionButton ? headerChildStyle : null}>
              { child}
            </Col>
          ))
      }

        { withActionButton && (
          <WithPermission permission={actionButtonRequiredPermission}>
            <Col lg="20">
              <ButtonWithIcon
                disabled={actionButtonDisabled}
                onClick={onActionButtonClick}
                title={actionButtonLabel}
                width={`${actionButtonWidth}px`}
                height="100%"
                styleClass={`${actionButtonStyleClass}`}
                icon={actionButtonIcon}
              />
              { withSecondaryActionButton && (
                <ButtonWithIcon
                  onClick={onSecondaryActionButtonClick}
                  title={secondaryActionButtonLabel}
                  width={`${actionButtonWidth}px`}
                  height="100%"
                  styleClass={`${actionButtonStyleClass}`}
                  icon={secondaryActionButtonIcon}
                />
              )}
              { withThirdActionButton && (
                <ButtonWithIcon
                  onClick={onThirdActionButtonClick}
                  title={thirdActionButtonLabel}
                  width={`${actionButtonWidth}px`}
                  height="100%"
                  styleClass={`${actionButtonStyleClass}`}
                  icon={thirdActionButtonIcon}
                />
              )}
            </Col>
          </WithPermission>
        )}
      </Row>
    </div>
    <CardBody>
      {
          !inFailedState ? children : <HttpError />
        }
    </CardBody>
  </Col>
);
ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string,
  secondaryActionButtonLabel: PropTypes.string,
  thirdActionButtonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  onActionButtonClick: PropTypes.func,
  onSecondaryActionButtonClick: PropTypes.func,
  onThirdActionButtonClick: PropTypes.func,
  withActionButton: PropTypes.bool,
  withSecondaryActionButton: PropTypes.bool,
  withThirdActionButton: PropTypes.bool,
  actionButtonRequiredPermission: PropTypes.string,
  inFailedState: PropTypes.bool,
  headerChildren: PropTypes.array,
  actionButtonIcon: PropTypes.string,
  secondaryActionButtonIcon: PropTypes.string,
  thirdActionButtonIcon: PropTypes.string,
  actionButtonWidth: PropTypes.number,
  actionButtonStyleClass: PropTypes.string,
  actionButtonDisabled: PropTypes.bool,
};

ActionCard.defaultProps = {
  withActionButton: true,
  withSecondaryActionButton: false,
  withThirdActionButton: false,
  actionButtonLabel: '',
  onActionButtonClick: () => {},
  actionButtonRequiredPermission: everything,
  secondaryActionButtonLabel: '',
  thirdActionButtonLabel: '',
  onSecondaryActionButtonClick: () => {},
  onThirdActionButtonClick: () => {},
  inFailedState: false,
  headerChildren: [],
  actionButtonIcon: '',
  secondaryActionButtonIcon: '',
  thirdActionButtonIcon: '',
  actionButtonWidth: 200,
  actionButtonStyleClass: '',
  actionButtonDisabled: false,
};

export default ActionCard;
