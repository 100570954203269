import React from 'react';
import PropTypes from 'prop-types';
import { Input as OriginalInput } from 'reactstrap';

const Input = ({
  maxLength,
  ...rest
}) => (
  <OriginalInput
    maxLength={maxLength}
    {...rest}
  />
);

Input.propTypes = {
  maxLength: PropTypes.number,
};

Input.defaultProps = {
  maxLength: 255,
};

export default Input;
