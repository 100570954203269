import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import {
  Table as OriginalTable,
} from 'reactstrap';

const Table = ({
  children,
  isEmpty,
  emptyMessage,
  t,
}) => (
  <>
    { isEmpty && (
      <p style={{ marginTop: 20 }}>{emptyMessage || t('no.data.yet')}</p>
    )}
    {
      !isEmpty && (
        <OriginalTable hover responsive="sm" size="sm">{ children }</OriginalTable>
      )
    }
  </>
);

Table.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isEmpty: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

Table.defaultProps = {
  emptyMessage: null,
  isEmpty: false,
};

export default compose(
  withTranslation(),
)(Table);
