import React from 'react';
import PropTypes from 'prop-types';
import {
  Label,
} from 'reactstrap';

const Checkbox = ({ checkboxLabel, ...rest }) => (
  <div>
    <input type="checkbox" id={checkboxLabel} {...rest} className="mr-3" />
    <Label className="label-clean" htmlFor={checkboxLabel}>{ checkboxLabel }</Label>
  </div>
);

Checkbox.propTypes = {
  checkboxLabel: PropTypes.string.isRequired,
};


export default Checkbox;
