import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import styles from './styles.module.css';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';
import OrderDetails from '../../components/order-details';
import SearchOrderForm from '../../components/search-order-form';
import Map from '../../components/map';
import PrimaryButtonWithIcon from '../../components/primary-button-with-icon';

class SearchOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: null,
      recaptcha: '',
      initialPosition: undefined,
    };

    this.captchaRef = React.createRef();
  }

  onLoginClick = () => {
    const { history } = this.props;
    history.push('/login');
  }

  onSubmit = async (values) => {
    const { httpRequest, httpPostLoading } = this.props;

    if (httpPostLoading) {
      return;
    }

    const { orderNumber } = values;
    const { recaptcha } = this.state;
    this.setState({ initialPosition: undefined });
    const data = {
      order_number: orderNumber,
      g_recaptcha: recaptcha,
    };

    const response = await httpRequest({
      method: 'post',
      url: 'search/order',
      data,
    }).catch(() => {});

    if (response) {
      const order = response.data;
      this.setState({
        orderDetails: order,
      });

      if (_.get(order, 'vehicle')) {
        const { geo } = _.get(order, 'vehicle');
        if (geo && _.get(geo, 'started', false)) {
          this.setState({
            initialPosition: [geo.lat, geo.long],
            channel: `order.${order.id}.vehicle.geo.changed`,
          });
        }
      }
    }
    this.captchaRef.current.reset();
  }

  onRecaptchaChanged = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken });
  }

  render() {
    const {
      t,
      httpPostLoading,
      httpPostFailReason,
      httpPostFailed,
    } = this.props;
    const {
      orderDetails,
      channel,
      initialPosition,
    } = this.state;

    return (
      <div className={`flex-start position-ref full-height page-container ${styles.grid}`}>
        <Row className={styles.alignCenter}>
          <div className={styles.container}>
            <Row className={`${styles.fullWidth}`}>
              <Col className={styles.logoContainer} lg="50">
                <div className={styles.logo}>
                  <img src="/assets/lfg-logo.png" alt="Logo" />
                </div>
                <div className={styles.logoText}>
                  { t('lf.logoBlock.name') }
                </div>
              </Col>
              <Col className={styles.buttonContainer}>
                <PrimaryButtonWithIcon
                  onClick={this.onLoginClick}
                  title={t('login')}
                  width="200px"
                  height="44px"
                  icon="/assets/images/lock-icon@2x.png"
                />
              </Col>
            </Row>

            <div className={`${styles.formContainer} ${styles.row}`}>
              <Row>
                <Col className={styles.formCol}>
                  <p className="lf-main-text">{ t('orders.searchOne') }</p>
                </Col>
              </Row>

              {
              httpPostFailed && httpPostFailReason
              && (
                <div className="content-lg">
                  <Col className={styles.formCol}>
                    <HttpErrorHandler {...httpPostFailReason} />
                  </Col>
                </div>
              )
              }
              <Row>
                <Col className={styles.formCol}>
                  <SearchOrderForm
                    t={t}
                    ref={this.captchaRef}
                    onSubmit={this.onSubmit}
                    hasRecaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    isLoading={httpPostLoading}
                    onRecaptchaChanged={this.onRecaptchaChanged}
                  />
                </Col>
              </Row>
              { !orderDetails && (
              <Row>
                <Col style={{ margin: '0px', padding: '0px' }}>
                  <p className="copyright-text">
                     Copyright ©
                    {(new Date().getFullYear())}
                    , Lafarge
                  </p>
                </Col>
              </Row>
              )}
            </div>
          </div>
        </Row>

        <Row>
          <div className={styles.lgContainer}>
            <Col className={styles.formCol}>
              {
                orderDetails
                && (
                <OrderDetails
                  shouldShowLessInfo={_.get(orderDetails, 'status.name', '') === 'Delivered'}
                  style={{ padding: '0px' }}
                  {...orderDetails}
                />
                )
              }
            </Col>
          </div>
        </Row>

        { orderDetails && channel && initialPosition && (
          <>
            <Row className={styles.row}>
              <Col>
                <p className="lf-main-text">{ t('map.view') }</p>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <Map channel={channel} initialPosition={initialPosition} />
              </Col>
            </Row>
          </>
        )}
        {

        orderDetails && (
        <Row>
          <Col md="12">
            <p className="copyright-text">
            Copyright ©
              {(new Date().getFullYear())}
          , Lafarge
            </p>
          </Col>
        </Row>
        )
}
      </div>
    );
  }
}

SearchOrders.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostLoading: PropTypes.bool,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.any,
  history: PropTypes.object.isRequired,
};

SearchOrders.defaultProps = {
  httpPostLoading: false,
  httpPostFailReason: undefined,
  httpPostFailed: false,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(SearchOrders);
