import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';
import HttpError from '../../components/http-error';
import WarehouseTableRow from '../../components/warehouse-table-row';
import Table from '../../components/table';
import WithPermission, { addWarehouses, editWarehouses } from '../with-permission';
import FlashMessage from '../../components/flash-message';
import withAPI from '../../hocs/with-api';

class Warehouses extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      warehouses: null,
      currentPage: getCurrentPageNumber(props),
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('warehouses.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadWarehouses();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/warehouses/${currentPage}`);
      this.loadWarehouses();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadWarehouses);
  }

  loadWarehouses = async () => {
    const { httpRequest } = this.props;
    const { currentPage } = this.state;
    const {
      data: {
        data: warehouses,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url: `/warehouses?page=${currentPage}`,
    });
    if (warehouses) {
      this.setState({
        warehouses,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/warehouses/${currentPage}`);
      this.loadWarehouses();
    });
  }

  render() {
    const {
      t,
      history,
      httpGetLoading,
      httpGetDone,
      httpGetFailed,
    } = this.props;
    const {
      warehouses,
      from,
      currentPage,
      totalPages,
      to,
      perPage,
      totalRecords,
    } = this.state;
    return (
      <ActionCard
        title={t('warehouses.all')}
        actionButtonLabel={t('warehouses.createNew')}
        onActionButtonClick={() => history.push('/warehouses/new')}
        actionButtonRequiredPermission={addWarehouses}
        actionButtonIcon="/assets/add-icon.png"
        actionButtonWidth={260}
      >
        { httpGetLoading && <Spinner marginTop="555px" /> }
        {
          <FlashMessage />
        }
        {
          httpGetFailed && (
          <HttpError
            withTryAgainButton
            onTryAgain={this.loadWarehouses}
          />
          )
        }
        {
        httpGetDone && !httpGetFailed && warehouses && !httpGetLoading && (
          <>
            <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(warehouses)} emptyMessage={t('messages.noData')}>
              <thead>
                <tr>
                  <th className="align-middle">{ t('warehouses.name') }</th>
                  <th className="align-middle hideMobile">{ t('address') }</th>
                  <WithPermission permission={[editWarehouses, addWarehouses]}>
                    <th className="lfg-actions align-middle actions">{ t('actions') }</th>
                  </WithPermission>
                </tr>
              </thead>
              <tbody>
                { _.map(warehouses, (warehouse) => (
                  <WarehouseTableRow
                    {...warehouse}
                    key={`warehouses-${warehouse.id}`}
                  />
                ))}
              </tbody>
            </Table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={this.handlePageChange}
              totalRecords={totalRecords}
              perPage={perPage}
              from={from}
              to={to}
            />
          </>
        )
        }
      </ActionCard>
    );
  }
}

Warehouses.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  httpGetDone: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Warehouses.defaultProps = {
  httpGetDone: false,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(Warehouses);
