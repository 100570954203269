import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';
import SuccessAlert from '../../components/success-alert';
import UserForm from '../../components/user-form';
import ButtonWithIcon from '../../components/button-with-icon';
import { roles as userRoles } from '../../hocs/with-user-info';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      carriers: [],
      userCreatedOk: false,
    };
  }

  async componentDidMount() {
    const { httpRequest, t } = this.props;
    document.title = `${t('users.createNew')} - ${t('app.name')}`;
    const { data: roles } = await httpRequest({
      method: 'get',
      url: 'roles',
    });
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });
    this.setState({
      roles,
      carriers,
    });
  }

  onSubmit = async (values) => {
    const { httpRequest } = this.props;
    const {
      name, email, password, role, carrier,
    } = values;
    const data = {
      name,
      email,
      password,
      role: role.id,
      ...(role.id === userRoles.dispatcher) && { carrier: carrier.id },
    };

    this.setState({
      userCreatedOk: false,
    });
    const posted = await httpRequest({
      method: 'post',
      url: 'users',
      data,
    });
    if (posted) {
      this.setState({
        userCreatedOk: true,
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }


  render() {
    const { roles, carriers, userCreatedOk } = this.state;
    const {
      t,
      httpPostFailed,
      httpPostFailReason,
    } = this.props;
    const formProps = {
      roles,
      carriers,
      userCreatedOk,
    };

    return (
      <>
        <Col md="12">
          {
            userCreatedOk && <SuccessAlert message={t('users.created')} />
          }
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('users.createNew') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {
            httpPostFailed && httpPostFailReason && <HttpErrorHandler {...httpPostFailReason} />
            }
          <UserForm {...formProps} onSubmit={this.onSubmit} />
        </Col>
      </>
    );
  }
}

CreateUser.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
};

CreateUser.defaultProps = {
  httpPostFailed: false,
  httpPostFailReason: undefined,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(CreateUser);
