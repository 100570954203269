import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withUserInfo from '../../hocs/with-user-info';

import ButtonWithConfirmation from '../button-with-confirmation';
import Input from '../input';
import ButtonWithIcon from '../button-with-icon';
import DropdownInput from '../dropdown-input';
import styles from './styles.module.css';
import WithPermission, { deleteVehicles } from '../../modules/with-permission';


const TRAILER_TYPE_BULK = 'BULK';
const TRAILER_TYPE_FREIGHT = 'FREIGHT';

const VehicleForm = ({
  drivers,
  carriers,
  transport,
  inEditMode,
  onSubmit,
  onDelete,
  t,
  isConfirmButtonLoading,
  isDeleteButtonLoading,
  user,
  vehicleCreated,
}) => {
  const transportTypes = [
    {
      id: TRAILER_TYPE_BULK,
      value: TRAILER_TYPE_BULK,
      label: t('vehicles.types.bulk'),
    },
    {
      id: TRAILER_TYPE_FREIGHT,
      value: TRAILER_TYPE_FREIGHT,
      label: t('vehicles.types.freight'),
    },
  ];

  const [tractorNr, setTractorNR] = useState('');
  const [trailerNr, setTrailerNr] = useState('');
  const [truckSapId, setTruckSapId] = useState('');
  const [truckExternalId, setTruckExternalId] = useState('');
  const [carrier, setCarrier] = useState('');
  const [driver, setDriver] = useState('');
  const [transportType, setTransportType] = useState('');

  const handleTractorNrChange = (event) => setTractorNR(_.get(event, 'target.value', ''));
  const handleTrailerNrChange = (event) => setTrailerNr(_.get(event, 'target.value', ''));
  const handleTruckSapIdChange = (event) => setTruckSapId(_.get(event, 'target.value', ''));
  const handleTruckExternalIdChange = (event) => setTruckExternalId(_.get(event, 'target.value', ''));
  const handleSetCarrier = (carrierId) => {
    setCarrier(carrierId);
    setDriver('');
  };
  const handleTransportTypeChange = (typeId) => setTransportType(typeId);
  const handleSetDriver = (driverId) => setDriver(driverId);

  const values = {
    tractorNr,
    trailerNr,
    truckSapId,
    truckExternalId,
    carrier,
    transportType,
    driver,
  };
  const { isDispatcher } = user;

  const getCarrier = () => {
    if (isDispatcher) {
      const carrierId = _.get(user, 'carriers[0].id');
      return carrierId;
    }
    return _.get(transport, 'carrier_id', '');
  };

  const resolvedCarrier = getCarrier();

  useEffect(() => {
    setCarrier(resolvedCarrier);
    setTractorNR(inEditMode ? transport.number_plate : '');
    setTrailerNr(inEditMode ? transport.number_plate_trailer : '');
    setTruckSapId(inEditMode ? transport.truck_sap_id : '');
    setTruckExternalId(inEditMode ? transport.external_id : '');
    setDriver(inEditMode ? transport.driver_id : '');
    setTransportType(inEditMode ? transport.type : '');
  }, [inEditMode, resolvedCarrier, transport, vehicleCreated]);

  const carrierDrivers = carrier ? _.filter(drivers, ['carrier.id', _.toNumber(carrier)]) : [];
  const carrierObj = _.find(carriers, ['id', _.toNumber(resolvedCarrier)]);
  const driverObj = _.find(drivers, ['id', _.toNumber(driver)]);
  const transportTypeObj = _.find(transportTypes, ['value', transportType]);

  const deleteButtonProps = {
    disabled: isConfirmButtonLoading || isDeleteButtonLoading,
    loading: isDeleteButtonLoading,
  };

  const fields = [tractorNr, trailerNr, truckSapId, carrier, transportType];
  const isSubmitButtonValid = _.isEmpty(_.filter(fields, (f) => _.isEmpty(_.toString(f))));


  const carrierDriversOptions = _.map(carrierDrivers, (record) => ({
    id: record.id,
    label: record.name,
    value: record.id,
  }));

  const carrierOptions = _.map(carriers, (record) => ({
    id: record.id,
    label: record.name,
    value: record.id,
  }));

  const vehicleTypeOptions = _.map(transportTypes, (record) => ({
    id: record.id,
    label: record.label,
    value: record.id,
  }));

  const defaultSelectedCarrier = (carrierObj ? {
    label: carrierObj.name,
    value: carrierObj,
    id: carrierObj.id,
  } : undefined);

  const defaultSelectedDriver = (driverObj ? {
    label: driverObj.name,
    value: driverObj,
    id: driverObj.id,
  } : undefined);
  const defaultSelectedTransportType = (transportTypeObj ? {
    label: transportTypeObj.label,
    value: transportTypeObj,
    id: transportTypeObj.id,
  } : undefined);

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Row>
        <div className={styles.vehicleFormGroup}>
          {
            !isDispatcher && (
              <Col md="12">
                <FormGroup>
                  <DropdownInput
                    required
                    options={carrierOptions}
                    label={t('carriers.one')}
                    placeholder={t('carriers.one')}
                    onSelected={handleSetCarrier}
                    defaultSelected={defaultSelectedCarrier}
                  />
                </FormGroup>
              </Col>
            )
          }
          <Col md="12">
            <FormGroup>
              <DropdownInput
                required
                options={carrierDriversOptions}
                label={t('vehicles.default.driver')}
                placeholder={t('vehicles.default.driver')}
                onSelected={handleSetDriver}
                disabled={!isDispatcher && !carrier}
                defaultSelected={defaultSelectedDriver}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <DropdownInput
                required
                options={vehicleTypeOptions}
                label={t('vehicles.type')}
                placeholder={t('vehicles.type')}
                onSelected={handleTransportTypeChange}
                value={transportType}
                defaultSelected={defaultSelectedTransportType}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-clean" for="truckExternalId">{ t('vehicle.externalId') }</Label>
              <Input
                value={truckExternalId}
                type="text"
                name="truckExternalId"
                id="truckExternalId"
                className="input-clean"
                placeholder={t('vehicle.externalId')}
                onChange={handleTruckExternalIdChange}
              />
            </FormGroup>
          </Col>
        </div>
        <div className={`${styles.vehicleFormGroup} ${styles.vehicleFormGroupSecondBlock}`}>
          <FormGroup>
            <Label className="label-clean" for="tractorNr">{ t('nr.tractor') }</Label>
            <Input
              value={tractorNr}
              type="text"
              name="tractorNr"
              id="tractorNr"
              className="input-clean"
              placeholder={t('nr.tractor')}
              onChange={handleTractorNrChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label-clean" for="trailerNr">{ t('nr.trailer') }</Label>
            <Input
              value={trailerNr}
              type="text"
              name="trailerNr"
              id="trailerNr"
              className="input-clean"
              placeholder={t('nr.trailer')}
              onChange={handleTrailerNrChange}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label-clean" for="trailerNr">{ t('vehicle.sapId') }</Label>
            <Input
              value={truckSapId}
              type="text"
              name="truckSapId"
              id="truckSapId"
              className="input-clean"
              placeholder={t('vehicle.sapId')}
              onChange={handleTruckSapIdChange}
            />
          </FormGroup>
        </div>
      </Row>
      <Col md="12" className={styles.btnFormGroup}>
        <ButtonWithIcon
          title={inEditMode ? t('save') : t('vehicles.createNew')}
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isSubmitButtonValid || isConfirmButtonLoading || isDeleteButtonLoading}
        />
        {
            inEditMode && (
              <WithPermission permission={deleteVehicles}>
                <ButtonWithConfirmation
                  buttonProps={deleteButtonProps}
                  buttonLabel={t('vehicles.delete')}
                  headerText={t('vehicles.delete')}
                  confirmText={t('vehicles.confirmDelete')}
                  onConfirm={() => onDelete(transport.id)}
                  alignment="left-aligned"
                  icon="/assets/images/delete-icon@2x.png"
                />
              </WithPermission>
            )
          }
      </Col>
    </Form>
  );
};

VehicleForm.propTypes = {
  vehicleCreated: PropTypes.bool,
  user: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
  isDeleteButtonLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  transport: PropTypes.object,
  carriers: PropTypes.array,
  drivers: PropTypes.array,
};

VehicleForm.defaultProps = {
  vehicleCreated: false,
  inEditMode: false,
  isConfirmButtonLoading: false,
  isDeleteButtonLoading: false,
  onDelete: () => {},
  transport: {},
  carriers: [],
  drivers: [],
};


export default compose(
  withTranslation(),
  withUserInfo,
)(VehicleForm);
