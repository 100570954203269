import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import DropdownFilter from '../../components/dropdown-filter';

import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';
import VehicleTableRow from '../../components/vehicle-table-row';

import { addVehicles } from '../with-permission';

import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import Table from '../../components/table';
import FlashMessage from '../../components/flash-message';

const TRAILER_TYPE_BULK = 'BULK';
const TRAILER_TYPE_FREIGHT = 'FREIGHT';

class Vehicles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: getCurrentPageNumber(props),
      vehicles: null,
      carriers: [],
      shouldFilterByCarrier: false,
      filterCarrierId: null,
      filterVehicleType: null,
    };
  }

  async componentDidMount() {
    const { t, user } = this.props;
    document.title = `${t('vehicles.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadVechicles();

    if (!user.isDispatcher) {
      this.loadCarriers();
    }
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  loadVechicles = async () => {
    const { httpRequest } = this.props;
    const {
      currentPage,
      shouldFilterByCarrier: withFilter,
      filterCarrierId: cid,
      filterVehicleType,
    } = this.state;

    const url = !withFilter ? `vehicles?page=${currentPage}&type=${filterVehicleType}` : `vehicles?page=${currentPage}`
    + `&carrier_id=${cid}&type=${filterVehicleType}`;
    const response = await httpRequest({
      method: 'get',
      url,
    });
    if (response) {
      const {
        data: {
          data: vehicles,
          total,
          per_page,
          last_page,
          from,
          to,
        },
      } = response;

      this.setState({
        vehicles,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  };

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/vehicles/${currentPage}`);
      this.loadVechicles();
    });
  };

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState(
      {
        currentPage: page,
      },
      this.loadVechicles,
    );
  };

  onCarrierSelected = (carrierId) => {
    this.setState({
      shouldFilterByCarrier: carrierId !== null,
      filterCarrierId: carrierId,
      currentPage: 1,
    }, this.loadVechicles);
  }

  onVehicleTypeSelected = (vehicleType) => {
    this.setState({
      filterVehicleType: vehicleType,
      currentPage: 1,
    }, this.loadVechicles);
  }

  async loadCarriers() {
    const { httpRequest } = this.props;
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });
    if (carriers) {
      this.setState({
        carriers,
      });
    }
  }

  render() {
    const {
      t,
      history,
      httpGetLoading,
      httpGetFailed,
      httpGetDone,
      user,
    } = this.props;

    const {
      vehicles,
      currentPage,
      totalPages,
      perPage,
      totalRecords,
      from,
      to,
      carriers,
    } = this.state;

    const transportTypes = [
      {
        id: TRAILER_TYPE_BULK,
        value: TRAILER_TYPE_BULK,
        label: t('vehicles.types.bulk'),
      },
      {
        id: TRAILER_TYPE_FREIGHT,
        value: TRAILER_TYPE_FREIGHT,
        label: t('vehicles.types.freight'),
      },
    ];

    const filterOptions = _.map(carriers, (carrier) => ({
      id: carrier.id,
      label: carrier.name,
      value: carrier.id,
    }));

    const vehicleTypeOptions = _.map(transportTypes, (record) => ({
      id: record.id,
      label: record.label,
      value: record.id,
    }));

    const carrierFilter = (
      <DropdownFilter
        dropDownStyles={{ toggleStyles: { marginRight: '12px', width: '240px' } }}
        options={filterOptions}
        label={t('carriers.one')}
        onSelected={this.onCarrierSelected}
      />
    );

    const vehicleTypeFilter = (
      <DropdownFilter
        dropDownStyles={{ toggleStyles: { width: '240px' } }}
        options={vehicleTypeOptions}
        label={t('vehicles.type')}
        onSelected={this.onVehicleTypeSelected}
      />
    );
    return (
      <>
        <ActionCard
          title={t('vehicles.all')}
          actionButtonLabel={t('vehicles.createNew')}
          onActionButtonClick={() => history.push('/vehicles/new')}
          actionButtonRequiredPermission={addVehicles}
          inFailedState={httpGetFailed}
          actionButtonIcon="/assets/add-icon.png"
          headerChildren={!user.isDispatcher ? [carrierFilter, vehicleTypeFilter] : []}
        >
          {httpGetLoading && <Spinner marginTop="555px" />}
          {
            <FlashMessage />
          }
          <>
            <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(vehicles)} emptyMessage={t('messages.noData')}>
              <thead>
                <tr>
                  <th className="align-middle">{t('nr.tractor')}</th>
                  <th className="align-middle hideMobile">{t('nr.trailer')}</th>
                  { (user.isDispatcher || user.isAdmin) && <th>{t('vehicles.type')}</th> }
                  {
                    !user.isDispatcher && (
                    <th className="align-middle">{t('carriers.one')}</th>
                    )
                  }
                  {
                    <th className="align-middle">{t('vehicle.externalId')}</th>
                  }
                  <th className="align-middle actions">{t('actions')}</th>
                </tr>
              </thead>
              {!httpGetFailed && httpGetDone && vehicles && (
                <tbody>
                  {
                    _.map(vehicles, (vehicle) => (
                      <VehicleTableRow key={vehicle.id} {...vehicle} />
                    ))
                }
                </tbody>
              )}
            </Table>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={this.handlePageChange}
              totalRecords={totalRecords}
              perPage={perPage}
              from={from}
              to={to}
            />
          </>

        </ActionCard>
      </>
    );
  }
}

Vehicles.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetFailed: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withRouter,
  withUserInfo,
  withAPI,
)(Vehicles);
