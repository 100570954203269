import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import Input from '../input';
import ButtonWithIcon from '../button-with-icon';


const ChangePasswordForm = ({
  isLoading,
  onSubmit,
  t,
}) => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const handleSetCurrentPassword = (e) => setCurrentPassword(_.get(e, 'target.value', ''));

  const [newPassword, setNewPassword] = React.useState('');
  const handleSetNewPassword = (e) => setNewPassword(_.get(e, 'target.value', ''));

  const [confirmationPassword, setConfirmationPassword] = React.useState('');
  const handleSetConfirmationPassword = (e) => setConfirmationPassword(_.get(e, 'target.value', ''));

  const values = {
    currentPassword,
    newPassword,
    confirmationPassword,
  };

  const submitDisabled = _.isEmpty(currentPassword)
    || _.isEmpty(newPassword)
    || _.isEmpty(confirmationPassword)
    || isLoading;

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="currentPassword">{ t('password.current') }</Label>
          <Input
            required
            autoComplete="off"
            value={currentPassword}
            type="password"
            name="currentPassword"
            id="currentPassword"
            className="input-clean"
            placeholder={t('password.current')}
            onChange={handleSetCurrentPassword}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="newPassword">{ t('password.new') }</Label>
          <Input
            required
            autoComplete="off"
            value={newPassword}
            type="password"
            name="newPassword"
            id="newPassword"
            className="input-clean"
            placeholder={t('password.new')}
            onChange={handleSetNewPassword}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="confirmationPassword">{ t('password.confirm') }</Label>
          <Input
            required
            autoComplete="off"
            value={confirmationPassword}
            type="password"
            name="confirmationPassword"
            id="confirmationPassword"
            className="input-clean"
            placeholder={t('password.confirm')}
            onChange={handleSetConfirmationPassword}
          />
        </FormGroup>
      </Col>
      <Col md="12">
        <ButtonWithIcon
          title={t('password.changeCTA')}
          icon="/assets/images/save-icon@2x.png"
          className="btn-main btn-save"
          disabled={submitDisabled}
          loading={isLoading}
        />
      </Col>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

ChangePasswordForm.defaultProps = {
  isLoading: false,
};

export default compose(
  withTranslation(),
)(ChangePasswordForm);
