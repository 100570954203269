import React, { useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Form,
  FormGroup,
  Label,
  Col,
} from 'reactstrap';

import Input from '../input';
import ButtonWithConfirmation from '../button-with-confirmation';
import ButtonWithIcon from '../button-with-icon';

import WithPermission, { deleteWarehouses } from '../../modules/with-permission';

const WarehouseForm = React.forwardRef((props, ref) => {
  const {
    inEditMode,
    warehouse,
    onSubmit,
    onDelete,
    confirmationModalError,
    t,
    isConfirmButtonLoading,
    isDeleteButtonLoading,
  } = props;

  const [name, setName] = useState(_.get(warehouse, 'name', ''));
  const [address, setAddress] = useState(_.get(warehouse, 'address', ''));
  const [ivmsLocationId, setIvmsLocationId] = useState(_.get(warehouse, 'ivms_location_id', ''));
  const handleSetName = (event) => setName(_.get(event, 'target.value', ''));
  const handleSetAddress = (event) => setAddress(_.get(event, 'target.value', ''));
  const handleSetIvmsLocationId = (event) => setIvmsLocationId(_.get(event, 'target.value', ''));

  const resetInputs = () => {
    setName('');
    setAddress('');
    setIvmsLocationId('');
  };

  useImperativeHandle(ref, () => ({
    resetInputs,
  }));

  const values = {
    name,
    address,
    ivmsLocationId,
  };

  const deleteButtonProps = {
    loading: isDeleteButtonLoading,
    disabled: isConfirmButtonLoading || isDeleteButtonLoading,
  };

  const isSubmitButtonValid = !_.isEmpty(name) && !_.isEmpty(address);

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12" className="noPadding">
        <FormGroup>
          <Label className="label-clean" for="name">{ t('warehouses.name') }</Label>
          <Input
            required
            value={name}
            type="text"
            name="name"
            id="name"
            className="input-clean"
            placeholder={t('warehouses.name')}
            onChange={handleSetName}
          />
        </FormGroup>
      </Col>
      <Col md="12" className="noPadding">
        <FormGroup>
          <Label className="label-clean" for="address">{ t('warehouses.address') }</Label>
          <Input
            required
            value={address}
            type="text"
            name="address"
            id="address"
            className="input-clean"
            placeholder={t('warehouses.address')}
            onChange={handleSetAddress}
          />
        </FormGroup>
      </Col>
      <Col md="12" className="noPadding">
        <FormGroup>
          <Label className="label-clean" for="ivmsLocationId">{ t('id.ivms') }</Label>
          <Input
            value={ivmsLocationId}
            type="text"
            name="ivmsLocationId"
            id="ivmsLocationId"
            className="input-clean"
            placeholder={t('id.ivms')}
            onChange={handleSetIvmsLocationId}
          />
        </FormGroup>
      </Col>
      <Col md="12" className="btn-form-group noPadding">
        <ButtonWithIcon
          title={inEditMode ? t('save') : t('add')}
          icon="/assets/images/save-icon@2x.png"
          alignment="left-aligned"
          disabled={!isSubmitButtonValid || isDeleteButtonLoading || isConfirmButtonLoading}
        />
        {
            inEditMode && (
              <WithPermission permission={deleteWarehouses}>
                <ButtonWithConfirmation
                  buttonProps={deleteButtonProps}
                  buttonLabel={t('warehouses.delete')}
                  headerText={t('warehouses.delete')}
                  confirmText={t('warehouses.confirmDelete')}
                  onConfirm={() => onDelete(warehouse.id)}
                  confirmationModalError={confirmationModalError}
                  alignment="left-aligned"
                  icon="/assets/images/delete-icon@2x.png"
                />
              </WithPermission>
            )
          }
      </Col>
    </Form>
  );
});

WarehouseForm.propTypes = {
  inEditMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  warehouse: PropTypes.object,
  confirmationModalError: PropTypes.string,
  isDeleteButtonLoading: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
};

WarehouseForm.defaultProps = {
  inEditMode: false,
  onDelete: () => {},
  warehouse: {},
  confirmationModalError: null,
  isDeleteButtonLoading: false,
  isConfirmButtonLoading: false,
};


export default WarehouseForm;
