import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import HttpError from '../../components/http-error';
import ActionCard from '../../components/action-card';
import CarrierTableRow from '../../components/carrier-table-row';
import Spinner from '../../components/spinner';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';

import WithPermission, { addCarriers, editCarriers } from '../with-permission';
import Table from '../../components/table';
import withAPI from '../../hocs/with-api';
import FlashMessage from '../../components/flash-message';

class Carriers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carriers: [],
      currentPage: getCurrentPageNumber(props),
      totalPages: 1,
      totalRecords: 1,
      perPage: 1,
      from: 1,
      to: 1,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('carriers.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadCarriers();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/carriers/${currentPage}`);
      this.loadCarriers();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadCarriers);
  }

  loadCarriers = async () => {
    const { httpRequest } = this.props;
    const { currentPage } = this.state;
    const {
      data: {
        data: carriers,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url: `carriers?page=${currentPage}`,
    });
    this.setState({
      carriers,
      totalPages: last_page,
      perPage: per_page,
      totalRecords: total,
      from,
      to,
    });
  }

  render() {
    const {
      t,
      history,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
    } = this.props;
    const {
      carriers,
      from,
      currentPage,
      totalPages,
      to,
      perPage,
      totalRecords,
    } = this.state;
    return (
      <ActionCard
        title={t('carriers.all')}
        actionButtonLabel={t('carriers.createNew')}
        onActionButtonClick={() => history.push('/carriers/new')}
        actionButtonRequiredPermission={addCarriers}
        actionButtonIcon="/assets/add-icon.png"
        actionButtonWidth={220}
      >
        { httpGetLoading && <Spinner marginTop="555px" /> }
        {
          <FlashMessage />
        }
        {
          httpGetFailed && (
          <HttpError
            withTryAgainButton
            onTryAgain={this.loadCarriers}
          />
          )
        }
        {
          httpGetDone && !httpGetFailed && !httpGetLoading && (
            <>
              <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(carriers)} emptyMessage={t('messages.noData')}>
                <thead>
                  <tr>
                    <th className="align-middle">{ t('carriers.name') }</th>
                    <th className="align-middle hideMobile">{ t('carriers.description') }</th>
                    <th className="align-middle">{ t('kpi.marketShare') }</th>
                    <WithPermission permission={editCarriers}>
                      <th className="align-middle actions">{t('actions')}</th>
                    </WithPermission>
                  </tr>
                </thead>
                <tbody>
                  { _.map(carriers, (carrier) => (
                    <CarrierTableRow {...carrier} key={`carrier-${carrier.id}`} />
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={this.handlePageChange}
                totalRecords={totalRecords}
                perPage={perPage}
                from={from}
                to={to}
              />
            </>
          )
        }
      </ActionCard>
    );
  }
}

Carriers.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Carriers.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(Carriers);
