import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
} from 'reactstrap';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import HttpErrorHandler from '../../components/http-error-handler';
import DriverForm from '../../components/driver-form';
import SuccessAlert from '../../components/success-alert';
import ButtonWithIcon from '../../components/button-with-icon';

class CreateDriver extends PureComponent {
  constructor() {
    super();
    this.state = {
      carriers: [],
      driverCreated: false,
      driver: {},
    };
  }

  async componentDidMount() {
    const { httpRequest, t, user } = this.props;
    document.title = `${t('drivers.createNew')} - ${t('app.name')}`;
    if (user.isDispatcher) {
      return;
    }
    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
    });
    this.setState({
      carriers,
    });
  }

  onSubmit = async (values) => {
    const { name, phone, carrier } = values;
    this.setState({ driverCreated: false });
    const { httpRequest } = this.props;
    const data = {
      name,
      phone,
      carrier_id: carrier.id,
    };
    const posted = await httpRequest({
      method: 'post',
      url: 'drivers',
      data,
    });
    if (posted) {
      this.setState({
        driverCreated: true,
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t, httpPostFailed, httpPostFailReason, httpPostLoading,
    } = this.props;
    const { carriers, driverCreated, driver } = this.state;
    return (
      <>
        <Col md="12">
          {
            driverCreated && <SuccessAlert message={t('drivers.created')} />
          }
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('drivers.createNew') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {
            httpPostFailed && httpPostFailReason && <HttpErrorHandler {...httpPostFailReason} />
          }
          <DriverForm
            driverCreated={driverCreated}
            driver={driver}
            carriers={carriers}
            onSubmit={this.onSubmit}
            isConfirmButtonLoading={httpPostLoading}
          />
        </Col>
      </>
    );
  }
}

CreateDriver.propTypes = {
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostLoading: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
  history: PropTypes.object.isRequired,
};

CreateDriver.defaultProps = {
  httpPostFailed: false,
  httpPostFailReason: undefined,
  httpPostLoading: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withUserInfo,
  withRouter,
)(CreateDriver);
