import React, { useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Alert,
} from 'reactstrap';

import Input from '../input';
import ButtonWithConfirmation from '../button-with-confirmation';
import ButtonWithIcon from '../button-with-icon';

import WithPermission, { deleteCarriers } from '../../modules/with-permission';

const CarrierForm = React.forwardRef(
  (props, ref) => {
    const {
      carrier,
      inEditMode,
      onSubmit,
      onDelete,
      onHold,
      onUnHold,
      t,
      isConfirmButtonLoading,
      isDeleteButtonLoading,
      isHoldButtonLoading,
    } = props;

    const [name, setName] = useState(_.get(carrier, 'name', ''));
    const handleSetName = (event) => setName(_.get(event, 'target.value', ''));
    const [sapId, setSapId] = useState(_.get(carrier, 'sap_id', '') || '');
    const handleSetSapId = (event) => setSapId(_.get(event, 'target.value', ''));
    const [description, setDescription] = useState(_.get(carrier, 'description', '') || '');
    const handleSetDescription = (event) => setDescription(_.get(event, 'target.value', ''));
    const [priceIndex, setPriceIndex] = useState(_.get(carrier, 'price_index', ''));
    const handleSetPriceIndex = (event) => setPriceIndex(_.get(event, 'target.value', ''));

    const resetInputs = () => {
      setName('');
      setDescription('');
      setSapId('');
      setPriceIndex('');
    };

    useImperativeHandle(ref, () => ({
      resetInputs,
    }));


    const values = {
      name,
      sapId,
      description,
      priceIndex,
    };

    const deleteButtonProps = {
      loading: isDeleteButtonLoading,
      disabled: isConfirmButtonLoading || isDeleteButtonLoading || isHoldButtonLoading,
    };
    const holdButtonProps = {
      loading: isHoldButtonLoading,
      disabled: isConfirmButtonLoading || isDeleteButtonLoading || isHoldButtonLoading,
    };
    return (
      <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
        {
          carrier.is_on_hold > 0 && (
            <Col md="12">
              <Alert key={t('carriers.holdMessage')} className="alert alert-warning warning-alert" variant="danger">
                <Row>
                  <Col className="alert-message">
                    {t('carriers.holdMessage')}
                  </Col>
                  <Col lg="20" className="error-close-btn-container">
                    <ButtonWithIcon
                      onClick={() => onUnHold(carrier.id)}
                      type="button"
                      styleClass="my-0"
                      title={t('carriers.unhold')}
                      disabled={isDeleteButtonLoading || isConfirmButtonLoading || isHoldButtonLoading}
                    />
                  </Col>
                </Row>
              </Alert>
            </Col>
          )
        }
        <Col md="12">
          <FormGroup>
            <Label className="label-clean" for="name">{ t('carriers.name') }</Label>
            <Input
              value={name}
              type="text"
              name="name"
              id="name"
              className="input-clean"
              placeholder={t('carriers.name')}
              onChange={handleSetName}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-clean" for="sapId">{ t('carriers.sapId') }</Label>
            <Input
              value={sapId}
              type="text"
              name="sapId"
              id="sapId"
              className="input-clean"
              placeholder={t('carriers.sapId')}
              onChange={handleSetSapId}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-clean" for="description">{ t('carriers.description') }</Label>
            <Input
              value={description}
              type="text"
              name="description"
              id="description"
              className="input-clean"
              placeholder={t('carriers.description')}
              onChange={handleSetDescription}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-clean" for="priceIndex">{ t('carriers.priceIndex') }</Label>
            <Input
              value={priceIndex}
              type="number"
              name="priceIndex"
              id="priceIndex"
              className="input-clean"
              placeholder="1.0"
              onChange={handleSetPriceIndex}
            />
          </FormGroup>
        </Col>
        <Col md="12" className="btn-form-group">
          <ButtonWithIcon
            title={inEditMode ? t('save') : t('add')}
            icon="/assets/images/save-icon@2x.png"
            alignment="left-aligned"
            disabled={_.isEmpty(name) || _.isEmpty(sapId) || isDeleteButtonLoading || isConfirmButtonLoading}
          />
          {
            inEditMode && (
              <WithPermission permission={deleteCarriers}>
                <ButtonWithConfirmation
                  buttonProps={deleteButtonProps}
                  buttonLabel={t('carriers.delete')}
                  headerText={t('carriers.delete')}
                  confirmText={t('carriers.confirmDelete')}
                  onConfirm={() => onDelete(carrier.id)}
                  alignment="left-aligned"
                  icon="/assets/images/delete-icon@2x.png"
                />
              </WithPermission>
            )
          }
          {
            inEditMode && !carrier.is_on_hold && (
              <WithPermission permission={deleteCarriers}>
                <ButtonWithConfirmation
                  buttonProps={holdButtonProps}
                  buttonLabel={t('carriers.hold')}
                  headerText={t('carriers.hold')}
                  confirmText={t('carriers.confirmHold')}
                  onConfirm={() => onHold(carrier.id)}
                  alignment="left-aligned"
                  icon="/assets/images/lock-icon@2x.png"
                />
              </WithPermission>
            )
          }
        </Col>
      </Form>
    );
  },
);

CarrierForm.propTypes = {
  inEditMode: PropTypes.bool,
  isDeleteButtonLoading: PropTypes.bool,
  isConfirmButtonLoading: PropTypes.bool,
  isHoldButtonLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onHold: PropTypes.func,
  onUnHold: PropTypes.func,
  carrier: PropTypes.object,
};

CarrierForm.defaultProps = {
  inEditMode: false,
  onDelete: () => {},
  onHold: () => {},
  onUnHold: () => {},
  carrier: {},
  isDeleteButtonLoading: false,
  isConfirmButtonLoading: false,
  isHoldButtonLoading: false,
};


export default CarrierForm;
