import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import HttpError from '../../components/http-error';
import ActionCard from '../../components/action-card';
import Spinner from '../../components/spinner';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';

import withAPI from '../../hocs/with-api';
import ClientsTableRow from '../../components/clients-table-row';
import Table from '../../components/table';
import FlashMessage from '../../components/flash-message';

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      currentPage: getCurrentPageNumber(props),
      totalPages: 1,
      totalRecords: 1,
      perPage: 1,
      from: 1,
      to: 1,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    document.title = `${t('clients.all')} - ${t('app.name')}`;
    window.onpopstate = this.onBackButtonClick;
    this.loadClients();
  }

  componentWillUnmount() {
    window.onpopstate = null;
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/clients/${currentPage}`);
      this.loadClients();
    });
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadClients);
  }

  loadClients = async () => {
    const { httpRequest } = this.props;
    const { currentPage } = this.state;
    const {
      data: {
        data: clients,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url: `clients?page=${currentPage}`,
    });

    this.setState({
      clients,
      totalPages: last_page,
      perPage: per_page,
      totalRecords: total,
      from,
      to,
    });
  }

  render() {
    const {
      t,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
    } = this.props;
    const {
      clients,
      from,
      currentPage,
      totalPages,
      to,
      perPage,
      totalRecords,
    } = this.state;
    return (
      <ActionCard title={t('clients.all')} withActionButton={false}>
        { httpGetLoading && <Spinner marginTop="555px" /> }
        {
          <FlashMessage />
        }
        {
          httpGetFailed && (
          <HttpError
            withTryAgainButton
            onTryAgain={this.loadClients}
          />
          )
        }
        {
          httpGetDone && !httpGetFailed && !httpGetLoading && (
            <>
              <Table hover responsive="sm" size="sm" isEmpty={_.isEmpty(clients)} emptyMessage={t('messages.noData')}>
                <thead>
                  <tr>
                    <th className="align-middle">{ t('clients.name') }</th>
                    <th className="align-middle hideMobile">{ t('clients.address') }</th>
                    <th className="align-middle actions">{ t('actions') }</th>
                  </tr>
                </thead>
                <tbody>
                  { _.map(clients, (client) => (
                    <ClientsTableRow client={client} key={`client-${client.id}`} />
                  ))}
                </tbody>
              </Table>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={this.handlePageChange}
                totalRecords={totalRecords}
                perPage={perPage}
                from={from}
                to={to}
              />
            </>
          )
        }
      </ActionCard>
    );
  }
}

Clients.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
};

Clients.defaultProps = {
  httpGetLoading: true,
  httpGetFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(Clients);
