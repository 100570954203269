import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Alert,
  Row,
  Form,
  Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import styles from './styles.module.css';

import Input from '../../components/input';
import Button from '../../components/button';

import getAPIClient from '../../services/api';

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isOK: false,
      isLoading: false,
    };
  }

  onEmailChange = (event) => {
    const { value: email } = event.target;
    this.setState({
      email,
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const apiClient = getAPIClient();
    const { email } = this.state;
    const { history, t } = this.props;
    this.setState({
      isLoading: true,
    });

    const response = await apiClient.post('reset-password', {
      email,
    }).catch(() => {
      this.setState({
        message: t('something.failed'),
        email: '',
        isOK: false,
        isLoading: false,
      });
    });
    if (response) {
      const { data: { message, status } } = response;
      this.setState({
        message,
        email: '',
        isOK: status === true,
        isLoading: false,
      });
      if (status !== false) {
        history.push({
          pathname: '/login',
          flash: {
            type: 'success',
            message,
          },
        });
      }
    }
  }

  render() {
    const { t } = this.props;
    const {
      email, message, isOK, isLoading,
    } = this.state;
    return (
      <div className="flex-start position-ref full-height page-container">
        <div className="content-sm">
          <Row className="lf-logo-container">
            <Col md="12">
              <div className="lf-logo">
                <img src="/assets/lfg-logo.png" alt="Logo" />
              </div>
              <div className="lf-logo-text">
                { t('lf.logoBlock.name') }
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p className="lf-main-text">{ t('password.reset') }</p>
            </Col>
          </Row>
          {
            message
              && (
                <Row>
                  <Col md="12">
                    <Alert color={isOK ? 'success' : 'danger'}>
                      {message}
                    </Alert>
                  </Col>
                </Row>
              )
          }
          <Form onSubmit={this.onSubmit}>
            <Row className={styles.resetRow}>
              <Col md="12">
                <Input
                  className="input-clean"
                  required
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.onEmailChange}
                  placeholder={t('email')}
                />
              </Col>
            </Row>
            <Row className={`text-left ${styles.resetRowBtn}`}>
              <Col md="12">
                <Button
                  className="btn-main"
                  loading={isLoading}
                  disabled={_.isEmpty(email)}
                >
                  { t('password.send.reset.link') }
                </Button>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col md="12">
              <p className="copyright-text">
              Copyright ©
                {(new Date().getFullYear())}
            , Lafarge
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
)(ResetPassword);
