import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Row,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withAPI from '../../hocs/with-api';
import SuccessAlert from '../../components/success-alert';
import HttpErrorHandler from '../../components/http-error-handler';
import ButtonWithIcon from '../../components/button-with-icon';
import CarrierForm from '../../components/carrier-form';

class CreateCarrier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierCreated: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = `${t('carriers.createNew')} - ${t('app.name')}`;
  }

  onSubmit = async (values) => {
    const { httpRequest } = this.props;
    const {
      name,
      sapId,
      description,
      priceIndex,
    } = values;
    const data = {
      name,
      sap_id: sapId,
      description,
      price_index: priceIndex || '1.0',
    };
    const posted = await httpRequest({
      method: 'post',
      url: 'carriers',
      data,
    });
    if (posted) {
      this.formRef.current.resetInputs();
      this.setState({
        carrierCreated: true,
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t, httpPostFailed, httpPostFailReason, httpPostLoading,
    } = this.props;
    const { carrierCreated } = this.state;
    const formProps = {
      onSubmit: this.onSubmit,
      t,
      ref: this.formRef,
      isConfirmButtonLoading: httpPostLoading,
    };
    return (
      <>
        <Col md="12">
          {
            carrierCreated && <SuccessAlert message={t('carriers.created')} />
          }
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('carriers.createNew') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {
            httpPostFailed && httpPostFailReason && <HttpErrorHandler {...httpPostFailReason} />
            }
          <CarrierForm {...formProps} />
        </Col>
      </>
    );
  }
}

CreateCarrier.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
  httpPostLoading: PropTypes.bool,
};
CreateCarrier.defaultProps = {
  httpPostLoading: false,
  httpPostFailed: false,
  httpPostFailReason: undefined,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(CreateCarrier);
