import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import {
  Form,
  Row,
  Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Input from '../input';
import PrimaryButtonWithIcon from '../primary-button-with-icon';
import styles from './styles.module.css';

const LoginForm = ({
  t,
  onLogin,
  isLoggingIn,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => setEmail(_.get(event, 'target.value', ''));
  const handlePasswordChange = (event) => setPassword(_.get(event, 'target.value', ''));

  const canLogin = !_.isEmpty(email) && !_.isEmpty(password);

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin({
      email, password,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className={styles.authRow}>
          <Col md="12">
            <Input
              className="input-clean"
              required
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder={t('email')}
            />
          </Col>
        </Row>
        <Row className={styles.authRow}>
          <Col md="12">
            <Input
              className="input-clean"
              required
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder={t('password')}
            />
          </Col>
        </Row>
        <Row className="text-left">
          <Col md="12">
            <PrimaryButtonWithIcon
              onClick={handleSubmit}
              title={t('signIn')}
              width="200px"
              height="44px"
              icon="/assets/images/login-icon@2x.png"
              disabled={!canLogin || isLoggingIn}
              loading={isLoggingIn}
              type="submit"
            />
          </Col>
        </Row>
        <Row className={`text-left ${styles.authRowReset}`}>
          <Col md="12">
            <Link to="/password/reset" className={`btn btn-link ${styles.btnLinkAuth}`}>
              {t('password.reset')}
            </Link>
          </Col>
        </Row>
        <Row className="findOrderBtn">
          <Col md="12">
            <Link to="/search/order" className="btn btn-main btn-find-command">
              <span>{ t('orders.findOne') }</span>
            </Link>
          </Col>
        </Row>
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  t: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  isLoggingIn: PropTypes.bool,
};

LoginForm.defaultProps = {
  isLoggingIn: false,
};

export default compose(
  withTranslation(),
)(LoginForm);
