import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const style = {
  margin: '0',
  padding: '0',
  listStyle: 'none',
};

const warningIconStyle = {
  backgroundImage: "url('/assets/warning-icon.png')",
  backgroundSize: '20px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5px center',
  paddingLeft: '35px',
  margin: '6px',
};

const Address = ({
  addresses,
}) => (
  <ul style={style}>
    { _.map(addresses, (value, index) => {
      const hasIvmsId = _.get(value, 'ivms_location_id') !== null;
      return (<li key={index} style={!hasIvmsId ? warningIconStyle : null}>{value.address}</li>);
    })}
  </ul>
);

Address.propTypes = {
  addresses: PropTypes.array,
};

Address.defaultProps = {
  addresses: [],
};

export default Address;
