import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import _ from 'lodash';
import styles from './styles.module.css';
import HttpErrorHandler from '../../components/http-error-handler';
import withAPI from '../../hocs/with-api';
import OrderDetails from '../../components/order-details';
import SearchOrderForm from '../../components/search-order-form';
import Map from '../../components/map';

class WebViewOrderSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: null,
      recaptcha: '',
      initialPosition: undefined,
    };

    this.captchaRef = React.createRef();
  }

  onLoginClick = () => {
    const { history } = this.props;
    history.push('/login');
  }

  onSubmit = async (values) => {
    const { httpRequest, httpPostLoading } = this.props;

    if (httpPostLoading) {
      return;
    }

    const { orderNumber } = values;
    const { recaptcha } = this.state;
    this.setState({ initialPosition: undefined });
    const data = {
      order_number: orderNumber,
      g_recaptcha: recaptcha,
    };

    const response = await httpRequest({
      method: 'post',
      url: 'search/order',
      data,
    }).catch(() => {});

    if (response) {
      const order = response.data;
      this.setState({
        orderDetails: order,
      });

      if (_.get(order, 'vehicle')) {
        const { geo } = _.get(order, 'vehicle');
        if (geo && _.get(geo, 'started', false)) {
          this.setState({
            initialPosition: [geo.lat, geo.long],
            channel: `order.${order.id}.vehicle.geo.changed`,
          });
        }
      }
    }
    this.captchaRef.current.reset();
  }

  onRecaptchaChanged = (recaptchaToken) => {
    this.setState({ recaptcha: recaptchaToken });
  }

  render() {
    const {
      t,
      httpPostLoading,
      httpPostFailReason,
      httpPostFailed,
    } = this.props;
    const {
      orderDetails,
      channel,
      initialPosition,
    } = this.state;

    return (
      <div className="position-ref searchPage full-height page-container">
        <div className={`${styles.lgContainer} ${styles.alignCenter}`}>
          {
              httpPostFailed && httpPostFailReason
              && (
              <Row>
                <Col className={styles.formCol}>
                  <HttpErrorHandler {...httpPostFailReason} />
                </Col>
              </Row>
              )
              }
          <Row>
            <Col className="colBlock" lg="5">
              <p className="lf-main-text">{ t('orders.searchOne') }</p>
            </Col>
            <Col lg="7">
              <SearchOrderForm
                t={t}
                ref={this.captchaRef}
                onSubmit={this.onSubmit}
                hasRecaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                isLoading={httpPostLoading}
                onRecaptchaChanged={this.onRecaptchaChanged}
              />
            </Col>
          </Row>

          <>
            {
              orderDetails
              && (
                <OrderDetails
                  shouldShowLessInfo={_.get(orderDetails, 'status.name', '') === 'Delivered'}
                  style={{ padding: '0px' }}
                  pageTitle=" "
                  {...orderDetails}
                />
              )
            }
          </>

        </div>

        { orderDetails && channel && initialPosition && (
          <>
            <Row className={styles.row}>
              <Col>
                <p className="lf-main-text">{ t('map.view') }</p>
              </Col>
            </Row>
            <Row>
              <Col className="mt-10">
                <Map channel={channel} initialPosition={initialPosition} />
              </Col>
            </Row>
          </>
        )}
        {

        orderDetails && (
          <Row>
            <Col md="12">
              <p className={`copyright-text ${styles.copyrightText}`}>
                Copyright ©
                {(new Date().getFullYear())}
                , Lafarge
              </p>
            </Col>
          </Row>
        )
}
      </div>
    );
  }
}

WebViewOrderSearch.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostLoading: PropTypes.bool,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.any,
  history: PropTypes.object.isRequired,
};

WebViewOrderSearch.defaultProps = {
  httpPostLoading: false,
  httpPostFailReason: undefined,
  httpPostFailed: false,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(WebViewOrderSearch);
