import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import _ from 'lodash';
import Input from '../input';

const KPIVariables = [
  { id: 1, KPIVariable: 'R', subscript: 'o' },
  { id: 2, KPIVariable: 'S', subscript: 'î' },
  { id: 3, KPIVariable: 'S', subscript: 'd' },
  { id: 4, KPIVariable: 'R', subscript: 'c' },
  { id: 5, KPIVariable: 'R', subscript: 'r' },
  { id: 6, KPIVariable: 'C', subscript: 't' },
];

const getKPIVariableValueByID = (id) => {
  const i = _.find(KPIVariables, ['id', id]);
  return i.KPIVariable;
};

const getKPIVariableSubscriptValueByID = (id) => {
  const i = _.find(KPIVariables, ['id', id]);
  return i.subscript;
};

const KpiTableRowForm = ({
  t,
  kpi,
  onUpdate,
}) => (
  <tr>
    <td className="align-middle">
      { getKPIVariableValueByID(kpi.id) }
      <sub>{ getKPIVariableSubscriptValueByID(kpi.id) }</sub>
    </td>
    <td className="align-middle">{ kpi.name }</td>
    <td className="align-middle customFieldMobile">
      <Input
        required
        type="number"
        className="form-control"
        placeholder={t('kpi.value')}
        value={kpi.value}
        disabled={!kpi.value_is_active}
        onChange={(event) => {
          const kpiUpdated = kpi;
          kpiUpdated.value = _.get(event, 'target.value', '');
          onUpdate(kpiUpdated);
        }}
      />
      <strong>%</strong>

      <div className="materialToggle">
        <input
          id={`KPIvalue${kpi.id}`}
          type="checkbox"
          onChange={(event) => {
            const kpiUpdated = kpi;
            kpiUpdated.value_is_active = _.get(event, 'target.checked', false);
            onUpdate(kpiUpdated);
          }}
          checked={kpi.value_is_active}
          className="materialToggleInput"
        />
        <label htmlFor={`KPIvalue${kpi.id}`} className="materialToggleLabel" />
      </div>
    </td>

    <td className="align-middle customFieldMobile">
      <Input
        required
        type="number"
        className="form-control"
        placeholder={t('kpi.minValue')}
        value={kpi.min_value}
        disabled={!kpi.min_value_is_active}
        onChange={(event) => {
          const kpiUpdated = kpi;
          kpiUpdated.min_value = _.get(event, 'target.value', '');
          onUpdate(kpiUpdated);
        }}
      />
      <strong>%</strong>

      <div className="materialToggle">
        <input
          id={`MinValue${kpi.id}`}
          type="checkbox"
          onChange={(event) => {
            const kpiUpdated = kpi;
            kpiUpdated.min_value_is_active = _.get(event, 'target.checked', false);
            onUpdate(kpiUpdated);
          }}
          checked={kpi.min_value_is_active}
          className="materialToggleInput"
        />
        <label htmlFor={`MinValue${kpi.id}`} className="materialToggleLabel" />
      </div>
    </td>
  </tr>

);
KpiTableRowForm.propTypes = {
  kpi: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
)(KpiTableRowForm);
