import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Col } from 'reactstrap';

import ImportStatusTable from '../../components/import-status-table';

import Spinner from '../../components/spinner';
import HttpErrorHandler from '../../components/http-error-handler';

import withAPI from '../../hocs/with-api';

class ImportStatus extends PureComponent {
  constructor() {
    super();
    this.state = {
      orderInfo: null,
    };
  }

  async componentDidMount() {
    const { httpRequest, match: { params: { id } } } = this.props;
    const { data: orderInfo } = await httpRequest({
      method: 'get',
      url: `orders/${id}/import`,
    });
    if (orderInfo) {
      this.setState({
        orderInfo,
      });
    }
  }

  render() {
    const { orderInfo } = this.state;
    const {
      httpGetLoading, httpGetFailed, httpGetDone, httpGetFailReason,
    } = this.props;
    return (
      <Col>
        {
          httpGetLoading && <Spinner />
        }
        {
          httpGetFailed && httpGetFailReason && (
            <HttpErrorHandler {...httpGetFailReason} />
          )
        }
        {
          httpGetDone && !httpGetFailed && orderInfo && (
            <ImportStatusTable {...orderInfo} />
          )
        }
      </Col>
    );
  }
}

ImportStatus.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetFailed: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  httpGetFailReason: PropTypes.any,
};


ImportStatus.defaultProps = {
  httpGetLoading: false,
  httpGetFailed: false,
  httpGetDone: false,
  httpGetFailReason: undefined,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(ImportStatus);
