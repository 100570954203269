import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import _ from 'lodash';
import withUserInfo from '../../hocs/with-user-info';
import LongText from '../long-text';
import styles from './styles.module.css';


const splitLicenseNumberIntoPieces = (licensePlate) => {
  const splitNumber = licensePlate.match(/[a-z]+|[^a-z]+/gi);
  return splitNumber.join(' ');
};

const colorByDayStatus = (status) => {
  switch (status) {
    case 'STATUS_DAY_FINISHED':
      return styles.finished;
    case 'STATUS_DAY_IN_PROGRESS':
      return styles.inProgress;
    default:
      return '';
  }
};

const colorByTodayStatus = (status) => {
  switch (status) {
    case 'STATUS_TODAY_EMPTY_DAYS':
      return styles.empty;
    case 'STATUS_TODAY_FINISHED':
      return styles.todayFinished;
    case 'STATUS_TODAY_IN_PROGRESS':
      return styles.todayInProgress;
    default:
      return '';
  }
};

const statusTodayName = (t, status) => {
  switch (status) {
    case 'STATUS_TODAY_EMPTY_DAYS':
      return t('vehicle.status.inactiv');
    case 'STATUS_TODAY_FINISHED':
      return t('vehicle.status.empty');
    case 'STATUS_TODAY_IN_PROGRESS':
      return t('vehicle.status.busy');

    default:
      return '';
  }
};

const VehicleStatusTableRow = ({
  vehicle,
  current_status,
  days,
  t,
}) => (
  <tr className={colorByTodayStatus(current_status)}>
    <td className="align-middle">
      { vehicle.carrier.name }
    </td>
    <td className={`${styles.licensePlate} align-middle`}>
      <LongText value={splitLicenseNumberIntoPieces(vehicle.number_plate)} maxLength={13} />
    </td>
    <td className="align-middle">
      { statusTodayName(t, current_status) }
    </td>
    {
        _.map(days, (status, key) => (
          <td className="align-middle" key={key}>
            <div className={`${styles.statusDay} ${colorByDayStatus(status)}`} />
          </td>
        ))
      }

  </tr>
);

VehicleStatusTableRow.propTypes = {
  t: PropTypes.func.isRequired,
  vehicle: PropTypes.object.isRequired,
  current_status: PropTypes.string.isRequired,
  days: PropTypes.object.isRequired,
};

export default compose(
  withTranslation(),
  withUserInfo,
)(VehicleStatusTableRow);
