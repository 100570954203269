import React from 'react';
import _ from 'lodash';
import getAPIClient from '../../services/api';

function withAPI(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        httpGetLoading: false,
        httpGetDone: false,
        httpGetFailed: false,
      };
      const tokens = JSON.parse(localStorage.getItem('lfg-tokens'));
      this.client = getAPIClient(tokens && tokens.access_token ? tokens.access_token : undefined);
    }

    httpRequest = async ({
      method: httpMethod,
      url,
      data,
      tag: mTag = '',
    }) => {
      const fn = _.get(this.client, httpMethod);
      const method = _.startCase(httpMethod);
      const tag = _.startCase(mTag);
      this.setState({
        [`http${method}Loading${tag}`]: true,
      });
      const result = await fn(url, data).catch((error) => {
        this.setState({
          [`http${method}Loading${tag}`]: false,
          [`http${method}Done${tag}`]: true,
          [`http${method}Failed${tag}`]: true,
          [`http${method}FailReason${tag}`]: {
            code: _.get(error, 'response.status', 404),
            errors: _.get(error, 'response.data.errors'),
            message: _.get(error, 'response.data.message'),
          },
        });
        throw error;
      });
      if (result) {
        this.setState({
          [`http${method}Loading${tag}`]: false,
          [`http${method}Done${tag}`]: true,
          [`http${method}Failed${tag}`]: false,
          [`http${method}FailReason${tag}`]: undefined,
        });
        return result;
      }
      return null;
    }

    render() {
      const rest = {
        httpRequest: this.httpRequest,
      };
      return <WrappedComponent {...this.props} {...rest} {...this.state} />;
    }
  };
}

export default withAPI;
