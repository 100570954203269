import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import HttpErrorHandler from '../../components/http-error-handler';
import ChangePasswordForm from '../../components/change-password-form';
import withAPI from '../../hocs/with-api';
import ButtonWithIcon from '../../components/button-with-icon';

class ChangePassword extends Component {
  onSubmit = async (values) => {
    const { currentPassword, newPassword, confirmationPassword } = values;
    const { httpRequest, history, t } = this.props;

    if (_.isEmpty(currentPassword) || _.isEmpty(newPassword) || _.isEmpty(confirmationPassword)) {
      return;
    }

    const data = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmationPassword,
    };

    const passwordChangeResponse = await httpRequest({
      method: 'patch',
      url: '/users/password',
      data,
    });

    if (passwordChangeResponse) {
      history.push({
        pathname: '/dashboard',
        flash: {
          type: 'success',
          message: t('password.success'),
        },
      });
    }
  };

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t,
      httpPatchLoading,
      httpPatchFailReason,
      httpPatchFailed,
    } = this.props;

    return (
      <>
        <Col md="12">
          <Row>
            <p className="lf-main-text form-name-text">{ t('password.change') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          {
            httpPatchFailed && httpPatchFailReason
            && <HttpErrorHandler {...httpPatchFailReason} />
          }
          <ChangePasswordForm onSubmit={this.onSubmit} isLoading={httpPatchLoading} />
        </Col>
      </>
    );
  }
}

ChangePassword.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  httpPatchLoading: PropTypes.bool,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.any,
  history: PropTypes.object.isRequired,
};

ChangePassword.defaultProps = {
  httpPatchLoading: false,
  httpPatchFailReason: undefined,
  httpPatchFailed: false,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
)(ChangePassword);
