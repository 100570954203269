import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import Spinner from '../../components/spinner';
import UserForm from '../../components/user-form';
import HttpErrorHandler from '../../components/http-error-handler';

import withUser from '../../hocs/with-user';
import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import ButtonWithIcon from '../../components/button-with-icon';

class EditUser extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
      carriers: [],
      roles: [],
    };
  }

  async componentDidMount() {
    const {
      match: { params: { id } },
      httpRequest,
      history,
    } = this.props;

    const response = await httpRequest({
      method: 'get',
      url: `users/${id}`,
    }).catch(() => {
      history.push('/dashboard');
    });

    const user = _.get(response, 'data');

    if (!user) {
      return;
    }

    this.setState({
      user,
    });

    const { data: roles } = await httpRequest({
      method: 'get',
      url: 'roles',
      tag: 'roles',
    });

    const { data: carriers } = await httpRequest({
      method: 'get',
      url: 'carriers',
      tag: 'carriers',
    });

    this.setState({
      roles,
      carriers,
    });
  }

  updateUser = async (values) => {
    const {
      user: {
        id,
      }, name, email, role, password, carrier,
    } = values;
    const {
      t, httpRequest, storeProfile, getProfile, user, history,
    } = this.props;
    const data = {
      name, email, role: role.id,
    };
    if (!_.isEmpty(_.trim(password))) {
      data.password = password;
    }
    if (!_.isEmpty(_.toString(carrier))) {
      data.carrier = carrier.id;
    }
    const patched = await httpRequest({
      method: 'patch',
      url: `/users/${id}`,
      data,
    });
    if (patched && id === user.id) {
      const profile = await getProfile();
      storeProfile(profile);
    }
    if (patched) {
      history.push({
        pathname: '/users',
        flash: {
          message: t('users.edited'),
          type: 'success',
        },
      });
    }
  }

  deleteUser = async (userId) => {
    const { httpRequest, history, t } = this.props;
    await httpRequest({
      method: 'delete',
      url: `users/${userId}`,
    });
    history.push({
      pathname: '/users',
      flash: {
        type: 'success',
        message: t('users.deleted'),
      },
    });
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t,
      httpGetLoading,
      httpGetDone,
      httpGetLoadingRoles,
      httpGetDoneRoles,
      httpPatchFailed,
      httpPatchFailReason,
      user: loggedInUser,
    } = this.props;

    const {
      user, roles, carriers,
    } = this.state;

    const isMyUser = user && (user.id === loggedInUser.id);

    const formProps = {
      user,
      roles,
      carriers,
      onSubmit: this.updateUser,
      onDelete: this.deleteUser,
      inEditMode: true,
      isMyUser,
    };

    const shouldRenderSpinner = httpGetLoading || httpGetLoadingRoles;

    return (
      <>
        <Col md="12">
          { httpPatchFailed && httpPatchFailReason && <HttpErrorHandler {...httpPatchFailReason} />}
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('users.edit') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        <Col md="12">
          { shouldRenderSpinner && <Spinner marginTop="180px" /> }
          {
              httpGetDone && httpGetDoneRoles && (
              <UserForm
                {...formProps}
              />
              )
            }
        </Col>
      </>
    );
  }
}

EditUser.defaultProps = {
  httpGetLoadingRoles: true,
  httpGetDoneRoles: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
};

EditUser.propTypes = {
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  user: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
  storeProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoadingRoles: PropTypes.bool,
  httpGetDoneRoles: PropTypes.bool,
};

export default compose(
  withTranslation(),
  withRouter,
  withUserInfo,
  withAPI,
  withUser,
)(EditUser);
