import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Col,
} from 'reactstrap';
import InfoAlert from '../../components/info-alert';

import HttpErrorHandler from '../../components/http-error-handler';
import Pagination, { getCurrentPageNumber } from '../../components/pagination';
import Spinner from '../../components/spinner';
import Table from '../../components/table';
import ActionCard from '../../components/action-card';
import OrderTableRow from '../../components/order-table-row';
import SuccessAlert from '../../components/success-alert';
import CarrierSelector, { setContextCarrier, getContextCarrier } from '../carrier-selector';

import withAPI from '../../hocs/with-api';
import withUserInfo from '../../hocs/with-user-info';
import withEcho from '../../hocs/with-echo';

import { getUpdateCollection } from './functions';

const getResourceURLByTabId = (tabId) => {
  const endpoints = {
    additional: 'orders/shared',
    recommended: 'orders/allocated',
    history: 'orders/own',
  };
  return endpoints[tabId];
};

class MyOrders extends PureComponent {
  constructor(props) {
    super(props);
    const { match: { params: { tabId } }, user } = this.props;
    this.state = {
      activeTabId: tabId,
      currentPage: getCurrentPageNumber(props),
      orders: [],
      shouldUseContextCarrierId: !user.isDispatcher,
      contextCarrierId: _.get(getContextCarrier(), 'id', undefined),
      newOrderAdded: false,
    };
  }

  async componentDidMount() {
    const { t, echoClient } = this.props;
    const { user } = this.props;
    const { contextCarrierId } = this.state;
    document.title = `${t('orders.mine')} - ${t('app.name')}`;
    const carrierId = !user.isDispatcher ? contextCarrierId : user.carriers[0].id;

    window.onpopstate = this.onBackButtonClick;
    echoClient
      .channel('order.picked')
      .listen('OrderPicked', this.onOrderPicked);

    echoClient
      .channel(`new.order.${carrierId}.added`)
      .listen('NewOrdersAdded', this.onNewOrderAdded);

    echoClient
      .channel('new.order.expired')
      .listen('NewOrdersAdded', this.onNewOrderAdded);

    echoClient
      .channel('orders.kpi.index')
      .listen('KPIOrderExpired', this.onOrderExpired);
    await this.loadOrders();
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { tabId: prevTabId } } } = prevProps;
    const { match: { params: { tabId: actualTabId } } } = this.props;
    if (prevTabId !== actualTabId) {
      this.loadOrders();
      this.updateActiveTab(actualTabId, this.loadOrders);
    }
  }

  updateActiveTab = (activeTab, callback) => {
    this.setState({ activeTabId: activeTab }, callback);
  }

  componentWillUnmount() {
    window.onpopstate = null;
    const { echoClient } = this.props;
    echoClient.leave();
  }

  onOrderPicked = (event) => {
    const { activeTabId } = this.state;
    if (activeTabId === 'history') return;
    const { orders } = this.state;
    const { data: { order: { id } } } = event;
    const updated = getUpdateCollection(orders, id, { justPicked: true });
    this.setState({
      orders: updated,
    });
  }

  onNewOrderAdded = (event) => {
    const { data: { message: eventMessage } } = event;
    this.setState({ newOrderAdded: true, eventMessage });
  }

  onOrderExpired = (event) => {
    const { orders } = this.state;
    const { data: { order_id } } = event;
    const updated = getUpdateCollection(orders, order_id, { justExpired: true });
    this.setState({
      orders: updated,
    });
  }

  loadOrders = async () => {
    const { httpRequest, match: { params: { tabId } } } = this.props;
    const {
      currentPage, shouldUseContextCarrierId, contextCarrierId,
    } = this.state;

    if (shouldUseContextCarrierId && !contextCarrierId) return;

    const endpoint = getResourceURLByTabId(tabId);
    const url = `${endpoint}?page=${currentPage}${shouldUseContextCarrierId ? `&carrier_id=${contextCarrierId}` : ''}`;

    const {
      data: {
        data: orders,
        total,
        per_page,
        last_page,
        from,
        to,
      },
    } = await httpRequest({
      method: 'get',
      url: `${url}`,
    });

    if (orders) {
      this.setState({
        orders,
        totalPages: last_page,
        perPage: per_page,
        totalRecords: total,
        from,
        to,
      });
    }
  }

  onBackButtonClick = () => {
    const page = getCurrentPageNumber(this.props);
    this.setState({
      currentPage: page,
    }, this.loadOrders);
  };

  handleNavigation = (tabId) => {
    const { history } = this.props;
    this.setState({
      currentPage: 1,
      activeTabId: tabId,
      newOrderAdded: false,
    }, () => {
      history.push(`/my-orders/${tabId}`);
    });
  }

  onContextCarrierChanged = (carrier) => {
    setContextCarrier(carrier);
    this.setState({
      contextCarrierId: carrier.id,
    }, this.loadOrders);
  }

  handlePageChange = (currentPage) => {
    const { history } = this.props;
    const { activeTabId } = this.state;
    this.setState({
      currentPage,
    }, () => {
      history.push(`/my-orders/${activeTabId}/${currentPage}`);
      this.loadOrders();
    });
  }

  handleAlertClose = () => {
    this.setState({
      newOrderAdded: false,
    });
  }

  render() {
    const {
      t,
      user,
      httpGetDone,
      httpGetLoading,
      httpGetFailed,
      httpGetFailReason,
      location,
    } = this.props;

    const {
      activeTabId,
      orders,
      currentPage,
      totalPages,
      totalRecords,
      perPage,
      from,
      to,
      shouldUseContextCarrierId,
      contextCarrierId,
      newOrderAdded,
      eventMessage,
    } = this.state;

    const carrierContextOK = user.isDispatcher || (
      shouldUseContextCarrierId && !_.isEmpty(_.toString(contextCarrierId))
    );

    const { pathname } = location;

    const carrierSelector = (
      <CarrierSelector
        onCarrierChanged={this.onContextCarrierChanged}
      />

    );
    return (
      <>
        <Col md="12">
          {
            newOrderAdded
            && (
            <SuccessAlert
              message={eventMessage || t('orders.new.added')}
              onClose={this.handleAlertClose}
            />
            )
          }
        </Col>
        <ActionCard
          withActionButton={false}
          title={t('orders.list')}
          headerChildren={!user.isDispatcher ? [carrierSelector] : []}
        >
          <Nav tabs>
            <>
              <NavItem>
                <NavLink
                  className={_.includes(pathname, 'recommended') ? 'active' : ''}
                  onClick={() => this.handleNavigation('recommended')}
                >
                  {t('orders.allocatedTab')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={_.includes(pathname, 'additional') ? 'active' : ''}
                  onClick={() => this.handleNavigation('additional')}
                >
                  {t('orders.freeTab')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={_.includes(pathname, 'history') ? 'active' : ''}
                  onClick={() => this.handleNavigation('history')}
                >
                  {t('orders.mineTab')}
                </NavLink>
              </NavItem>
            </>
          </Nav>
          <TabContent activeTab="1">
            <TabPane tabId="1">
              {
                !carrierContextOK && (
                  <InfoAlert style={{ marginTop: 20 }} message={t('select.carrier.to.go.on')} />
                )
              }
              {
                httpGetLoading && <Spinner />
              }
              {
                httpGetFailed && <HttpErrorHandler {...httpGetFailReason} />
              }
              {
                !httpGetLoading && httpGetDone && !httpGetFailed && carrierContextOK && (
                  <>
                    <Table isEmpty={_.isEmpty(orders)}>
                      <thead>
                        <tr>
                          <th>{t('orders.number')}</th>
                          <th className="hideMobile">{t('orders.status')}</th>
                          {
                            activeTabId === 'recommended' && (
                              <th>{t('orders.expireDate')}</th>
                            )
                          }
                          <th>{t('actions')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          httpGetDone && orders && _.map(orders, (order) => (
                            <OrderTableRow
                              key={order.id}
                              hasViewLink
                              hasEditLink={false}
                              hasEditDetailsLink={_.get(order, 'can_edit_details', false)}
                              viewLinkRouterState={{ contextCarrierId }}
                              {...order}

                            />
                          ))
                        }

                      </tbody>
                    </Table>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onChange={this.handlePageChange}
                      totalRecords={totalRecords}
                      perPage={perPage}
                      from={from}
                      to={to}
                    />
                  </>
                )
              }
            </TabPane>
          </TabContent>
        </ActionCard>
      </>
    );
  }
}

MyOrders.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  httpGetDone: PropTypes.bool.isRequired,
  httpGetLoading: PropTypes.bool.isRequired,
  httpGetFailed: PropTypes.bool.isRequired,
  httpGetFailReason: PropTypes.any,
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  echoClient: PropTypes.object.isRequired,
};


MyOrders.defaultProps = {
  httpGetFailReason: undefined,
};

export default compose(
  withTranslation(),
  withRouter,
  withAPI,
  withUserInfo,
  withEcho,
)(MyOrders);
