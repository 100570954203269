import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  Col,
  Row,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Spinner from '../../components/spinner';

import withAPI from '../../hocs/with-api';
import HttpErrorHandler from '../../components/http-error-handler';
import OrderDetailsForm from '../../components/order-details-form';
import SuccessAlert from '../../components/success-alert';
import ButtonWithIcon from '../../components/button-with-icon';

class EditOrderDetails extends PureComponent {
  constructor() {
    super();
    this.state = {
      order: null,
      drivers: [],
      vehicles: [],
      orderEditedOk: false,
    };
  }

  componentDidMount = async () => {
    const { httpRequest, match: { params: { id: orderId } } } = this.props;
    const { data: order } = await httpRequest({
      method: 'get',
      url: `/orders/${orderId}`,
    });
    if (order) {
      this.setState({
        order,
      });
    }
    const carrierId = _.get(order, 'carrier_id', '');

    const { data: drivers } = await httpRequest({
      method: 'get',
      url: `drivers/?carrier_id=${carrierId}`,
      tag: 'drivers',
    });

    if (drivers) {
      this.setState({
        drivers,
      });
    }

    const vehicleType = _.get(order, 'vehicle_type', '');
    const { data: vehicles } = await httpRequest({
      method: 'get',
      url: `vehicles/?carrier_id=${carrierId}&type=${vehicleType}`,
      tag: 'vehicles',
    });

    if (vehicles) {
      this.setState({
        vehicles,
      });
    }
  }

  updateOrder = async ({
    driver,
    vehicle,
  }, notify = '') => {
    const {
      httpRequest, match: { params: { id } },
    } = this.props;

    const { order } = this.state;
    const data = {
      driver_id: driver,
      vehicle_id: vehicle,
      carrier_id: _.get(order, 'carrier_id', ''),
    };

    this.setState({ orderEditedOk: false });
    const patched = await httpRequest({
      method: 'patch',
      url: `orders/${id}/details${notify}`,
      data,
    });
    if (patched) {
      this.setState({ orderEditedOk: true });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      order, drivers, vehicles, orderEditedOk,
    } = this.state;
    const {
      httpGetLoading,
      httpGetDone,
      t,
      httpPatchFailed,
      httpPatchFailReason,
      httpPatchLoading,
    } = this.props;

    return (
      <>
        <Col md="12">
          <Row>
            <p className="lf-main-text form-name-text">{ t('orders.edit') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
        </Col>
        { httpGetLoading && <Spinner />}
        { !httpGetLoading && httpGetDone && order
        && (
        <Col md="12">
          {
            orderEditedOk
            && <SuccessAlert message={t('orders.edited')} />
          }
          {
            httpPatchFailed && httpPatchFailReason
            && <HttpErrorHandler {...httpPatchFailReason} />
          }
          <OrderDetailsForm
            t={t}
            order={order}
            onSubmit={this.updateOrder}
            isConfirmButtonLoading={httpPatchLoading}
            drivers={drivers}
            vehicles={vehicles}
          />
        </Col>
        )}
      </>
    );
  }
}

EditOrderDetails.propTypes = {
  httpRequest: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  httpGetLoading: PropTypes.bool,
  httpGetDone: PropTypes.bool,
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  httpPatchFailed: PropTypes.bool,
  httpPatchFailReason: PropTypes.object,
  httpPatchLoading: PropTypes.bool,
  httpDeleteLoading: PropTypes.bool,
  httpDeleteFailed: PropTypes.bool,
  httpDeleteFailReason: PropTypes.object,
};

EditOrderDetails.defaultProps = {
  httpGetLoading: true,
  httpGetDone: false,
  httpPatchFailed: false,
  httpPatchFailReason: undefined,
  httpPatchLoading: false,
  httpDeleteLoading: false,
  httpDeleteFailReason: undefined,
  httpDeleteFailed: false,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(EditOrderDetails);
