import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Form,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap';
import styles from './styles.module.css';

import Input from '../input';
import ButtonWithIcon from '../button-with-icon';

const ClientsForm = ({
  t,
  client,
  onSubmit,
  isConfirmButtonLoading,
}) => {
  const [name, setName] = React.useState(_.get(client, 'name', ''));
  const [addresses, updateAdressesList] = React.useState(client.addresses);
  const handleSetName = (event) => setName(_.get(event, 'target.value', ''));

  const handleSetIvmsLocationId = (address) => {
    updateAdressesList(_.map(addresses, (addressObj) => (addressObj.id === address.id ? address : addressObj)));
  };

  const values = {
    name,
    addresses,
  };
  const isSubmitButtonValid = !_.isEmpty(name);

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
      <Col md="12">
        <FormGroup>
          <Label className="label-clean" for="name">{ t('clients.name') }</Label>
          <Input
            required
            value={name}
            type="text"
            name="name"
            id="name"
            className="input-clean"
            placeholder={t('clients.name')}
            onChange={handleSetName}
          />
        </FormGroup>
      </Col>
      {
      _.map(addresses, (value) => (
        <Col md="12">
          <Row>
            <FormGroup className={styles.formGroup}>
              <Label className="label-clean" for="name">{ t('clients.address') }</Label>
              <Input
                disabled
                value={value.address}
                address={value.address}
                type="text"
                name="name"
                id="name"
                className="input-clean"
                placeholder={t('clients.address')}
              />
            </FormGroup>
            <FormGroup className={styles.formGroup}>
              <Label className="label-clean" for="ivmsLocationId">{ t('id.ivms') }</Label>
              <Input
                value={value.ivms_location_id}
                type="text"
                name="ivmsLocationId"
                id="ivmsLocationId"
                className="input-clean"
                placeholder={t('id.ivms')}
                onChange={(event) => {
                  const addressUpdated = value;
                  addressUpdated.ivms_location_id = _.get(event, 'target.value', '');
                  handleSetIvmsLocationId(addressUpdated);
                }}
              />
            </FormGroup>
          </Row>
        </Col>
      ))

        }
      <Col md="12" className={styles.btnFormGroup}>
        <ButtonWithIcon
          title={t('save')}
          icon="/assets/images/save-icon@2x.png"
          className="btn-main btn-save"
          disabled={!isSubmitButtonValid || isConfirmButtonLoading}
        />
      </Col>
    </Form>
  );
};


ClientsForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  client: PropTypes.object,
  isConfirmButtonLoading: PropTypes.bool,
};

ClientsForm.defaultProps = {
  client: {},
  isConfirmButtonLoading: false,
};


export default ClientsForm;
