import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withAPI from '../../hocs/with-api';
import SuccessAlert from '../../components/success-alert';
import HttpErrorHandler from '../../components/http-error-handler';
import WarehouseForm from '../../components/warehouse-form';
import ButtonWithIcon from '../../components/button-with-icon';

class CreateWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouseCreated: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = `${t('warehouses.createNew')} - ${t('app.name')}`;
  }

  onSubmit = async (values) => {
    const { httpRequest } = this.props;
    const {
      name,
      address,
      ivmsLocationId,
    } = values;
    const data = {
      name,
      address,
      ivms_location_id: ivmsLocationId,
    };
    const posted = await httpRequest({
      method: 'post',
      url: 'warehouses',
      data,
    });
    if (posted) {
      this.formRef.current.resetInputs();
      this.setState({
        warehouseCreated: true,
      });
    }
  }

  onBackButtonClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      t, httpPostFailed, httpPostFailReason, httpPostLoading,
    } = this.props;
    const { warehouseCreated } = this.state;

    const formProps = {
      onSubmit: this.onSubmit,
      t,
      ref: this.formRef,
      isConfirmButtonLoading: httpPostLoading,
    };

    return (
      <>
        <Col md="12">
          {
            warehouseCreated && <SuccessAlert message={t('warehouses.created')} />
          }
        </Col>
        <Col md="12">
          <Row className="with-back-button">
            <p className="lf-main-text form-name-text">{ t('warehouses.createNew') }</p>
            <ButtonWithIcon
              height="40px"
              icon="/assets/back-icon.png"
              width="50px"
              className="float-right back-button"
              onClick={this.onBackButtonClick}
            />
          </Row>
          <Col md="12">
            {
              httpPostFailed && httpPostFailReason && <HttpErrorHandler {...httpPostFailReason} />
            }
            <WarehouseForm {...formProps} />
          </Col>
        </Col>
      </>
    );
  }
}

CreateWarehouse.propTypes = {
  t: PropTypes.func.isRequired,
  httpRequest: PropTypes.func.isRequired,
  httpPostFailed: PropTypes.bool,
  httpPostFailReason: PropTypes.object,
  httpPostLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
};
CreateWarehouse.defaultProps = {
  httpPostLoading: false,
  httpPostFailed: false,
  httpPostFailReason: undefined,
};

export default compose(
  withTranslation(),
  withAPI,
  withRouter,
)(CreateWarehouse);
