import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.css';

/*
The KPI Variables naming is shown both in the KPI values table and the KPI formulas.
If changed, ensure to modify in both places
*/
const KPIVariables = [
  { id: 1, KPIVariable: 'R', subscript: 'o' },
  { id: 2, KPIVariable: 'S', subscript: 'î' },
  { id: 3, KPIVariable: 'S', subscript: 'd' },
  { id: 4, KPIVariable: 'R', subscript: 'c' },
  { id: 5, KPIVariable: 'R', subscript: 'r' },
  { id: 6, KPIVariable: 'P', subscript: 'i' },
];

const getKPIVariableValueByID = (id) => {
  const i = _.find(KPIVariables, ['id', id]);
  return i.KPIVariable;
};

const getKPIVariableSubscriptValueByID = (id) => {
  const i = _.find(KPIVariables, ['id', id]);
  return i.subscript;
};

const KpiTableRow = ({
  t,
  kpi,
}) => {
  const getKpiStatus = (isActive) => t(`kpi.active.${isActive}`);
  const KPIVariableActive = _.get(kpi, 'value_is_active', false);
  const MinValueActive = _.get(kpi, 'min_value_is_active', false);
  return (

    <tr className={KPIVariableActive ? null : styles.inactiveRow}>
      <td className="align-middle">
        { getKPIVariableValueByID(kpi.id) }
        <sub>{ getKPIVariableSubscriptValueByID(kpi.id) }</sub>
      </td>
      <td className="align-middle">{ kpi.name }</td>

      <td className="align-middle kpiIndicator">
        <div className={styles.tableValue}>{`${_.get(kpi, 'value', '')}%` }</div>
        <span className={`${KPIVariableActive ? styles.labelActive : styles.labelInactive} customLabel`}>
          {getKpiStatus(_.get(kpi, 'value_is_active', false))}
        </span>
      </td>

      <td className="align-middle kpiIndicator">
        <div className={styles.tableValue}>{`${_.get(kpi, 'min_value', '')}%`}</div>
        <span className={`${MinValueActive ? styles.labelActive : styles.labelInactive} customLabel`}>
          {getKpiStatus(_.get(kpi, 'min_value_is_active', false))}
        </span>
      </td>
    </tr>
  );
};


KpiTableRow.propTypes = {
  kpi: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withTranslation(),
  React.memo,
)(KpiTableRow);
