import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReCaptcha from 'react-google-recaptcha';
import {
  Form,
  FormGroup,
  // Label,
  Row,
  Col,
} from 'reactstrap';
// import ButtonWithIcon from '../button-with-icon';
import PrimaryButtonWithIcon from '../primary-button-with-icon';

// import Button from '../button';
import Input from '../input';

const SearchOrderForm = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    isLoading,
    t,
    hasRecaptcha,
    onRecaptchaChanged,
    size,
    sitekey,
  } = props;
  const [orderNumber, setOrderNumber] = React.useState('');
  const handleSetOrderNumber = (event) => setOrderNumber(_.get(event, 'target.value', ''));
  const values = { orderNumber };

  return (
    <div className="content-lg">
      <Form onSubmit={(e) => { e.preventDefault(); onSubmit(values); }}>
        <FormGroup>
          <Row style={{ paddingRight: '0px' }}>
            <Col className="colBlock" style={{ paddingLeft: 0 }}>
              <Input
                className="input-clean search-input"
                required
                type="text"
                name="orderNumber"
                id="orderNumber"
                value={orderNumber}
                onChange={handleSetOrderNumber}
                placeholder={t('orders.orderNumber')}
              />
            </Col>
            <Col className="colBlock" style={{ paddingRight: '0px', paddingLeft: '0px' }}>
              <div className="search-button">
                <PrimaryButtonWithIcon
                  disabled={_.isEmpty(orderNumber) || isLoading}
                  onClick={() => {}}
                  title={t('orders.findOne')}
                  width="230px"
                  height="44px"
                  icon="/assets/images/search-icon@2x.png"
                  type="submit"
                />
              </div>
            </Col>
          </Row>
        </FormGroup>

        {
        hasRecaptcha
        && (
        <FormGroup>
          <ReCaptcha
            ref={ref}
            size={size}
            sitekey={sitekey}
            onChange={onRecaptchaChanged}
          />
        </FormGroup>
        )
      }
      </Form>
    </div>

  );
});

SearchOrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRecaptchaChanged: PropTypes.func,
  size: PropTypes.string,
  sitekey: PropTypes.string,
  isLoading: PropTypes.bool,
  hasRecaptcha: PropTypes.bool,
};
SearchOrderForm.defaultProps = {
  isLoading: false,
  hasRecaptcha: false,
  size: 'normal',
  sitekey: '',
  onRecaptchaChanged: () => {},
};

export default SearchOrderForm;
