import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import Button from '../button';

const HttpError = ({
  t,
  withTryAgainButton,
  onTryAgain,
}) => (
  <Alert color="light" className="text-center">
    <FontAwesomeIcon color="pink" icon={faExclamationTriangle} className="fa-10x" />
    <hr />
    <h3>{ t('something.failed') }</h3>
    {
      withTryAgainButton && (
        <>
          <Button color="primary" onClick={onTryAgain}>{ t('try.again') }</Button>
        </>
      )
    }
    {
      !withTryAgainButton && <span>{ t('please.try.again.later') }</span>
    }
  </Alert>
);

HttpError.propTypes = {
  t: PropTypes.func.isRequired,
  withTryAgainButton: PropTypes.bool,
  onTryAgain: PropTypes.func,
};

HttpError.defaultProps = {
  withTryAgainButton: false,
  onTryAgain: () => {},
};

export default compose(
  withTranslation(),
)(HttpError);
