import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import WithPermission, { editVehicles } from '../../modules/with-permission';
import CarrierName from '../carrier-name';

import withUserInfo from '../../hocs/with-user-info';
import LinkButton, { editIconStyle } from '../link-button';
import LongText from '../long-text';
import styles from './styles.module.css';


const splitLicenseNumberIntoPieces = (licensePlate) => {
  const splitNumber = licensePlate.match(/[a-z]+|[^a-z]+/gi);
  return splitNumber.join(' ');
};

const VehicleTableRow = ({
  t,
  id,
  number_plate,
  number_plate_trailer,
  external_id,
  type,
  carrier,
  user,
}) => {
  const [rowHoverDisabled, setRowHoverDisabled] = React.useState(false);
  return (
    <tr className={rowHoverDisabled ? 'hover-disabled' : 'hover-enabled'}>

      <td className={`${styles.licensePlate} align-middle`}>
        <LongText value={splitLicenseNumberIntoPieces(number_plate)} maxLength={13} />
      </td>
      <td className={`${styles.licensePlate} align-middle hideMobile`}>
        <LongText value={splitLicenseNumberIntoPieces(number_plate_trailer)} maxLength={13} />
      </td>
      {
      (user.isDispatcher || user.isAdmin)
      && (
      <td className="align-middle">
        { t(`vehicles.types.${_.lowerCase(type)}`)}
      </td>
      )
    }
      {
      !user.isDispatcher && (
      <td className="align-middle">
        <CarrierName {...carrier} />
      </td>
      )
    }
      <td className="align-middle">
        {external_id ?? '-'}
      </td>
      <td className="align-middle">
        <WithPermission permission={editVehicles}>
          <LinkButton
            to={`/vehicles/${id}/edit`}
            iconStyle={editIconStyle}
            onMouseEnter={() => setRowHoverDisabled(true)}
            onMouseLeave={() => setRowHoverDisabled(false)}
            title={t('edit')}
          />
        </WithPermission>
      </td>
    </tr>
  );
};

VehicleTableRow.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  number_plate: PropTypes.string.isRequired,
  number_plate_trailer: PropTypes.string.isRequired,
  external_id: PropTypes.string,
  carrier: PropTypes.object,
  user: PropTypes.object.isRequired,
};

VehicleTableRow.defaultProps = {
  carrier: null,
  external_id: null,
};

export default compose(
  withTranslation(),
  withUserInfo,
)(VehicleTableRow);
