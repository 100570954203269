import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'reactstrap';
import styles from './styles.module.css';

const PrimaryButtonWithIcon = ({
  title,
  icon,
  width,
  height,
  alignment,
  ...rest
}) => {
  const iconStyle = {
    backgroundImage: `url(${icon})`,
    backgroundSize: '19px auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '17px 12px',
  };
  return (
    <Button
      style={{ width, height, ...iconStyle }}
      className={`${styles.btn} ${alignment}`}
      {... rest}
    >
      <span>{ title }</span>
    </Button>
  );
};


PrimaryButtonWithIcon.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alignment: PropTypes.string,
};

PrimaryButtonWithIcon.defaultProps = {
  icon: '',
  width: '200px',
  height: '44px',
  title: '',
  alignment: '',
};

export default PrimaryButtonWithIcon;
