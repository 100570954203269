import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import LongText from '../long-text';

const dropdownStyle = {
  maxHeight: 220,
  overflow: 'scroll',
};

const DropdownFilter = ({
  t,
  label,
  options,
  onSelected,
  dropDownStyles,
}) => {
  const [shown, setShown] = useState(false);
  const [selected, setSelected] = useState();

  const onClick = (option) => {
    setSelected(option);
    onSelected(option.value);
  };

  const resetFilter = () => {
    setSelected(null);
    onSelected(null);
  };

  const { toggleStyles, toggleParentStyles } = dropDownStyles || {};

  return (
    <Dropdown
      isOpen={shown}
      toggle={() => setShown(!shown)}
      className="float-right clean-dropdown"
      style={toggleParentStyles}
    >
      <DropdownToggle caret tag="a" style={toggleStyles}>
        <LongText withTooltip={false} value={selected ? selected.label : label} />
      </DropdownToggle>
      <DropdownMenu style={dropdownStyle}>
        {
          selected && (
            <>
              <DropdownItem onClick={resetFilter}>{t('reset')}</DropdownItem>
              <DropdownItem divider />
            </>
          )
        }
        {
          _.map(options, (option) => (
            <DropdownItem
              onClick={() => onClick(option)}
              key={option.id}
            >
              <LongText value={option.label} withTooltip={false} />
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

DropdownFilter.propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  dropDownStyles: PropTypes.object,
};

DropdownFilter.defaultProps = {
  dropDownStyles: {},
};

export default compose(
  withTranslation(),
)(DropdownFilter);
