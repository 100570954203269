import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { NavLink, Link } from 'react-router-dom';
import { DropdownItem, UncontrolledCollapse } from 'reactstrap';
import styles from './styles.module.css';
import LongText from '../long-text';

export const togglerStyle = {
  width: '100%',
  margin: 0,
  padding: 0,
};

export const navLinkStyle = {
  margin: 0,
  padding: 0,
  width: '100%',
  display: 'block',
};

const userInfoStyle = {
  padding: '5px 20px 5px 60px',
  fontSize: 13,
};

const LeftNavigationItem = ({
  id,
  title,
  icon,
  canNavigate,
  path,
  isParent,
  onClick,
  onRouteChanged,
}) => {
  const iconStyle = {
    backgroundImage: `url(${icon})`,
    backgroundSize: '21px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20px center',
  };

  if (canNavigate) {
    return (
      <NavLink
        to={path}
        tag={Link}
        className={`${styles.link} ${isParent ? styles.item : styles.subitem}`}
        style={iconStyle}
        activeClassName={styles.active}
        exact
        isActive={(match, location) => {
          if (location && _.startsWith(location.pathname, path)) {
            const paginationPattern = new RegExp(`\\${path}\/\\d+`); // eslint-disable-line
            return path === location.pathname || paginationPattern.test(location.pathname);
          }
          return false;
        }}
        onClick={onRouteChanged}
      >
        {title}
      </NavLink>
    );
  }
  return (
    <li
      onClick={onClick}
      onKeyDown={onClick}
      id={id}
      style={iconStyle}
      className={`${styles.link} ${styles.item}`}
      role="presentation"
    >
      {title}

    </li>
  );
};

export const UserInfoNavigationItem = ({ user, t, onNavigate }) => (
  <li className={styles.hiddenMenuItem}>
    <ul style={navLinkStyle}>
      <DropdownItem divider />
      <LeftNavigationItem
        id="user"
        icon="/assets/settings-icon.png"
        title={user.name}
        canNavigate={false}
      />
      <li>
        <UncontrolledCollapse
          toggler="#user"
          onEntered={
            () => {
              const objDiv = document.getElementsByClassName('collapse-container')[0];
              objDiv.scrollTop = objDiv.scrollHeight;
            }
          }
        >
          <div style={userInfoStyle}>
            <b>{ user.roles[0].label }</b>
            <br />
            {user.isDispatcher && (
              <>
                <LongText value={user.carriers[0].name} />
                <br />
              </>
            )}
            { user.email }
          </div>
          <DropdownItem divider />
          <LeftNavigationItem
            isParent
            title={t('password.change')}
            path="/password/change"
            onRouteChanged={onNavigate}
          />
        </UncontrolledCollapse>
        <LeftNavigationItem isParent title={t('logout')} path="/logout" />
      </li>
    </ul>

  </li>
);

LeftNavigationItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  canNavigate: PropTypes.bool,
  isParent: PropTypes.bool,
  path: PropTypes.string,
  onClick: PropTypes.func,
  onRouteChanged: PropTypes.func,
};

LeftNavigationItem.defaultProps = {
  id: '',
  icon: '',
  canNavigate: true,
  path: '',
  isParent: false,
  onClick: () => {},
  onRouteChanged: () => {},
};

UserInfoNavigationItem.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onNavigate: PropTypes.func,
};
UserInfoNavigationItem.defaultProps = { onNavigate: () => {} };
export default LeftNavigationItem;
