import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from 'reactstrap';

import LongText from '../long-text';

const dropdownStyle = {
  maxHeight: 220,
  overflow: 'scroll',
};

const DropdownInput = ({
  label,
  placeholder,
  options,
  onSelected,
  defaultSelected,
}) => {
  const [shown, setShown] = useState(false);
  const [selected, setSelected] = useState(defaultSelected);

  const handleDropdownToggle = () => {
    setShown(!_.isEmpty(options) && !shown);
  };
  const onClick = (option) => {
    setSelected(option);
    onSelected(option.value);
  };

  useEffect(() => {
    if (selected) return;
    setSelected(defaultSelected);
  }, [defaultSelected, selected]);
  return (
    <>
      <Label className="label-clean" for="role">{label}</Label>
      <Dropdown
        isOpen={shown}
        toggle={handleDropdownToggle}
        className="clean-dropdown input-dropdown dropdown-toggle-primary"
      >
        <DropdownToggle
          caret
          tag="a"
          className={selected ? '' : 'placeholder'}
        >
          <LongText withTooltip={false} value={selected ? selected.label : placeholder} />
        </DropdownToggle>
        <DropdownMenu style={dropdownStyle}>
          {
          _.map(options, (option) => (
            <DropdownItem
              onClick={() => onClick(option)}
              key={option.id}
              id={`role-${option.id}`}
            >
              <LongText value={option.label} withTooltip={false} />
            </DropdownItem>
          ))
        }
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

DropdownInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelected: PropTypes.func.isRequired,
  defaultSelected: PropTypes.object,
};

DropdownInput.defaultProps = {
  defaultSelected: undefined,
};
export default compose(
  withTranslation(),
)(DropdownInput);
